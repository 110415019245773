import { IosEmbedWalletHelp } from "@/membership_form/embed/IosEmbedWalletHelp";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import {
  ActionGroups,
  ActionGroupType,
  ActionOutcomeBuilder,
  ActionOutcomesProvider,
  getRequiredOrgMemberAccountTypeByActionType,
  useActionOutcomesProvider,
} from "@/modules/actions";
import { ActionButton } from "@common/buttons/ActionButton";
import { KazmIcon } from "@common/icons/KazmIcons";
import SizedBox from "@common/SizedBox";
import {
  AppColors,
  BlockchainType,
  Branding,
  CommonQuestUtils,
  MemberActionType,
} from "@juntochat/kazm-shared";

import { useOrgMember } from "@/membership_form/providers/org_member_provider";
import { ActivationImage } from "@/modules/activations/ActivationImage/ActivationImage.tsx";
import { ActivationPointsBadge } from "@/modules/activations/ActivationPointsBadge/ActivationPointsBadge.tsx";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { useGetAllCurrentMemberConnectedAccounts } from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import { CheckboxWithLabelProps } from "@common/inputs/Checkbox";
import {
  ActionType,
  ActivationClaimDto,
  ActivationDto,
  ActivationVerificationMethod,
  ConnectedAccountDto,
  MemberConnectedAccountType,
} from "@juntochat/internal-api";
import { useCurrentOrgId } from "@utils/hooks/use_project_params";
import classNames from "classnames";
import { ReactNode, useEffect, useState } from "react";

import { RichTextView } from "@/common_components/editors/RichTextEditor/RichTextEditor.tsx";
import { AccessibleImage } from "@/common_components/images/AccessibleImage.tsx";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { ActivationAutomaticallyClaimedMessage } from "@/modules/activations/ActivationAutomaticallyClaimedMessage/ActivationAutomaticallyClaimedMessage.tsx";
import { ActivationRecurrence } from "@/modules/activations/ActivationRecurrence/ActivationRecurrence.tsx";
import {
  useGetActivationClaimEligibility,
  useListClaimableActivationIds,
} from "@/modules/activations/api.ts";
import { ActivationTypeIcon } from "@/projects/membership/components/activations/ActivationTypeIcon/ActivationTypeIcon.tsx";
import { DEFAULT_EMPTY_RAW_FORMAT } from "@/projects/membership/providers/forms/use_default_form.ts";
import { useGetAddressInfo } from "@/utils/hooks/use_cache.ts";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { TimeAgo } from "@common/TimeAgo.tsx";
import { MembershipCheckboxWithLabel } from "../inputs/MembershipCheckbox.tsx";
import { useClaimActivationController } from "./claim_activation_controller.ts";
import { MembershipThemedModal } from "@common/overlays/modals/MembershipThemedModal.tsx";

type ClaimQuestModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  activation: ActivationDto;
  existingClaim: ActivationClaimDto | undefined;
  isReadOnly?: boolean;
};

export function ClaimQuestModal(props: ClaimQuestModalProps) {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const isSmallMobile = useIsSmallMobile();

  return (
    <ActionOutcomesProvider
      orgId={loyaltyForm.orgId}
      membershipId={loyaltyForm.id}
      actionDefinitions={props.activation.claimRequirements.map((e) =>
        DtoMigrationUtils.actionDefinitionToProto(e),
      )}
    >
      <MembershipThemedModal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        style={{
          content: {
            width: isSmallMobile ? "100vw" : "600px",
            maxWidth: "100%",
            // Do not hide overflown content,
            // otherwise some dropdowns (e.g. for TikTok video selection) won't be fully visible.
            overflow: "unset",
          },
        }}
      >
        <ClaimQuestModalContent {...props} />
      </MembershipThemedModal>
    </ActionOutcomesProvider>
  );
}

function ClaimQuestModalContent(props: ClaimQuestModalProps) {
  const { activation } = props;
  const [isUsernameUpdateApproved, setIsUsernameUpdateApproved] =
    useState(true);
  const { signedInMember, refetchOrgMemberInfo } = useOrgMember();
  const orgId = useCurrentOrgId();
  const controller = useClaimActivationController({
    activation: props.activation,
    claim: props.existingClaim,
  });
  const { updateAndVerifyOutcomes } = useActionOutcomesProvider();
  const eligibility = useGetActivationClaimEligibility({
    membershipId: props.activation.membershipId,
    orgId: props.activation.orgId,
    activationId: props.activation.activationId,
  });
  const isViewingExistingClaim = props.existingClaim !== undefined;
  const claim = controller.claim ?? props.existingClaim;

  // We don't auto-validate twitter react claims so this allows the quest to be submitted without explicitly clicking the refresh icon
  const { data: claimableActivationIds } = useListClaimableActivationIds({
    membershipId: activation.membershipId,
    orgId: activation.orgId,
  });
  const isManualPointsAdjustment =
    activation.type === ActionType.ManualPointAdjustment;
  const isReadOnly = Boolean(
    claimableActivationIds?.has(activation.activationId) === false ||
      props.isReadOnly ||
      isManualPointsAdjustment ||
      eligibility.data === undefined ||
      eligibility.data.isCurrentPeriodRecurrenceLimitReached ||
      eligibility.data.isAllTimeRecurrenceLimitReached,
  );
  const { connectedOrExistingAccount, requiredAccountType } =
    useGetConnectedAccount(activation);
  const cloudFunctionsService = useCloudFunctionsService();
  const isAutomaticallyClaimed =
    ActivationUtils.isAutomaticallyClaimed(activation);

  const walletAddress = isEthereumAccount(connectedOrExistingAccount)
    ? connectedOrExistingAccount?.id
    : undefined;
  const { data } = useGetAddressInfo({
    address: walletAddress,
    blockchainType: BlockchainType.ETHEREUM,
  });
  const ensDomain = data?.displayName.includes(".eth")
    ? data.displayName
    : undefined;
  const username = isEthereumAccount(connectedOrExistingAccount)
    ? ensDomain
    : connectedOrExistingAccount?.name;

  // Don't show this checkbox, once the member already updated their username once.
  // See `generateUsername` util on the backend for more info on default username generation.
  const hasDefaultGeneratedUsername = /#[0-9]+/.test(signedInMember.username);
  const isUsernameAlreadyUpdated = !hasDefaultGeneratedUsername;
  const isUsernameUpdateEnabled =
    requiredAccountType !== undefined &&
    !isUsernameAlreadyUpdated &&
    !isAutomaticallyClaimed &&
    requiredAccountType !== MemberConnectedAccountType.SolanaWallet &&
    requiredAccountType !== MemberConnectedAccountType.AptosWallet;

  const { branding } = useMembershipBranding();

  useEffect(() => {
    if (claim) {
      updateAndVerifyOutcomes({
        outcomes: claim.outcomes.map((outcome) =>
          DtoMigrationUtils.actionOutcomeToProto(outcome),
        ),
        debounceValidation: false,
        optimistic: true,
      });
    }
  }, [isReadOnly]);

  async function onClaimQuest() {
    const claim = await controller.submitClaim();

    if (!ActivationUtils.isFailedClaim(claim)) {
      props.onRequestClose();

      if (
        isUsernameUpdateEnabled &&
        isUsernameUpdateApproved &&
        connectedOrExistingAccount &&
        username
      ) {
        try {
          await cloudFunctionsService.memberApi.orgMemberInfoControllerUpdate({
            orgId,
            memberId: signedInMember.memberId,
            updateOrgMemberInfoDto: {
              username,
              profilePictureUrl: connectedOrExistingAccount.profileImageUrl,
            },
          });

          await refetchOrgMemberInfo();
        } catch (e) {
          // Swallow this error since it is not critical to update the username
          console.error(e);
        }
      }
    }
  }

  return (
    <ClaimQuestDisplay activation={activation} isReadOnly={isReadOnly}>
      {isUsernameUpdateEnabled && username && (
        <AllowPublicUsernameUpdateCheckbox
          value={isUsernameUpdateApproved}
          onChange={setIsUsernameUpdateApproved}
          username={username}
          profilePictureUrl={connectedOrExistingAccount?.profileImageUrl}
        />
      )}
      <div className="flex flex-col space-y-[10px]">
        {!isViewingExistingClaim && (
          <SubmitButton
            activation={activation}
            onSubmit={onClaimQuest}
            onRefreshClaims={() => controller.checkForUnseenClaims()}
          />
        )}
        {!isReadOnly && (
          <HelpMessages activation={activation} branding={branding} />
        )}
      </div>
    </ClaimQuestDisplay>
  );
}

function SubmitButton(props: {
  activation: ActivationDto;
  onSubmit: () => Promise<void>;
  onRefreshClaims: () => Promise<void>;
}) {
  const { activation } = props;
  const buttonHeight = 44;
  const { isAnyValidating, isOutcomeValid, existingOutcomes } =
    useActionOutcomesProvider();
  const eligibility = useGetActivationClaimEligibility({
    membershipId: props.activation.membershipId,
    orgId: props.activation.orgId,
    activationId: props.activation.activationId,
  });
  const { branding } = useMembershipBranding();
  const validOutcomes = existingOutcomes.filter((outcome) =>
    isOutcomeValid(outcome.definitionId),
  );
  const isAutomaticallyClaimed =
    ActivationUtils.isAutomaticallyClaimed(activation);
  const isClaimValid =
    (!isAnyValidating &&
      validOutcomes.length === activation.claimRequirements.length) ||
    // We don't auto-validate twitter react claims so this allows the quest to be submitted without explicitly clicking the refresh icon
    activation.claimRequirements[0].type === ActionType.TwitterReact;

  const isCheckInQuest = activation.type === ActionType.CheckIn;
  const checkInCallToAction =
    activation.claimRequirements.find((r) => r.type === ActionType.CheckIn)
      ?.checkIn?.callToAction ?? "Check in";

  if (isAutomaticallyClaimed) {
    return (
      <AutomaticallyClaimedBanner
        activation={activation}
        onRefresh={props.onRefreshClaims}
      />
    );
  }

  if (eligibility.data === undefined) {
    return <Shimmer height={buttonHeight} />;
  }

  if (eligibility.data.isAllTimeRecurrenceLimitReached) {
    return (
      <Banner
        icon={<KazmIcon.Cross size={20} color={AppColors.gray200} />}
        title="No retries left"
        description={<span>You can't retry this quest again.</span>}
      />
    );
  }

  if (
    eligibility.data.isCurrentPeriodRecurrenceLimitReached &&
    eligibility.data.nextRecurrencePeriodStart
  ) {
    return (
      <Banner
        icon={<KazmIcon.Lock color={AppColors.gray200} />}
        title="Quest locked"
        description={
          <span>
            You can try again
            <SizedBox inline width={5} />
            <TimeAgo date={eligibility.data.nextRecurrencePeriodStart} />
          </span>
        }
      />
    );
  }

  return (
    <ActionButton
      isLoading={isAnyValidating}
      disabled={!isClaimValid || isAnyValidating}
      onClick={props.onSubmit}
      className="w-full rounded-[30px] font-semibold"
      style={{
        backgroundColor: branding?.buttonColor,
        color: branding?.buttonTextColor,
        height: buttonHeight,
      }}
    >
      {isCheckInQuest ? checkInCallToAction : "Submit"}
    </ActionButton>
  );
}

interface ClaimQuestDisplayProps {
  activation: ActivationDto;
  isReadOnly: boolean;
  children?: React.ReactNode;
}

function ClaimQuestDisplay({
  activation,
  isReadOnly,
  children,
}: ClaimQuestDisplayProps) {
  const { branding } = useMembershipBranding();
  const isManualPointsAdjustment =
    activation.type === ActionType.ManualPointAdjustment;
  const isJamesHypeYoutubeQuest =
    activation.activationId === "0f0fc52c-7626-4200-bb5f-363b6bcc87e3";
  function followJamesHypeYoutube() {
    window.open(`https://youtube.com/@JamesHype?sub_confirmation=1`, "_blank");
  }
  const isDefaultRichDescription =
    activation.richDescription === JSON.stringify(DEFAULT_EMPTY_RAW_FORMAT);

  return (
    <div className="max-h-[80vh] space-y-[20px] overflow-y-scroll">
      <div className="flex items-center justify-between gap-[10px]">
        <div className="flex items-center gap-x-[10px]">
          <ActivationTypeIcon
            activationType={activation.type}
            color={branding.textColor}
          />
          <div className="flex flex-col">
            <div
              className="font-semibold"
              style={{
                color: branding.textColor,
              }}
            >
              {activation.title}
            </div>
            {activation.recurrence && (
              <ActivationRecurrence
                className="caption mt-1 text-gray-300"
                recurrence={activation.recurrence}
                style={{ color: branding.textColor }}
              />
            )}
          </div>
        </div>
        {!isManualPointsAdjustment && (
          <ActivationPointsBadge activation={activation} enablePointsLabel />
        )}
      </div>
      {!isDefaultRichDescription && (
        <div className="overflow-hidden text-ellipsis">
          <RichTextView
            textColor={branding.textColor}
            value={JSON.parse(activation?.richDescription || "[]")}
          />
        </div>
      )}
      {isJamesHypeYoutubeQuest && (
        <div className="flex justify-end">
          <ActionButton
            onClick={followJamesHypeYoutube}
            className={classNames(
              "flex items-center justify-center gap-x-[5px] whitespace-nowrap rounded-[4px] bg-cool-purple-400 px-[10px] py-[6.5px]",
            )}
            style={{
              color: branding?.buttonTextColor,
              backgroundColor: branding?.buttonColor,
            }}
          >
            <KazmIcon.Youtube size={20} />
            <span>@JamesHype</span>
          </ActionButton>
        </div>
      )}
      <ActivationImage
        className="max-h-[400px] w-full"
        activation={activation}
        fallbackToDefaultImage={false}
      />
      <div
        className={classNames(
          "flex w-full flex-col items-center gap-[10px] rounded-default",
          {
            "pointer-events-none": isReadOnly,
          },
        )}
        style={{ color: AppColors.white }}
      >
        {activation.claimRequirements.map((definition) => (
          <ActionOutcomeBuilder
            key={definition.id}
            connectAccountTitle="Verify account"
            actionDefinition={DtoMigrationUtils.actionDefinitionToProto(
              definition,
            )}
            shouldUseMembershipBranding={false}
          />
        ))}
      </div>
      {children}
    </div>
  );
}

interface HelpMessagesProps {
  activation: ActivationDto;
  branding: Branding;
}

function HelpMessages(props: HelpMessagesProps) {
  const { activation } = props;

  const actionType = DtoMigrationUtils.actionTypeToProto(activation.type);
  const isWalletAction =
    ActionGroups.getGroupOfActionType(actionType) ===
      ActionGroupType.EVM_WALLET ||
    ActionGroups.getGroupOfActionType(actionType) ===
      ActionGroupType.SOLANA_WALLET ||
    ActionGroups.getGroupOfActionType(actionType) ===
      ActionGroupType.APTOS_WALLET;
  const requiresManualVerification =
    activation.verificationMethod === ActivationVerificationMethod.Manual &&
    CommonQuestUtils.isManuallyVerifiableAction(actionType);
  const isTwitterAPIAction = [
    MemberActionType.TWITTER_MENTION,
    MemberActionType.TWITTER_REACT,
  ].includes(actionType);
  const isTwitterLikeAction =
    isTwitterAPIAction &&
    activation.claimRequirements.some((c) => c.twitterReact?.shouldLike);

  return (
    <>
      {isWalletAction && <IosEmbedWalletHelp />}
      {requiresManualVerification && (
        <p
          className="mt-[10px] text-center text-[14px]"
          style={{
            color: props.branding.textColor,
          }}
        >
          Points awarded upon admin approval
        </p>
      )}
      {isTwitterAPIAction && (
        <p
          className="mt-[10px] text-center text-[14px]"
          style={{
            color: props.branding.textColor,
          }}
        >
          The Twitter API may take a few seconds to react
        </p>
      )}
      {isTwitterLikeAction && (
        <p
          className="mt-[10px] text-center text-[14px]"
          style={{
            color: props.branding.textColor,
          }}
        >
          Must be within your last 5 likes
        </p>
      )}
    </>
  );
}

function AutomaticallyClaimedBanner(props: {
  activation: ActivationDto;
  onRefresh: () => Promise<void>;
}) {
  const { branding } = useMembershipBranding();

  return (
    <Banner
      title="Auto-Claimed"
      description={
        <ActivationAutomaticallyClaimedMessage activation={props.activation} />
      }
    >
      <ActionButton
        onClick={props.onRefresh}
        className="rounded-[7px] p-[5px]"
        style={{
          background: branding?.buttonColor,
        }}
      >
        <KazmIcon.RotateLeft size={20} color={branding?.buttonTextColor} />
      </ActionButton>
    </Banner>
  );
}

function Banner(props: {
  title: string;
  description: ReactNode;
  icon?: ReactNode;
  children?: ReactNode;
}) {
  const { branding } = useMembershipBranding();

  return (
    <div
      className="flex justify-between rounded-[10px] bg-dark-base-darker px-5 py-4"
      style={{
        backgroundColor: branding.containerColor,
      }}
    >
      <div>
        <div className="flex items-center gap-x-[5px]">
          {props.icon}
          <span
            className="text-[14px] font-semibold"
            style={{
              color: branding.textColor,
            }}
          >
            {props.title}
          </span>
        </div>
        <SizedBox height={10} />
        <div
          className="text-[12px]"
          style={{
            color: branding.textColor,
          }}
        >
          {props.description}
        </div>
      </div>
      {props.children && (
        <div className="flex items-center">{props.children}</div>
      )}
    </div>
  );
}

function AllowPublicUsernameUpdateCheckbox(
  props: Pick<CheckboxWithLabelProps, "onChange" | "value"> & {
    username: string;
    profilePictureUrl?: string | null;
  },
) {
  const { username, profilePictureUrl, ...checkboxProps } = props;
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { branding } = useMembershipBranding();
  // Show disabled state until account is connected.
  const isDisabled = username === undefined;

  return (
    <div className="space-y-[10px]">
      <MembershipCheckboxWithLabel
        {...checkboxProps}
        disabled={isDisabled}
        title={`Update my ${loyaltyForm.privateLabel} public username and profile image`}
        className={classNames("w-full whitespace-nowrap text-gray-200", {
          "pointer-events-none opacity-[0.5]": isDisabled,
        })}
      />
      <div className="ml-[25px] flex items-center space-x-[10px]">
        {profilePictureUrl && (
          <AccessibleImage
            src={profilePictureUrl}
            alt="new profile picture"
            className="h-[30px] w-[30px] rounded-full"
          />
        )}
        <div
          className="headline-sm"
          style={{
            color: branding.textColor,
          }}
        >
          {username}
        </div>
      </div>
    </div>
  );
}

function useGetConnectedAccount(activation: ActivationDto) {
  const { data } = useGetAllCurrentMemberConnectedAccounts();
  const requiredAccountType = activation.claimRequirements
    .map((requirement) =>
      getRequiredOrgMemberAccountTypeByActionType(
        DtoMigrationUtils.actionTypeToProto(requirement.type),
      ),
    )
    .find((requiredAccountType) => requiredAccountType !== undefined);

  const connectedOrExistingAccount = data?.find(
    (account) => account.accountType === requiredAccountType,
  );

  return {
    connectedOrExistingAccount,
    requiredAccountType,
  };
}

function isEthereumAccount(account: ConnectedAccountDto | undefined) {
  return account?.accountType === MemberConnectedAccountType.EthereumWallet;
}

function isSolanaAccount(account: ConnectedAccountDto | undefined) {
  return account?.accountType === MemberConnectedAccountType.SolanaWallet;
}
