import { useCallback, useMemo } from "react";
import { Row } from "react-table";

import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider";
import { ExtendedColumn } from "@common/table/Table";
import {
  MemberInfo,
  PropertyDefinition,
  PropertyType,
} from "@juntochat/kazm-shared";
import {
  useCurrentOrgDataSources,
  useListMemberTags,
} from "@utils/hooks/use_cache";
import { useMemberProperties } from "@utils/hooks/use_member_properties";

import KazmUtils from "../../../utils/utils";

import { defaultColumnWidth, MemberColumn } from "./MemberColumn";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";

export type MemberColumnsState = {
  isLoading: boolean;
  columns: MemberColumnDefinition[] | undefined;
};

export type DefineColumnOptions = {
  propertyDefinition: PropertyDefinition;
};

export type ExportColumnOptions = {
  propertyDefinition: PropertyDefinition;
  member: MemberInfo;
};

export type MemberColumnDefinition = DefineColumnOptions & {
  isUserInfoColumn: boolean;
  // If true, user can't hide it using the column edit feature.
  isAlwaysShown: boolean;
  leftToRightPosition: number;
  tableColumn: ExtendedColumn<MemberInfo>;
  getExportValue: (member: MemberInfo) => number | string | undefined;
};

export function useMemberColumns(): MemberColumnsState {
  const orgId = useCurrentOrgId();
  const { propertyDefinitions } = usePropertyRegistry();
  const { findProperty } = useMemberProperties();
  const { data: dataSourceInfo } = useCurrentOrgDataSources();
  const tags = useListMemberTags({
    orgId,
  });

  const getExportValue = useCallback(
    (options: ExportColumnOptions) => {
      const { member, propertyDefinition } = options;
      const { property } =
        findProperty(member, {
          propertyDefinitionId: propertyDefinition.id,
        }) ?? {};

      if (
        propertyDefinition.propertyType === PropertyType.PROPERTY_ACCOUNT_TAG
      ) {
        const tagIds = KazmUtils.deduplicatePrimitives(property?.value ?? []);
        const tagNames = tagIds
          ?.map((tagId) => tags?.data?.data.find((tag) => tag.id === tagId))
          .filter(KazmUtils.isDefined)
          ?.map((tag) => tag.title);
        return String(tagNames);
      }

      return property?.value ? String(property?.value) : "-";
    },
    [findProperty, tags.data],
  );

  const createColumn = useCallback(
    (options: DefineColumnOptions): MemberColumnDefinition => {
      const { propertyDefinition } = options;

      const isUserInfoColumn =
        propertyDefinition.propertyType === PropertyType.PROPERTY_ACCOUNT_TYPES;

      // User info is a special-case column.
      // It shows account avatar, username and connected account icons,
      // so it must be wider than all the others.
      const userInfoColumnWidth = 350;
      const columnWidth = isUserInfoColumn
        ? userInfoColumnWidth
        : defaultColumnWidth;

      const tableColumn: ExtendedColumn<MemberInfo> = {
        id: propertyDefinition.id,
        Header: () => (
          <MemberColumn.Header propertyDefinition={propertyDefinition} />
        ),
        Cell: ({ row }: { row: Row<MemberInfo> }) => (
          <MemberColumn.Body
            member={row.original}
            propertyDefinition={propertyDefinition}
          />
        ),
        isSortable: true,
        style: { width: columnWidth, flexGrow: "1" },
      };

      const leftToRightPosition = getLeftToRightPosition(
        propertyDefinition.propertyType,
      );
      const isAlwaysShown =
        propertyDefinition.propertyType === PropertyType.PROPERTY_ACCOUNT_TYPES;

      return {
        propertyDefinition,
        isUserInfoColumn,
        isAlwaysShown,
        leftToRightPosition,
        getExportValue: (member) =>
          getExportValue({ member, propertyDefinition }),
        tableColumn,
      };
    },
    [getExportValue],
  );

  const columns = useMemo(() => {
    const hiddenProperties = new Set([
      PropertyType.PROPERTY_ACCOUNT_TYPES_IDS,
      PropertyType.PROPERTY_ACCOUNT_IDS,
      PropertyType.PROPERTY_MEMBER_ID,
      PropertyType.PROPERTY_ACCOUNT_NAME,
      PropertyType.PROPERTY_ACCOUNT_DATA_SOURCE_IDS,
      PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_ID,
    ]);
    return propertyDefinitions
      ?.filter(
        (propertyDefinition) =>
          !hiddenProperties.has(propertyDefinition.propertyType),
      )
      ?.map((propertyDefinition) =>
        createColumn({
          propertyDefinition,
        }),
      );
  }, [createColumn, propertyDefinitions]);

  return {
    isLoading: !dataSourceInfo,
    columns,
  };
}

function getLeftToRightPosition(propertyType: PropertyType) {
  switch (propertyType) {
    case PropertyType.PROPERTY_ACCOUNT_TYPES:
      return 0;
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_POINTS:
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_LEVEL:
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_ID:
      return 1;
    case PropertyType.PROPERTY_ACCOUNT_JOIN_DATE:
      return 2;
    case PropertyType.PROPERTY_ACCOUNT_TAG:
      return 3;
    case PropertyType.PROPERTY_ACCOUNT_EMAIL:
      return 4;
    case PropertyType.PROPERTY_TWITTER_FOLLOWERS:
      return 5;
    default:
      return 6;
  }
}
