import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";

import { useOutcomeBuilderProvider } from "@/modules/actions";
import { VerificationButton } from "../Ethereum/Ethereum";
import { LinkFulfillmentButton } from "../common/DefaultButtons";
import { OutcomeBuilderContainer } from "../common/OutcomeBuilderContainer";

export function AptosOwnNftOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const nfts = definition.aptosOwnNft?.anyOfNfts ?? [];

  return (
    <OutcomeBuilderContainer
      title="Hold a NFT"
      bottom={
        <div className="space-y-[10px]">
          {nfts.map((nft) => {
            return (
              <div
                key={nft.id}
                className="flex w-full items-center justify-between gap-[10px]"
              >
                <div
                  className="w-[180px] truncate"
                  style={{
                    fontSize: 14 * textSizeMultiplier,
                    color: branding?.textColor,
                  }}
                >
                  {nft.minimumBalance} x {nft.name}
                </div>
                <LinkFulfillmentButton title="Get NFTs" url={nft.link} />
              </div>
            );
          })}
        </div>
      }
    >
      <div className="flex items-center gap-[10px]">
        <VerificationButton />
      </div>
    </OutcomeBuilderContainer>
  );
}
