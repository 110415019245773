import { format, isValid } from "date-fns";
import { saveAs } from "file-saver";
import { FirebaseError } from "firebase/app";
import { User } from "firebase/auth";
import { isValidNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import { unparse as unparseCsv } from "papaparse";
import validator from "validator";

import { ContractType, ResponseError } from "@juntochat/internal-api";
import {
  BlockchainType,
  LegacyContractType,
  MemberProperty,
  OffsetPaginationRequest,
  OffsetPaginationResponse,
  PointsType,
  PropertyDefinition,
  blockchainTypeToJSON,
} from "@juntochat/kazm-shared";

import { AxiosError, isAxiosError } from "axios";
import { readableNumber } from "./text_utils";

type PaginatedResponse<T> = {
  offsetPagination: OffsetPaginationResponse | undefined;
  items: T[];
};

type CsvEntry = {
  label: string;
  value: string | undefined;
};

export enum ClientLibraryErrorStatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
}

// https://en.wikipedia.org/wiki/Multimap
export class MultiMap<K, V> extends Map<K, V[]> {
  constructor(entries?: readonly (readonly [K, V])[] | null) {
    super();

    for (const entry of entries ?? []) {
      const [key, value] = entry;
      const existingEntries = this.get(key) ?? [];
      existingEntries.push(value);
      this.set(key, existingEntries);
    }
  }
}

type AppEnvironment = {
  useCloudFunctionsEmulator: boolean;
  usePosthog: boolean;
  environment: "dev" | "staging" | "production";
  isDev: boolean;
  isStaging: boolean;
  isProduction: boolean;
  functionsV2Url: string;
  useAnalytics: boolean;
  useSendGrid: boolean;
  enableYoutubeMemberConnection: boolean;
  enableNewOnboarding: boolean;
};

// TODO: Move blockchain related util functions to blockchain_utils.ts
class KazmUtils {
  static capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.substring(1, text.length);
  }

  static areObjectsEqual(a: unknown, b: unknown): boolean {
    if (a === b) {
      return true;
    }
    return JSON.stringify(a) === JSON.stringify(b);
  }

  // Return a comparator function, that can be passed to `array.sort`.
  // Callback should return `true` if that element should be placed at the beginning of the array.
  static sortAtFrontComparator<E>(shouldBeAtFront: (e: E) => boolean) {
    return function (a: E, b: E) {
      if (shouldBeAtFront(b) === shouldBeAtFront(a)) {
        return 0;
      } else if (shouldBeAtFront(b)) {
        return 1;
      } else {
        return -1;
      }
    };
  }

  static getInvertedLookup<Key, Value>(
    lookup: Map<Key, Value | Value[]>,
  ): Map<Value, Key> {
    const entries: [Value, Key][] = [];

    lookup.forEach((value, key) => {
      if (Array.isArray(value)) {
        (value as Value[]).forEach((v) => {
          entries.push([v, key]);
        });
      } else {
        entries.push([value as Value, key]);
      }
    });

    return new Map(entries);
  }

  static parsePolygonWagmiError(error: any) {
    function getFormattedMessage() {
      const rawMessage =
        error.data?.message ??
        error.cause?.reason ??
        error.message ??
        "Unknown error";

      // The raw error is in format:
      // "err: insufficient funds for gas * price + value: address <address> have 0 want <wei> (supplied gas <wei>)"
      const insufficientFundsMatch = /insufficient funds .* want ([0-9]+)/.exec(
        rawMessage,
      );
      if (insufficientFundsMatch) {
        const [_, firstGroupMatch] = insufficientFundsMatch;
        const expectedValueInWei = Number(firstGroupMatch);
        return `Insufficient funds in your wallet. Need at least ${KazmUtils.formatWeiInEth(
          expectedValueInWei,
        )} MATIC`;
      }

      return rawMessage;
    }

    return {
      isRequestRejected: error?.name === "UserRejectedRequestError",
      message: getFormattedMessage(),
    };
  }

  static deduplicateByCustomKey<Item>(options: {
    items: Item[];
    getUniqueKey: (item: Item) => string;
  }): Item[] {
    const lookup = new Map(
      options.items.map((item) => [options.getUniqueKey(item), item]),
    );
    return [...lookup.values()];
  }

  static getAddressInfoUrl(props: {
    address: string;
    blockchain: BlockchainType;
  }) {
    switch (props.blockchain) {
      case BlockchainType.ETHEREUM:
        return `https://etherscan.io/address/${props.address}`;
      case BlockchainType.POLYGON:
        return `https://polygonscan.com/address/${props.address}`;
      case BlockchainType.IMMUTABLE_X:
        return `https://immutascan.io/address/${props.address}`;
      case BlockchainType.BASE:
        return `https://basescan.org/address/${props.address}`;
      case BlockchainType.AVAX:
        return `https://snowtrace.io/address/${props.address}`;
      default:
        throw new Error(
          `\`getAddressInfoUrl\` not implement for blockchain: ${blockchainTypeToJSON(
            props.blockchain,
          )}`,
        );
    }
  }

  static computeOneToManyLookup<Key = string, Item = unknown>(options: {
    items: Item[];
    keyField: keyof Item;
  }): Map<Key, Item[]> {
    const getKey = (item: Item) => item[options.keyField] as unknown as Key;
    const lookup = new Map<Key, Item[]>();
    for (const item of options.items) {
      const existingItems = lookup.get(getKey(item)) ?? [];
      existingItems.push(item);
      lookup.set(getKey(item), existingItems);
    }
    return lookup;
  }

  static deduplicateObjects<Item>(options: {
    items: Item[];
    deduplicateByFields: (keyof Item)[];
  }): Item[] {
    const lookup = new Map(
      options.items.map((item) => [
        options.deduplicateByFields.map((key) => item[key]).join(),
        item,
      ]),
    );
    return [...lookup.values()];
  }

  static deduplicatePrimitives<Item = number | string | undefined>(
    items: Item[],
  ): Item[] {
    return Array.from(new Set(items));
  }

  static splitArray<Item>(
    array: Item[],
    conditionCallback: (item: Item) => boolean,
  ) {
    const firstPart = array.filter((e) => conditionCallback(e));
    const secondPart = array.filter((e) => !conditionCallback(e));
    return [firstPart, secondPart];
  }

  static safeJsonParse(raw: string): unknown | undefined {
    try {
      return JSON.parse(raw) as unknown;
    } catch (e) {
      console.error("Error while parsing object from local storage:", e);
      return undefined;
    }
  }

  // Will collapse the text with "..." as infix
  // Example: HelloWorld -> Hell...rld
  static collapseText(
    value: string,
    options: {
      maxLength: number;
    },
  ) {
    const { maxLength } = options;
    if (maxLength >= value.length) {
      return value;
    }
    const infix = "...";
    const collapsedLength = Math.min(value.length, maxLength) - infix.length;
    const prefixLength = Math.round(collapsedLength / 2);
    const postfixLength = Math.floor(collapsedLength / 2);
    const prefix = value.substring(0, prefixLength);
    const postfix = value.substring(value.length - postfixLength, value.length);
    return `${prefix}${infix}${postfix}`;
  }

  static formatDate(value: unknown) {
    return typeof value === "string" && isValid(new Date(value))
      ? format(new Date(value), "MMM d, yyyy")
      : "-";
  }

  static mergeArraysWithSum(arrays: number[][]) {
    const result: number[] = [];
    for (let i = 0; i < arrays[0].length; i++) {
      let sum = 0;
      for (let j = 0; j < arrays.length; j++) {
        sum += arrays[j][i];
      }
      result.push(sum);
    }
    return result;
  }

  static isCastableToNumber(value: unknown): value is string {
    // Arrays are for some reason castable to numbers with Number([])
    // that's why we need to validate that value is not an object (array)
    return typeof value !== "object" && !Number.isNaN(Number(value));
  }

  static isCallable<T>(maybeFunc: unknown): maybeFunc is T {
    return typeof maybeFunc === "function";
  }

  static isDefined<Value>(value: Value | null | undefined): value is Value {
    return value !== null && value !== undefined;
  }

  static isNotFalsy<Value>(
    value: Value | null | undefined | false | number | string,
  ): value is Value {
    return Boolean(value);
  }

  static getApproxNumberOfAllRows<DataType extends object>(
    response: PaginatedResponse<DataType> | undefined,
  ) {
    const rowsPerPage = response?.items.length ?? 0;
    const numOfPages = response?.offsetPagination?.totalPages ?? 0;
    return rowsPerPage * numOfPages;
  }

  static formatEstimatedItemsCount<DataType extends object>(
    request: OffsetPaginationRequest | undefined,
    response: PaginatedResponse<DataType> | undefined,
    maxPageNumber: number,
  ) {
    if (!response || !request) return "";
    if ((response?.offsetPagination?.totalPages ?? 0) <= 1) {
      // If there is a single page, just return the number of items in that page.
      // If there are no pages return 0.
      return response.items.length;
    }
    if (response?.offsetPagination?.totalPages !== undefined) {
      // There is at least as many items, as there are items on all the pages,
      // except the last one (which may be incomplete).
      const minNumberOfItems =
        (response.offsetPagination.totalPages - 1) * request.pageSize;
      return `${minNumberOfItems}+`;
    }
    // There is at least as many items, as there are items on all the loaded pages.
    return `${request.pageSize * (maxPageNumber + 1)}+`;
  }

  static formatNumberAsCurrency(val: number, round = false) {
    if (round) {
      return "$" + val.toFixed(0).replace(/\d(?=(\d{3})$)/g, "$&,");
    } else {
      return "$" + val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
  }

  /** Proxy HTTP GET requests through our servers to avoid CORS errors */
  static corsProxyGet(uri: string): string {
    return `https://us-central1-juntochat.cloudfunctions.net/ImageProxy?url=${encodeURIComponent(
      uri,
    )}`;
  }

  static isLegacyClientLibraryErrorType(
    error: any,
    errorType: ClientLibraryErrorStatusCode,
  ): error is AxiosError {
    return isAxiosError(error) && error.response?.status === errorType;
  }

  static isClientLibraryErrorType(
    error: any,
    errorType: ClientLibraryErrorStatusCode,
  ): error is ResponseError {
    return (
      this.isApiResponseError(error) && error.response.status === errorType
    );
  }

  static isNotFoundError(error: any): boolean {
    const legacyNotFoundError = Boolean(
      error &&
        error instanceof FirebaseError &&
        error.code === "functions/not-found",
    );
    return (
      legacyNotFoundError ||
      this.isClientLibraryErrorType(
        error,
        ClientLibraryErrorStatusCode.NotFound,
      )
    );
  }

  static extensionFromUrl(url: string): string | undefined {
    try {
      const uri = new URL(url);
      const pathSegments = uri.pathname.split("/");
      const lastSegmentParts = pathSegments[pathSegments.length - 1].split(".");
      return lastSegmentParts.length > 1
        ? lastSegmentParts[lastSegmentParts.length - 1]
        : undefined;
    } catch (e) {
      console.log(e);
      console.log(`Failed to parse ${url}`);
      return undefined;
    }
  }

  static isValidURL(url: string) {
    return validator.isURL(url);
  }

  static isValidEmail(email: string) {
    return validator.isEmail(email);
  }

  static isValidPhoneNumber(number: string) {
    const parsedNumber = parsePhoneNumberFromString(number);
    return parsedNumber ? isValidNumber(number) : false;
  }

  static isSpotifyId(id: string) {
    const spotifyIdFormat = /^[0-9a-zA-Z]{22}$/;

    return spotifyIdFormat.test(id);
  }

  static formatURL(url: string) {
    let sanitisedURL = url.replaceAll("https://", "");
    sanitisedURL = sanitisedURL.replaceAll("http://", "");

    return "https://" + sanitisedURL;
  }

  static getEnvParams(): AppEnvironment {
    const coreEnvVariables = {
      useCloudFunctionsEmulator:
        import.meta.env.VITE_USE_CLOUD_FUNCTIONS_EMULATOR === "true",
      environment: import.meta.env.VITE_ENVIRONMENT ?? "dev",
      isDev: import.meta.env.VITE_ENVIRONMENT === "dev",
      isStaging: import.meta.env.VITE_ENVIRONMENT === "staging",
      isProduction: import.meta.env.VITE_ENVIRONMENT === "production",
      enableImmutableXAddContracts:
        import.meta.env.VITE_ENABLE_IMMUTABLE_X_ADD_CONTRACTS === "true",
    };

    return {
      enableNewOnboarding: false,
      ...coreEnvVariables,
      functionsV2Url: coreEnvVariables.isDev ? "b547hy6ilq" : "u6lnikeoka",
      usePosthog: coreEnvVariables.isProduction,
      useAnalytics: coreEnvVariables.isProduction,
      useSendGrid: coreEnvVariables.isProduction,
      // Keep disabled until Google approves our API scopes.
      // https://www.notion.so/kazm/Complete-Google-app-verification-for-connecting-YouTube-accounts-16dd8aabda844e92b63fe200ba649469?pvs=4
      enableYoutubeMemberConnection: false,
    };
  }

  static async runAndTimeFuture<T>(future: Promise<T>): Promise<{
    executionTime: number;
    result: T;
  }> {
    const startTime = Date.now();
    const result = await future;
    const endTime = Date.now();

    return {
      executionTime: endTime - startTime,
      result,
    };
  }

  static wait(milliseconds: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), milliseconds));
  }

  static hexColorWithOpacity(hex: string, opacity: number) {
    if (opacity < 0 || opacity > 1) {
      throw new Error("Opacity must be between 0 and 1");
    }
    const scaledOpacity = Math.round(opacity * 255);
    const hexOpacity = scaledOpacity.toString(16);
    return `${hex}${hexOpacity}`;
  }

  static updateArrayCopy<T>(array: T[], index: number, value: T) {
    return [
      ...array.slice(0, index),
      value,
      ...array.slice(index + 1, array.length),
    ];
  }

  static convertWeiToEth(weiValue: number) {
    return weiValue / Math.pow(10, 18);
  }

  static convertEthToWei(ethValue: number) {
    return ethValue * Math.pow(10, 18);
  }

  static formatWeiInEth(weiValue: number | undefined) {
    return weiValue !== undefined
      ? `${readableNumber(this.convertWeiToEth(weiValue))}`
      : "-";
  }

  static getCsvHeaderAndRowData(data: {
    propertyDefinitions: PropertyDefinition[];
    submissions: { properties: MemberProperty[] }[];
  }) {
    const csvData = data.submissions.map((submission): CsvEntry[] => {
      const submissionPropertyLookup = new Map<string, MemberProperty>(
        submission.properties.map((p) => [p.propertyDefinitionId, p]),
      );

      const row = data.propertyDefinitions.map(
        (propertyDefinition): CsvEntry => {
          const property = submissionPropertyLookup.get(propertyDefinition.id);

          return {
            label: propertyDefinition.title,
            value: property?.value ?? "",
          };
        },
      );

      return row;
    });

    if (csvData.length === 0) {
      return {
        headers: [],
        rows: [],
      };
    }

    const headers = csvData[0].map((row) => row.label);
    const rows = csvData.map((row) => row.map((col) => col.value ?? "-"));

    if (rows.length > 0 && headers.length !== rows[0]?.length) {
      throw Error("Column labels and data rows must have the same length");
    }

    return {
      headers,
      rows,
    };
  }

  static downloadAsCsvFile(
    columnLabels: string[],
    dataRows: (number | string)[][],
    filename: string,
  ) {
    if (dataRows.length > 0 && columnLabels.length !== dataRows[0]?.length) {
      throw Error("Column labels and data rows must have the same length");
    }
    const csv = unparseCsv(
      { data: dataRows, fields: columnLabels },
      {
        quotes: true,
      },
    );
    const blob = new Blob([csv], { type: "text/csv" });
    console.log(
      `Downloading CSV file ${filename} (rows=${dataRows.length}, size=${blob.size} bytes)`,
    );
    saveAs(blob, filename);
  }

  static stringifyJSON(json: any) {
    return encodeURIComponent(JSON.stringify(json));
  }

  static getRoutes() {
    return {
      projects: "/projects",
      invite: "/invite",
    };
  }

  static removeUndefinedAndEmptyString<T extends Record<string, any>>(
    obj: T,
  ): T {
    Object.keys(obj).forEach((key: string) => {
      const value = obj[key];
      if (value === undefined || (typeof value == "string" && !value)) {
        delete obj[key];
      }
    });

    return obj;
  }

  static shortenWalletAddress(address: string) {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  }

  static removeUndefined<T extends Record<string, any>>(obj: T): T {
    Object.keys(obj).forEach((key: string) => {
      const value = obj[key];
      if (value === undefined) {
        delete obj[key];
      }
    });

    return obj;
  }

  // Opens a url to follow the specified twitter user
  static getFollowIntent(accountId: string) {
    return `https://twitter.com/intent/follow?user_id=${accountId}`;
  }

  static getSpotifyArtistUrl(artistId: string) {
    return `https://open.spotify.com/artist/${artistId}`;
  }

  static getSpotifyTrackUrl(trackId: string) {
    return `https://open.spotify.com/track/${trackId}`;
  }

  static getKazmOnboardingVideoUrl() {
    return "https://res.cloudinary.com/junto/video/upload/tour/onboard.mp4";
  }

  static getHelpDocsUrl() {
    return "https://kazm.notion.site/Kazm-Help-Docs-dae0c0fd47b9477e88f73fb1de2ede1d";
  }

  static isKazmAdmin(user: User | undefined) {
    return Boolean(user?.email?.endsWith("@kazm.com") && user?.emailVerified);
  }

  static async tryOpenNewTab(args: {
    url: string;
    onBlocked?: (url: string) => void;
  }) {
    const { url, onBlocked } = args;
    const win = window.open(url, "_blank");
    win?.focus();

    if (!win || win.closed || typeof win.closed == "undefined") {
      onBlocked?.(url);
    }
  }

  static async getOpenNewTab(args: {
    getUrl: () => Promise<string>;
    onBlocked?: (url: string) => void;
  }): Promise<{ url: string; openUrl: () => void }> {
    const { getUrl, onBlocked: onPopupBlocked } = args;
    const url = await getUrl();

    function openUrl() {
      KazmUtils.tryOpenNewTab({
        url,
        onBlocked: onPopupBlocked,
      });
    }

    return { url, openUrl };
  }

  static isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static isApiResponseError(error: unknown): error is ResponseError {
    // We can't just say `error instanceof ResponseError`
    // because ResponseError class is transpiled to a function implementation.
    return typeof error === "object" && error !== null && "response" in error;
  }

  static API_DOCS_LINK = "https://api.kazm.com";
  static ABOUT_KAZM_LINK = "https://join.kazm.com";
  static INFURA_API_KEY = "a6e82f6414e24bedb5617b8d2039784f";

  static buildPointsLabel(pointsType: PointsType) {
    switch (pointsType) {
      case PointsType.BALANCE_POINTS:
        return "Points Balance";
      case PointsType.LIFETIME_POINTS:
      case PointsType.UNSPECIFIED_POINTS:
        return "Lifetime Points";
      default:
        throw new Error("Invalid points type");
    }
  }

  static getURLFavicon(url: string) {
    return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`;
  }

  static legacyContractTypeTooContractType(
    type: LegacyContractType,
  ): ContractType {
    switch (type) {
      case LegacyContractType.CONTRACT_TYPE_UNSPECIFIED:
        return ContractType.ContractTypeUnspecified;
      case LegacyContractType.ERC1155_CONTRACT:
        return ContractType.Erc1155Contract;
      case LegacyContractType.ERC20_CONTRACT:
        return ContractType.Erc20Contract;
      case LegacyContractType.ERC721_CONTRACT:
        return ContractType.Erc721Contract;
      default:
        return ContractType.Unrecognized;
    }
  }

  // Also set in index.html
  static RECAPTCHA_V3_CLIENT_KEY = "6Le6RNMpAAAAAB9z-jUPOYcPJhFrg00BM69bYwQg";

  static RECAPTCHA_V2_CLIENT_KEY = "6Lf8btMpAAAAAGYlgX6IwqkMmbzoiEfCfrSkZoDa";

  static getCurrentPage() {
    const path = window.location.pathname;
    const parts = path.split("/");
    return parts[parts.length - 1];
  }
}

export default KazmUtils;
