import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { PropsWithChildren } from "react";
import { Network } from "@aptos-labs/ts-sdk";
import { ToastUtils } from "@/utils/toast_utils";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";

export const AptosWalletProvider = ({ children }: PropsWithChildren) => {
  const plugins = [new MartianWallet()];

  return (
    <AptosWalletAdapterProvider
      plugins={plugins}
      optInWallets={["Petra", "Nightly"]}
      dappConfig={{ network: Network.MAINNET }}
      onError={(error) => {
        ToastUtils.showErrorToast("Error connecting Aptos wallet");
        console.log("error", error);
      }}
    >
      {children}
    </AptosWalletAdapterProvider>
  );
};
