import { ActionButton } from "@/common_components/buttons/ActionButton";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import {
  OrgConnectedAccountTypeIcon,
  orgConnectedAccountName,
} from "@/common_components/data_source/OrgConnectedAccount";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@/common_components/overlays/modals/ConfirmModal";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useOrgConnectedAccountsController } from "@/utils/hooks/org_connected_accounts_controller";
import { useConnectOrgAccount } from "@/utils/hooks/use_connect_org_account";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import {
  OrgConnectedAccountDto,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import classNames from "classnames";

interface EditableConnectionCardProps {
  type: OrgConnectedAccountType;
  connectedAccount: OrgConnectedAccountDto;
}

export function EditableConnectionCard({
  type,
  connectedAccount,
}: EditableConnectionCardProps) {
  const title = orgConnectedAccountName(connectedAccount);
  const id = connectedAccount.id;

  return (
    <UnstyledButton
      className={classNames(
        "w-full rounded-[10px] border-[1.4px] border-gray-300 p-[21px]",
        {
          "border-dashed opacity-70 ": !id,
        },
      )}
    >
      <div className="flex w-full items-center justify-between space-x-[10px]">
        <div className="flex w-[90%] items-center space-x-[14px]">
          <OrgConnectedAccountTypeIcon connectedAccountType={type} size={28} />
          <div className="truncate text-[19.67px]">{title}</div>
        </div>
        <div className="flex items-center space-x-[10px]">
          <EditConnectionButton
            type={type}
            connectedAccount={connectedAccount}
          />
          <DeleteConnectionButton accountId={id} title={title} />
        </div>
      </div>
    </UnstyledButton>
  );
}

interface EditConnectionButtonProps {
  type: OrgConnectedAccountType;
  connectedAccount: OrgConnectedAccountDto;
}

function EditConnectionButton({
  type,
  connectedAccount,
}: EditConnectionButtonProps) {
  const { editAccount } = useConnectOrgAccount();

  if (!isBlockchainAccount(type)) {
    return null;
  }

  return (
    <ActionButton
      onClick={() =>
        editAccount({ type, orgConnectedAccount: connectedAccount })
      }
      className="!text-gray-300 hover:!text-white"
    >
      <KazmIcon.Pencil size={20} />
    </ActionButton>
  );
}

function isBlockchainAccount(type: OrgConnectedAccountType) {
  return (
    type === OrgConnectedAccountType.EthereumAccount ||
    type === OrgConnectedAccountType.ImxAccount ||
    type === OrgConnectedAccountType.PolygonAccount ||
    type === OrgConnectedAccountType.SolanaAccount ||
    type === OrgConnectedAccountType.AptosAccount
  );
}

interface DeleteConnectionButtonProps {
  accountId: string;
  title: string;
}

function DeleteConnectionButton({
  accountId,
  title,
}: DeleteConnectionButtonProps) {
  const orgId = useCurrentOrgId();
  const { showConfirmModal, controller: confirmModalController } =
    useConfirmModalController<ConfirmDeletionModalProps>();
  const { refetchAccounts } = useOrgConnectedAccountsController();
  const cloudFunctionsService = useCloudFunctionsService();

  async function deleteOrgConnectedAccount() {
    await cloudFunctionsService.orgAdminApi.orgConnectedAccountControllerDelete(
      {
        orgId,
        orgConnectedAccountId: accountId,
      },
    );

    await refetchAccounts();
  }

  return (
    <>
      <ConfirmDeletionModal controller={confirmModalController} />
      <ActionButton
        className="!text-gray-300 hover:!text-red-200"
        onClick={() =>
          showConfirmModal(() => deleteOrgConnectedAccount(), {
            title: "Remove connected account",
            description: (
              <div className="space-y-4">
                <div>
                  Are you sure you wanna remove your connected account{" "}
                  <code>{title}</code>?
                </div>
                <div>You can always re-connect it later.</div>
              </div>
            ),
          })
        }
      >
        <KazmIcon.Bin size={20} />
      </ActionButton>
    </>
  );
}
