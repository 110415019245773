/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const MemberConnectedAccountType = {
    EthereumWallet: 'ETHEREUM_WALLET',
    SolanaWallet: 'SOLANA_WALLET',
    TwitterAccount: 'TWITTER_ACCOUNT',
    DiscordAccount: 'DISCORD_ACCOUNT',
    InstagramAccount: 'INSTAGRAM_ACCOUNT',
    YoutubeAccount: 'YOUTUBE_ACCOUNT',
    Email: 'EMAIL',
    TelegramAccount: 'TELEGRAM_ACCOUNT',
    UnstoppableDomain: 'UNSTOPPABLE_DOMAIN',
    SpotifyAccount: 'SPOTIFY_ACCOUNT',
    TikTokAccount: 'TIK_TOK_ACCOUNT',
    KazmMemberId: 'KAZM_MEMBER_ID',
    Phone: 'PHONE',
    FacebookAccount: 'FACEBOOK_ACCOUNT',
    AptosWallet: 'APTOS_WALLET',
    Unknown: 'UNKNOWN'
};
export function MemberConnectedAccountTypeFromJSON(json) {
    return MemberConnectedAccountTypeFromJSONTyped(json, false);
}
export function MemberConnectedAccountTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function MemberConnectedAccountTypeToJSON(value) {
    return value;
}
