import { uuidv4 } from "@firebase/util";
import {
  BlockchainType,
  MemberActionDefinition,
  OrgDataSource,
  AptosOwnNftDefinition,
  AptosTokenDefinition,
} from "@juntochat/kazm-shared";
import { useEffect, useState } from "react";

import { useConnectEditBlockchainContractProvider } from "@/providers/connect_edit_blockchain_contract_provider";
import { useCurrentOrgContractSources } from "@/utils/hooks/use_current_org_contract_infos";
import { BlockchainUtils } from "@utils/blockchain_utils.ts";
import { ActionDefinitionValidationError } from "../../action_definition_validation_service";
import { MemberActionDefinitionManager } from "../interface";

interface UseAptosOwnNftControllerProps extends MemberActionDefinitionManager {
  validateDefinition: (
    memberActionDefinition: MemberActionDefinition,
  ) => ActionDefinitionValidationError[];
}

export interface AptosOwnNftController {
  isDirty: boolean;
  setIsDirty: (val: boolean) => void;
  allAptosContracts: OrgDataSource[];
  nfts: AptosTokenDefinition[];
  toggleOwnNft: (nft: AptosTokenDefinition) => void;
  editNft: (nft: Partial<AptosTokenDefinition>) => void;
  connectAptosSource: () => void;
  addCustomNft: () => void;
  removeNft: (nftId: string) => void;
}

export function useAptosOwnNftController({
  actionDefinition,
  setActionDefinition,
  validateDefinition,
}: UseAptosOwnNftControllerProps): AptosOwnNftController {
  const [isDirty, setIsDirty] = useState(false);
  const anyOfNfts = actionDefinition?.aptosOwnNft?.anyOfNfts ?? [];
  const { aptosTokenContracts } = useCurrentOrgContractSources();

  function toggleOwnNft(newNft: AptosTokenDefinition) {
    const nftAlreadyIncluded = Boolean(
      anyOfNfts?.find((nft) => nft.id === newNft.id),
    );
    let definition = actionDefinition;

    if (nftAlreadyIncluded) {
      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        aptosOwnNft: AptosOwnNftDefinition.fromPartial({
          anyOfNfts: anyOfNfts.filter((nft) => nft.id !== newNft.id),
        }),
      });
    } else {
      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        aptosOwnNft: AptosOwnNftDefinition.fromPartial({
          anyOfNfts: [...anyOfNfts, newNft],
        }),
      });
    }

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function editNft(nftData: Partial<AptosTokenDefinition>) {
    const filteredNfts = anyOfNfts?.filter((nft) => nft.id !== nftData.id);

    const nft = anyOfNfts.find((nft) => nft.id === nftData.id);
    const updatedNftData = AptosTokenDefinition.fromPartial({
      ...nft,
      ...nftData,
    });

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      aptosOwnNft: AptosOwnNftDefinition.fromPartial({
        anyOfNfts: [...filteredNfts, updatedNftData],
      }),
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const { addEditContract } = useConnectEditBlockchainContractProvider();

  function connectAptosSource() {
    addEditContract({ type: BlockchainType.APTOS });
  }

  function addCustomNft() {
    const customNFT = AptosTokenDefinition.fromPartial({
      id: uuidv4(),
      createdDate: new Date().toISOString(),
    });

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      aptosOwnNft: AptosOwnNftDefinition.fromPartial({
        anyOfNfts: [...anyOfNfts, customNFT],
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function removeNft(nftId: string) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      aptosOwnNft: AptosOwnNftDefinition.fromPartial({
        anyOfNfts: anyOfNfts.filter((nft) => nft.id !== nftId),
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (anyOfNfts.length === 0) {
      const firstAptosSource = aptosTokenContracts?.[0];

      if (firstAptosSource) {
        toggleOwnNft({
          id: uuidv4(),
          name: firstAptosSource.name,
          tokenAddress:
            BlockchainUtils.getDataSourceContractAddress(firstAptosSource),
          createdDate: new Date().toISOString(),
          minimumBalance: "1",
          link: "",
        });
      }
    }
  }, []);

  return {
    isDirty,
    setIsDirty,
    allAptosContracts: aptosTokenContracts,
    nfts: anyOfNfts,
    toggleOwnNft,
    editNft,
    connectAptosSource,
    addCustomNft,
    removeNft,
  };
}
