import { Tooltip } from "@common/overlays/tooltip/Tooltip.tsx";
import { MemberInfo } from "@juntochat/kazm-shared";
import { getMemberUsername } from "@/projects/members/table/member_utils.ts";

type MemberUsernameProps = {
  showTooltip?: boolean;
  member: MemberInfo;
};

export function MemberUsername(props: MemberUsernameProps) {
  const username = getMemberUsername(props.member);
  return (
    <span className="display-[inline-block] text-overflow-ellipsis mx-[5px] max-w-[100%] overflow-hidden text-left">
      <Tooltip
        disabled={!props.showTooltip}
        maxWidth={500}
        tooltipContent={username}
      >
        {username}
      </Tooltip>
    </span>
  );
}
