/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const NftDefinitionDtoBlockchainEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_MINUS_1: -1
};
/**
 * Check if a given object implements the NftDefinitionDto interface.
 */
export function instanceOfNftDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "blockchain" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "minimumBalance" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "nftAddress" in value;
    isInstance = isInstance && "tokenId" in value;
    isInstance = isInstance && "mustMatchAttributes" in value;
    return isInstance;
}
export function NftDefinitionDtoFromJSON(json) {
    return NftDefinitionDtoFromJSONTyped(json, false);
}
export function NftDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blockchain': json['blockchain'],
        'createdDate': json['createdDate'],
        'id': json['id'],
        'link': json['link'],
        'minimumBalance': json['minimumBalance'],
        'name': json['name'],
        'nftAddress': json['nftAddress'],
        'tokenId': json['tokenId'],
        'mustMatchAttributes': json['mustMatchAttributes'],
    };
}
export function NftDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blockchain': value.blockchain,
        'createdDate': value.createdDate,
        'id': value.id,
        'link': value.link,
        'minimumBalance': value.minimumBalance,
        'name': value.name,
        'nftAddress': value.nftAddress,
        'tokenId': value.tokenId,
        'mustMatchAttributes': value.mustMatchAttributes,
    };
}
