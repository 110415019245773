import { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { VscSearch } from "react-icons/vsc";
import Popup from "reactjs-popup";

import { PremiumButton } from "@/common_components/upgrade/PremiumButton";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import ghost_icon from "@assets/ghost_icon.svg?url";
import ghost_team from "@assets/ghost_team.png";
import people from "@assets/people.svg?url";
import { SimpleButton } from "@common/buttons/SimpleButton";
import { ErrorMessage } from "@common/error/ErrorMessage";
import { AccessibleImage } from "@common/images/AccessibleImage";
import { ImageWithFallback } from "@common/images/NftImage";
import TextInput from "@common/inputs/TextInput";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@common/overlays/modals/ConfirmModal";
import { EditableProfileImage } from "@common/profile_image/EditableProfileImage";
import SizedBox from "@common/SizedBox";
import styled from "@emotion/styled";
import {
  AppColors,
  SendOrgAdminInviteRequest,
  UserOrgConnectionInfo,
} from "@juntochat/kazm-shared";

import { useProjectPlanProvider } from "@/providers/project_plan_provider";
import { useCurrentExtendedOrgInfo } from "@utils/hooks/use_current_org_info";

import {
  useGetExtendedOrgInfo,
  useGetOrgInfo,
  useGetUserOrgConnections,
  useGetUserOrgPendingConnections,
} from "@utils/hooks/use_cache";
import { useCurrentUser } from "@utils/hooks/use_current_user";
import { useNavigateToBilling } from "@utils/hooks/use_navigate_to_billing";
import { LayoutStyles, TextStyles } from "@utils/styles";
import { ToastUtils } from "@utils/toast_utils";
import KazmUtils from "../../utils/utils";
import { BodyLayout } from "../BodyLayout";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import {
  UserOrgConnectionDtoOrgRoleEnum,
  UserOrgConnectionInfoDto,
} from "@juntochat/internal-api";
import { InviteAdminsInput } from "./InviteAdminInput";

export function ProjectSettings() {
  const navigateToBilling = useNavigateToBilling();

  return (
    <BodyLayout>
      <ColumnContainer>
        <Column>
          <ProjectOrganizationEdit />
          <PremiumButton
            fullWidth
            className="!z-0 h-[44px]"
            onClick={() => {
              navigateToBilling();
            }}
          >
            Upgrade
          </PremiumButton>
        </Column>
        <Column>
          <ProjectTeam />
          <ProjectInviteTeam />
        </Column>
      </ColumnContainer>
      <SizedBox height={100} />
    </BodyLayout>
  );
}

const ColumnContainer = styled.div`
  display: flex;
  gap: 40px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

enum OrgProfileFormField {
  name,
  website,
}

export function ProjectOrganizationEdit() {
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const {
    data: orgData,
    mutate: refetchOrgInfo,
    error: orgInfoError,
  } = useGetExtendedOrgInfo(orgId);
  const { mutate: refetchOrgInfoOnly } = useGetOrgInfo(orgId);
  const [profilePicture, setProfilePicture] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [errors, setErrors] = useState<OrgProfileFormField[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const defaultValue = "";
  const { setShowPlanModal } = useProjectPlanProvider();

  const dataChanged =
    (profilePicture !== orgData?.info?.profilePicture &&
      profilePicture !== defaultValue) ||
    (name !== orgData?.info?.name && name !== defaultValue) ||
    website !== orgData?.info?.website;

  useEffect(() => {
    if (orgData) {
      setProfilePicture(orgData?.info?.profilePicture || defaultValue);
      setName(orgData?.info?.name || defaultValue);
      setWebsite(orgData?.info?.website || defaultValue);
    }
  }, [orgData]);

  async function handleSetOrgInfo() {
    setSaving(true);
    setErrors([]);
    const isValidName = name !== defaultValue;
    if (!isValidName) {
      setErrors([OrgProfileFormField.name]);
      setSaving(false);
      return;
    }

    try {
      await cloudFunctionsService.orgAdminApi.orgInfoControllerUpdate({
        orgId,
        updateOrgInfoDto: { profilePicture, name, website },
      });
      await refetchOrgInfo();

      // Reload anywhere that we retrieved the org info with the infoOnly flag.
      refetchOrgInfoOnly();
    } catch (e) {
      ToastUtils.showErrorToast("Error updating organization info");
      console.error(e);
    } finally {
      setSaving(false);
    }
  }

  if (orgInfoError) {
    return <ErrorMessage error={"Unable to load organization info"} />;
  } else if (!orgData) {
    return (
      <div
        className={LayoutStyles.center}
        style={{
          flex: 1,
          height: "200px",
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div>
        <PremiumButton
          borderRadius={10}
          intent="tertiary"
          className="w-[140px]"
          onClick={() => {
            setShowPlanModal(true);
          }}
        >
          <div className="flex items-center justify-center gap-[8px]">
            <AccessibleImage src={people} />
            <span className="first-letter:uppercase">
              {orgData.subscriptionInfo?.subscriptionType ?? "free"} plan
            </span>
          </div>
        </PremiumButton>
        <SizedBox height={20} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <EditableProfileImage
            editable
            setImageUrl={setProfilePicture}
            imageSource={profilePicture}
            width={80}
            height={80}
            name={name}
          />
          <SizedBox width={10} />
          <TextInput
            style={{ flex: 1 }}
            label="Name"
            onChangeText={setName}
            defaultValue={name}
            error={errors.includes(OrgProfileFormField.name)}
          />
        </div>
        <SizedBox height={10} />
        <TextInput
          label="Website"
          onChangeText={setWebsite}
          defaultValue={website}
          error={errors.includes(OrgProfileFormField.website)}
        />
        {dataChanged && (
          <>
            <SizedBox height={20} />
            <SimpleButton
              className={LayoutStyles.center}
              onClick={handleSetOrgInfo}
              style={{
                backgroundColor: AppColors.coolPurple200,
                color: AppColors.darkBase,
                width: "100%",
                padding: "7px",
                fontWeight: 700,
              }}
            >
              {saving ? (
                <LoadingSpinner size={20} color={AppColors.white} />
              ) : (
                "Save"
              )}
            </SimpleButton>
          </>
        )}
      </div>
    </>
  );
}

export function ProjectTeam() {
  const orgId = useCurrentOrgId();
  const [searchVal, setSearchVal] = useState("");
  const currentUser = useCurrentUser();
  const { data: teamData, error: teamError } = useGetUserOrgConnections(orgId);
  const {
    data: userOrgPendingConnectionsData,
    error: userOrgPendingConnectionsError,
  } = useGetUserOrgPendingConnections(orgId);

  if (userOrgPendingConnectionsError) {
    console.error(
      "Failed loading user org pending connections: ",
      userOrgPendingConnectionsError,
    );
  }

  const isSignedInUserOwner =
    teamData?.userOrgConnections.filter(
      (member) => member.userInfo?.userId === currentUser?.uid,
    )[0]?.user?.orgRole === UserOrgConnectionDtoOrgRoleEnum.Owner;

  const userOrgConnections = teamData?.userOrgConnections ?? [];
  const userOrgPendingConnections =
    userOrgPendingConnectionsData?.userOrgConnections ?? [];
  const orgTeamData = [...userOrgConnections, ...userOrgPendingConnections];

  // TODO(community-settings): Stop getting all user org connections for searching on settings page.
  const filteredTeam = orgTeamData.filter((item) => {
    const { user, userInfo } = item;
    return (
      (!searchVal ||
        userInfo?.email?.toLowerCase().includes(searchVal.toLowerCase())) &&
      (user?.orgRole === UserOrgConnectionDtoOrgRoleEnum.Admin ||
        user?.orgRole === UserOrgConnectionDtoOrgRoleEnum.Owner ||
        user?.orgRole === UserOrgConnectionDtoOrgRoleEnum.Pending)
    );
  });

  if (teamError) {
    return <ErrorMessage error="Error loading user connections" />;
  } else if (!teamData) {
    return (
      <div className="flex h-[200px] flex-1 items-center justify-center">
        <LoadingSpinner size={40} />
      </div>
    );
  }

  return (
    <div>
      <h2 className="headline-sm mb-0 text-left">Administrators</h2>
      <SizedBox height={20} />
      <SearchInput>
        <VscSearch id="search" />
        <input
          onChange={(e) => {
            setSearchVal(e.target.value);
          }}
          value={searchVal}
          placeholder="Search admins"
        />
      </SearchInput>
      <SizedBox height={10} />
      <div className="w-full">
        {filteredTeam?.length ? (
          filteredTeam?.map((user, index) => (
            <UserOrgConnectionDisplay
              key={index}
              isSignedInUserOwner={isSignedInUserOwner}
              userConnection={user}
            />
          ))
        ) : (
          <div className="w-full">
            <SizedBox height={20} />
            <AccessibleImage
              className="mx-auto rounded-[50%]"
              src={ghost_team}
              width={100}
              height={100}
            />
            <h3 className="headline-md text-center">No results</h3>
            <p className="mx-auto max-w-[260px] text-center text-gray-300">
              We couldn’t find any admins named “{searchVal}”
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

const SearchInput = styled.div`
  background-color: #474752;
  padding: 5px 45px;
  border-radius: 40px;
  position: relative;
  text-align: start;

  input {
    font-size: 16px;
    color: ${AppColors.gray100};
    border: none;
    outline: none;
    background-color: transparent;
  }

  #search {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
  }
`;

function UserOrgConnectionDisplay({
  isSignedInUserOwner,
  userConnection,
}: {
  isSignedInUserOwner: boolean;
  userConnection: UserOrgConnectionInfoDto | UserOrgConnectionInfo;
}) {
  const userId = userConnection?.userInfo?.userId;
  const formattedId = userId
    ? KazmUtils.shortenWalletAddress(userId)
    : "Unknown";

  return (
    <div
      className="flex w-full flex-1 items-center justify-between p-[10px] odd:bg-dark-base-lighter even:bg-dark-base"
      key={userId}
    >
      <div className="flex items-center">
        <ImageWithFallback
          className="rounded-[50%] object-cover"
          src={userConnection.userInfo?.profilePicture}
          fallback={ghost_icon}
          width={30}
          height={30}
          alt={`${userConnection?.userInfo?.name}'s profile picture`}
        />
        <SizedBox width={10} />
        <span className={TextStyles.sHeadline}>
          {userConnection?.userInfo?.email ||
            userConnection?.userInfo?.name ||
            formattedId}
        </span>
      </div>
      <div className="flex items-center gap-[10px]">
        <div className="text-[14px] text-gray-200">
          {getUserRole(userConnection)}
        </div>
        <AdminOptions
          userConnection={userConnection}
          isSignedInUserOwner={isSignedInUserOwner}
        />
      </div>
    </div>
  );
}

export function getUserRole(
  userConnection: UserOrgConnectionInfoDto | UserOrgConnectionInfo,
) {
  const { user } = userConnection;
  if (user?.orgRole === UserOrgConnectionDtoOrgRoleEnum.Owner) {
    return "Owner";
  } else if (user?.orgRole === UserOrgConnectionDtoOrgRoleEnum.Admin) {
    return "Admin";
  } else if (user?.orgRole === UserOrgConnectionDtoOrgRoleEnum.Pending) {
    return "Pending";
  }
}

interface AdminOptionsProps {
  userConnection: UserOrgConnectionInfoDto | UserOrgConnectionInfo;
  isSignedInUserOwner: boolean;
}

function AdminOptions({
  userConnection,
  isSignedInUserOwner,
}: AdminOptionsProps) {
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const inviteId = userConnection?.inviteId;
  const currentUser = useCurrentUser();
  const userId = userConnection?.userInfo?.userId;
  const { mutate: reloadTeamData } = useGetUserOrgConnections(orgId);
  const { mutate: reloadUserOrgPendingConnections } =
    useGetUserOrgPendingConnections(orgId);

  const { mutate: reloadOrgInfo } = useCurrentExtendedOrgInfo();
  const { controller, showConfirmModal } =
    useConfirmModalController<ConfirmDeletionModalProps>();

  async function handleResendInvite() {
    if (!userConnection.userInfo?.email) {
      ToastUtils.showErrorToast("No email provided!");
      return;
    }

    try {
      await cloudFunctionsService.sendOrgAdminInvite(
        SendOrgAdminInviteRequest.fromPartial({
          orgId,
          email: userConnection.userInfo?.email,
        }),
      );
      ToastUtils.showSuccessToast("Invite sent");
      await reloadUserOrgPendingConnections();
    } catch (error) {
      console.error(error);
      ToastUtils.showErrorToast("Failed resending invite!");
    }
  }

  async function handleDeleteAdmin() {
    if (!userId) {
      ToastUtils.showErrorToast("No user id found.");
      return;
    }

    try {
      await cloudFunctionsService.orgAdminApi.userOrgConnectionsControllerDelete(
        {
          orgId,
          userId,
        },
      );
      ToastUtils.showSuccessToast("Succesfully removed admin.");
    } catch (error) {
      console.error(error);
      ToastUtils.showErrorToast("Failed removing admin.");
    }

    await Promise.all([reloadTeamData(), reloadOrgInfo()]);
  }

  async function handleRemoveAdminInvite() {
    if (!inviteId) {
      ToastUtils.showErrorToast("No invite id found.");
      return;
    }

    try {
      await cloudFunctionsService.deleteOrgAdminInvite({
        inviteId: inviteId,
      });
      ToastUtils.showSuccessToast("Successfully removed admin invite.");
    } catch (error) {
      console.error(error);
      ToastUtils.showErrorToast("Failed removing admin invite.");
    }

    await Promise.all([reloadUserOrgPendingConnections(), reloadOrgInfo()]);
  }

  const isKazmAdmin =
    currentUser?.email?.endsWith("@kazm.com") && currentUser?.emailVerified;
  const ownAccount = currentUser?.uid === userId;
  // TODO(user-id): show user connected address if user has ethereum address.
  const isOwner =
    userConnection?.user?.orgRole === UserOrgConnectionDtoOrgRoleEnum.Owner;
  const isPending =
    userConnection?.user?.orgRole === UserOrgConnectionDtoOrgRoleEnum.Pending;

  const showAdminOptions =
    ((isSignedInUserOwner || isPending) && !isOwner && !ownAccount) ||
    (isKazmAdmin && !isOwner);

  if (!showAdminOptions) return <></>;

  return (
    <>
      <Popup
        trigger={
          <div className="cursor-pointer p-[5px]">
            <BsThreeDots size={30} />
          </div>
        }
        position="bottom right"
        on="hover"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: "0px", border: "none" }}
        arrow={false}
      >
        <div className="flex w-[111px] flex-col justify-center rounded-[4px] bg-dark-base-lighter p-[10px] text-[14px] text-gray-300 drop-shadow-[0_4px_24px_rgba(0,0,0,0.25)]">
          {isPending && (
            <button
              className="flex h-[40px] cursor-pointer items-center p-[5px] outline-none"
              onClick={handleResendInvite}
            >
              Resend Link
            </button>
          )}
          <button
            className="flex h-[40px] cursor-pointer items-center gap-[8px] p-[5px] text-red-200 outline-none"
            onClick={() =>
              showConfirmModal(
                isPending ? handleRemoveAdminInvite : handleDeleteAdmin,
                {
                  title: "Delete admin?",
                  description: `This will delete ${userConnection?.userInfo?.email} from the admin list.`,
                },
              )
            }
          >
            Remove <IoClose size={25} />
          </button>
        </div>
      </Popup>
      <ConfirmDeletionModal controller={controller} />
    </>
  );
}

function ProjectInviteTeam() {
  return (
    <div className="flex items-center justify-center rounded-[10px] bg-black p-[20px]">
      <AccessibleImage style={{ height: 100 }} src={ghost_team} />
      <SizedBox width={30} />
      <div className="w-full">
        <h3 className="headline-sm mb-0 text-start">Invite admins by email:</h3>
        <SizedBox height={8} />
        <InviteAdminsInput />
      </div>
    </div>
  );
}
