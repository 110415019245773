import {
  MembershipPage,
  useMembershipPage,
} from "@/membership_form/hooks/use_membership_page";
import { useValidatedTiers } from "@/membership_form/hooks/use_validated_tiers";
import { useConfetti } from "@/membership_form/providers/confetti_provider.tsx";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useActionOutcomesProvider } from "@/modules/actions";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import {
  useListMyActivationClaims,
  useRefreshActivationClaims,
} from "@/modules/activations/api.ts";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import {
  ActivationClaimDto,
  ActivationDto,
  ActivationVerificationMethod,
  ListActivationClaimsResponseDto,
  CreateActivationClaimFailedValidationResponseDtoFromJSON,
} from "@juntochat/internal-api";
import {
  GetMembershipLeaderboardMembersRequest,
  MemberActionOutcome,
  MemberActionType,
} from "@juntochat/kazm-shared";
import { useGetMembershipLeaderboardMembers } from "@utils/hooks/use_cache";
import { useMemberPoints } from "@utils/hooks/use_member_points";
import { ToastUtils } from "@utils/toast_utils.tsx";
import { useState } from "react";
import KazmUtils from "@utils/utils.ts";

export type ClaimActivationController = {
  activation: ActivationDto;
  claim: ActivationClaimDto | undefined;
  // Should be used in rare cases where claim happens outside the claim modal UI,
  // and we just need to sync the state.
  setClaim: (claim: ActivationClaimDto) => void;
  // Throws an error if claim can't be created due to validation errors.
  submitClaim: () => Promise<ActivationClaimDto>;
  checkForUnseenClaims: () => Promise<void>;
};

export function useClaimActivationController(props: {
  activation: ActivationDto;
  claim?: ActivationClaimDto;
}): ClaimActivationController {
  const { activation } = props;
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { showConfetti } = useConfetti();
  const cloudFunctionsService = useCloudFunctionsService();
  const {
    definitions,
    existingOutcomes,
    setOutcomeErrors,
    updateAndVerifyOutcomes,
  } = useActionOutcomesProvider();
  const [claim, setClaim] = useState<ActivationClaimDto | undefined>(
    props.claim,
  );
  const currentMemberClaims = useListMyActivationClaims({
    orgId: activation.orgId,
    membershipId: activation.membershipId,
  });
  const refreshClaimData = useRefreshActivationClaimAffectedData();

  async function submitClaim(): Promise<ActivationClaimDto> {
    if (!loyaltyForm) {
      throw new Error("Membership undefined");
    }

    // If user doesn't hit refresh button manually,
    // no error message will be displayed for these action types.
    const actionTypesWithoutUserInputs = new Set([
      MemberActionType.TWITTER_REACT,
    ]);
    const actionsToRevalidate = definitions.find((outcome) =>
      actionTypesWithoutUserInputs.has(outcome.type),
    );

    if (actionsToRevalidate) {
      await updateAndVerifyOutcomes({
        debounceValidation: false,
        outcomes: [
          MemberActionOutcome.fromPartial({
            definitionId: actionsToRevalidate.id,
            type: actionsToRevalidate.type,
          }),
        ],
        optimistic: false,
      });
    }

    try {
      const claim =
        await cloudFunctionsService.activationsApi.activationClaimsControllerCreate(
          {
            orgId: loyaltyForm.orgId,
            activationId: activation.activationId,
            membershipId: loyaltyForm.id,
            createActivationClaimRequestDto: {
              outcomes: existingOutcomes.map((protoOutcome) =>
                DtoMigrationUtils.actionOutcomeFromProto(protoOutcome),
              ),
            },
          },
        );

      // If claim is created with validation errors,
      // those are sensitive errors that shouldn't be returned during validation step.
      const claimRequirementErrors = claim.actionValidationResults.flatMap(
        (e) => e.errors,
      );

      setOutcomeErrors(
        claimRequirementErrors.map((error) =>
          DtoMigrationUtils.actionErrorToProto(error),
        ),
      );

      handleUnseenClaim(claim);

      return claim;
    } catch (error) {
      if (KazmUtils.isApiResponseError(error)) {
        const response =
          CreateActivationClaimFailedValidationResponseDtoFromJSON(
            await error.response.json(),
          );
        setOutcomeErrors(
          response.actionValidationErrors?.map((error) =>
            DtoMigrationUtils.actionErrorToProto(error),
          ) ?? [],
        );
      }
      throw error;
    }
  }

  async function checkForUnseenClaims() {
    // Claims must be initially loaded,
    // as we must know the previous claims to calculate diff with the new claims.
    if (!currentMemberClaims.data) {
      throw new Error("Claims not loaded");
    }
    const claimsBeforeRefresh = filterToCurrentActivationClaims(
      currentMemberClaims.data.data,
    );
    const claimIdsBeforeRefresh = new Set(
      claimsBeforeRefresh.map((claim) => claim.id),
    );

    const refreshedData =
      await currentMemberClaims.mutate<ListActivationClaimsResponseDto>();

    if (!refreshedData) {
      throw new Error("No refreshed claim data");
    }

    const claimsAfterRefresh = filterToCurrentActivationClaims(
      refreshedData.data,
    );
    const unseenClaim = claimsAfterRefresh.find(
      (claim) => !claimIdsBeforeRefresh.has(claim.id),
    );

    if (unseenClaim) {
      handleUnseenClaim(unseenClaim);
    } else {
      ToastUtils.showInfoToast(
        "Nothing yet, maybe try again in a few seconds?",
      );
    }
  }

  function handleUnseenClaim(claim: ActivationClaimDto) {
    if (ActivationUtils.isFailedClaim(claim)) {
      ToastUtils.showErrorToast(
        "Incorrect answer, no points gained. Try again.",
      );
      return;
    }

    setClaim(claim);
    showConfetti();

    if (activation.points && activation.points > 0) {
      const requiresManualVerification =
        activation.verificationMethod === ActivationVerificationMethod.Manual;
      ToastUtils.showPointsToast({
        points: activation.points,
        isPending: requiresManualVerification,
      });
    }

    refreshClaimData();
  }

  function filterToCurrentActivationClaims(claims: ActivationClaimDto[]) {
    return claims.filter(
      (claim) => claim.activationId === activation.activationId,
    );
  }

  return { activation, setClaim, claim, submitClaim, checkForUnseenClaims };
}

export function useRefreshActivationClaimAffectedData() {
  const refreshClaimData = useRefreshActivationClaims();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { currentPage } = useMembershipPage();
  const isLeaderboardPage = currentPage === MembershipPage.LEADERBOARD;
  const { mutate: revalidateTiers } = useValidatedTiers(loyaltyForm);
  const { mutate: refetchLeaderboard } = useGetMembershipLeaderboardMembers(
    GetMembershipLeaderboardMembersRequest.fromPartial({
      membershipId: loyaltyForm.id,
      orgId: loyaltyForm.orgId,
    }),
    { shouldFetch: isLeaderboardPage },
  );
  const { refreshPoints } = useMemberPoints();

  function refresh() {
    // No need to await, since the updates happen in the background.
    revalidateTiers();
    refreshPoints();
    refreshClaimData();

    if (isLeaderboardPage) {
      refetchLeaderboard();
    }
  }

  return refresh;
}
