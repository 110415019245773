/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { GetWeb3AptosSignInMessageResponseDtoFromJSON, GetWeb3SignInMessageRequestDtoToJSON, GetWeb3SignInMessageResponseDtoFromJSON, GetWeb3SolSignInMessageRequestDtoToJSON, GetWeb3SolSignInMessageResponseDtoFromJSON, LoginWithEmailOtpRequestDtoToJSON, LoginWithEmailOtpResponseDtoFromJSON, LoginWithSMSOtpRequestDtoToJSON, LoginWithSMSOtpResponseDtoFromJSON, SignInWithSignedWeb3MessageRequestDtoToJSON, SignInWithSignedWeb3MessageResponseDtoFromJSON, } from '../models/index.js';
/**
 *
 */
export class LoginApi extends runtime.BaseAPI {
    /**
     * Retrieve a sign in message to initiate sign in with web3 for Aptos
     */
    loginControllerGetAptosSignInMessageRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/login/web3/aptos/sign-in-message`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetWeb3AptosSignInMessageResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Retrieve a sign in message to initiate sign in with web3 for Aptos
     */
    loginControllerGetAptosSignInMessage(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loginControllerGetAptosSignInMessageRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Retrieve a sign in message to initiate sign in with web3 for Ethereum
     */
    loginControllerGetEthSignInMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.getWeb3SignInMessageRequestDto === null || requestParameters.getWeb3SignInMessageRequestDto === undefined) {
                throw new runtime.RequiredError('getWeb3SignInMessageRequestDto', 'Required parameter requestParameters.getWeb3SignInMessageRequestDto was null or undefined when calling loginControllerGetEthSignInMessage.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/v2/login/web3/eth/sign-in-message`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: GetWeb3SignInMessageRequestDtoToJSON(requestParameters.getWeb3SignInMessageRequestDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetWeb3SignInMessageResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Retrieve a sign in message to initiate sign in with web3 for Ethereum
     */
    loginControllerGetEthSignInMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loginControllerGetEthSignInMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Retrieve a sign in message to initiate sign in with web3 for Solana
     */
    loginControllerGetSolSignInMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.getWeb3SolSignInMessageRequestDto === null || requestParameters.getWeb3SolSignInMessageRequestDto === undefined) {
                throw new runtime.RequiredError('getWeb3SolSignInMessageRequestDto', 'Required parameter requestParameters.getWeb3SolSignInMessageRequestDto was null or undefined when calling loginControllerGetSolSignInMessage.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/v2/login/web3/sol/sign-in-message`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: GetWeb3SolSignInMessageRequestDtoToJSON(requestParameters.getWeb3SolSignInMessageRequestDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetWeb3SolSignInMessageResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Retrieve a sign in message to initiate sign in with web3 for Solana
     */
    loginControllerGetSolSignInMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loginControllerGetSolSignInMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    loginControllerLoadTestOtpRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.email === null || requestParameters.email === undefined) {
                throw new runtime.RequiredError('email', 'Required parameter requestParameters.email was null or undefined when calling loginControllerLoadTestOtp.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/login/load-test-otp/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     */
    loginControllerLoadTestOtp(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loginControllerLoadTestOtpRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Logs in using an email OTP
     * Login with email OTP
     */
    loginControllerLoginWithEmailOtpRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.loginWithEmailOtpRequestDto === null || requestParameters.loginWithEmailOtpRequestDto === undefined) {
                throw new runtime.RequiredError('loginWithEmailOtpRequestDto', 'Required parameter requestParameters.loginWithEmailOtpRequestDto was null or undefined when calling loginControllerLoginWithEmailOtp.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/v2/login/email-otp`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: LoginWithEmailOtpRequestDtoToJSON(requestParameters.loginWithEmailOtpRequestDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LoginWithEmailOtpResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Logs in using an email OTP
     * Login with email OTP
     */
    loginControllerLoginWithEmailOtp(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loginControllerLoginWithEmailOtpRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Logs in using an SMS OTP
     * Login with SMS OTP
     */
    loginControllerLoginWithSMSOtpRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.loginWithSMSOtpRequestDto === null || requestParameters.loginWithSMSOtpRequestDto === undefined) {
                throw new runtime.RequiredError('loginWithSMSOtpRequestDto', 'Required parameter requestParameters.loginWithSMSOtpRequestDto was null or undefined when calling loginControllerLoginWithSMSOtp.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/v2/login/sms-otp`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: LoginWithSMSOtpRequestDtoToJSON(requestParameters.loginWithSMSOtpRequestDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LoginWithSMSOtpResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Logs in using an SMS OTP
     * Login with SMS OTP
     */
    loginControllerLoginWithSMSOtp(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loginControllerLoginWithSMSOtpRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Sign in using a signed SIWE message
     */
    loginControllerSignInWithEthSignInMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.signInWithSignedWeb3MessageRequestDto === null || requestParameters.signInWithSignedWeb3MessageRequestDto === undefined) {
                throw new runtime.RequiredError('signInWithSignedWeb3MessageRequestDto', 'Required parameter requestParameters.signInWithSignedWeb3MessageRequestDto was null or undefined when calling loginControllerSignInWithEthSignInMessage.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/v2/login/web3/eth/sign-in`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SignInWithSignedWeb3MessageRequestDtoToJSON(requestParameters.signInWithSignedWeb3MessageRequestDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SignInWithSignedWeb3MessageResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Sign in using a signed SIWE message
     */
    loginControllerSignInWithEthSignInMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loginControllerSignInWithEthSignInMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    loginControllerThrottleTestRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/login/throttle-test`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    loginControllerThrottleTest(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loginControllerThrottleTestRaw(initOverrides);
        });
    }
}
