import {
  WalletContextState,
  useWallet,
} from "@aptos-labs/wallet-adapter-react";
import { useEffect, useState } from "react";
import { ConnectWalletStatus } from "./use_connect_evm_wallet";
import { Signature } from "@aptos-labs/ts-sdk";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";

export type ConnectAptosWalletController = {
  account: WalletContextState;
  address: string | undefined;
  error: string | undefined;
  status: ConnectWalletStatus;
  connectWallet: () => Promise<void>;
  disconnectWallet: () => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getSignature: () => Promise<WalletSignatureResult | undefined>;
  publicKey: string | undefined;
};

type WalletSignatureResult = {
  message: string;
  signature: string;
};

type ConnectAptosWalletOptions = {
  onCloseOrError?: () => void;
};

/**
 * Hook that should be used for all Aptos wallet connection attempts.
 *
 * This hook acts as a wrapper to the 3rd party lib hooks, which:
 * - properly handles errors
 * - exposes connection state (that can be used for improved UX)
 * - exposes wallet signature utility
 */
export function useConnectAptosWallet(
  options?: ConnectAptosWalletOptions,
): ConnectAptosWalletController {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { onCloseOrError } = options ?? {};
  const wallet = useWallet();
  const { connected, account, disconnect, signMessage, isLoading } = wallet;
  const [status, setStatus] = useState(ConnectWalletStatus.INITIAL);
  const [errorMessage, setErrorMessage] = useState<string>();
  const cloudFunctionsService = useCloudFunctionsService();

  const getSignatureOrThrow =
    async function (): Promise<WalletSignatureResult> {
      setStatus(ConnectWalletStatus.REQUESTING_SIGNATURE);

      try {
        const { message, nonce } =
          await cloudFunctionsService.loginApi.loginControllerGetAptosSignInMessage();

        const response = await signMessage({
          nonce,
          message,
          chainId: true,
          address: true,
        });

        const fullMessage = response.fullMessage;
        const signature = (response.signature as Signature).toString();
        setStatus(ConnectWalletStatus.SIGNATURE_PROVIDED);

        return { message: fullMessage, signature };
      } catch (e: any) {
        setStatus(ConnectWalletStatus.ERROR);
        setErrorMessage("Failed to get signature");
        onCloseOrError?.();

        // Must pass error back to the caller
        throw e;
      }
    };

  async function getSignature(): Promise<WalletSignatureResult | undefined> {
    try {
      return await getSignatureOrThrow();
    } catch (e) {
      // Don't propagate error to external callers.
      onCloseOrError?.();

      return;
    }
  }

  async function disconnectWallet() {
    await new Promise((resolve) => {
      disconnect();
      resolve(undefined);
    });
    setStatus(ConnectWalletStatus.INITIAL);
  }

  async function connectWallet() {
    // Disconnect the current wallet,
    // so that user can choose a different account
    // as it was previously connected.
    // Both sign-in and account connection flow rely on this.
    if (connected) {
      await disconnectWallet();
    }

    try {
      await new Promise((resolve) => {
        setIsModalOpen(true);
        resolve(undefined);
      });
      setStatus(ConnectWalletStatus.CONNECT_MODAL_OPEN);
    } catch (error) {
      onCloseOrError?.();
      setStatus(ConnectWalletStatus.ERROR);
    }
  }

  useEffect(() => {
    if (
      !isModalOpen &&
      status === ConnectWalletStatus.CONNECT_MODAL_OPEN &&
      !isLoading
    ) {
      setStatus(ConnectWalletStatus.INITIAL);
    }
  }, [isModalOpen, status, isLoading]);

  useEffect(() => {
    if (connected && status === ConnectWalletStatus.CONNECT_MODAL_OPEN) {
      setStatus(ConnectWalletStatus.ACCOUNT_CONNECTED);
    }
  }, [connected, status]);

  useEffect(() => {
    if (!isModalOpen) {
      onCloseOrError?.();
    }
  }, [isModalOpen, status]);

  useEffect(() => {
    if (errorMessage) {
      disconnectWallet();
    }
  }, [errorMessage]);

  return {
    disconnectWallet,
    connectWallet,
    account: wallet,
    address: account?.address,
    status,
    isModalOpen,
    setIsModalOpen,
    error: errorMessage,
    getSignature,
    publicKey: account?.publicKey as string | undefined,
  };
}
