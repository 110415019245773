import { DataSourceType, LegacyContractType } from "@juntochat/kazm-shared";
import { useCurrentOrgDataSources } from "./use_cache";

export function useCurrentOrgContractSources() {
  const { data, error, mutate } = useCurrentOrgDataSources();

  // Ethereum contracts
  const ethereumContracts =
    data?.dataSources?.filter(
      (ds) => ds.sourceType === DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT,
    ) ?? [];

  const erc20EthereumContracts =
    data?.dataSources?.filter(
      (ds) => ds.ethSource?.contractType === LegacyContractType.ERC20_CONTRACT,
    ) ?? [];

  const erc721EthereumContracts =
    ethereumContracts?.filter(
      (ds) => ds.ethSource?.contractType === LegacyContractType.ERC721_CONTRACT,
    ) ?? [];

  const erc1155EthereumContracts =
    ethereumContracts?.filter(
      (ds) =>
        ds.ethSource?.contractType === LegacyContractType.ERC1155_CONTRACT,
    ) ?? [];

  const otherEthereumContracts = data?.dataSources?.filter(
    (ds) =>
      ds.ethSource?.contractType !== LegacyContractType.ERC721_CONTRACT &&
      ds.ethSource?.contractType !== LegacyContractType.ERC20_CONTRACT,
  );

  // Polygon contracts
  const polygonContracts =
    data?.dataSources?.filter(
      (ds) =>
        ds.sourceType === DataSourceType.DATA_SOURCE_TYPE_POLYGON_CONTRACT,
    ) ?? [];

  const erc20PolygonContracts = polygonContracts?.filter(
    (ds) =>
      ds.polygonSource?.contractType === LegacyContractType.ERC20_CONTRACT,
  );

  const erc721PolygonContracts = polygonContracts?.filter(
    (ds) =>
      ds.polygonSource?.contractType === LegacyContractType.ERC721_CONTRACT,
  );

  const erc1155PolygonContracts = polygonContracts?.filter(
    (ds) =>
      ds.polygonSource?.contractType === LegacyContractType.ERC1155_CONTRACT,
  );

  // Immutable X contracts
  const imxContracts =
    data?.dataSources?.filter(
      (ds) =>
        ds.sourceType === DataSourceType.DATA_SOURCE_TYPE_IMMUTABLE_X_CONTRACT,
    ) ?? [];

  const erc20ImxContracts =
    data?.dataSources?.filter(
      (ds) =>
        ds.immutableXSource?.contractType === LegacyContractType.ERC20_CONTRACT,
    ) ?? [];

  const erc721ImxContracts = imxContracts?.filter(
    (ds) =>
      ds.immutableXSource?.contractType === LegacyContractType.ERC721_CONTRACT,
  );

  // Base contracts
  const baseContracts =
    data?.dataSources?.filter(
      (ds) => ds.sourceType === DataSourceType.DATA_SOURCE_TYPE_BASE_CONTRACT,
    ) ?? [];

  const erc20BaseContracts =
    baseContracts?.filter(
      (ds) => ds.baseSource?.contractType === LegacyContractType.ERC20_CONTRACT,
    ) ?? [];

  const erc721BaseContracts =
    baseContracts?.filter(
      (ds) =>
        ds.baseSource?.contractType === LegacyContractType.ERC721_CONTRACT,
    ) ?? [];

  const erc1155BaseContracts =
    baseContracts?.filter(
      (ds) =>
        ds.baseSource?.contractType === LegacyContractType.ERC1155_CONTRACT,
    ) ?? [];

  // Avax contracts
  const avaxContracts =
    data?.dataSources?.filter(
      (ds) => ds.sourceType === DataSourceType.DATA_SOURCE_TYPE_AVAX_CONTRACT,
    ) ?? [];

  const erc20AvaxContracts =
    avaxContracts?.filter(
      (ds) => ds.avaxSource?.contractType === LegacyContractType.ERC20_CONTRACT,
    ) ?? [];

  const erc721AvaxContracts =
    avaxContracts?.filter(
      (ds) =>
        ds.avaxSource?.contractType === LegacyContractType.ERC721_CONTRACT,
    ) ?? [];

  const erc1155AvaxContracts =
    avaxContracts?.filter(
      (ds) =>
        ds.avaxSource?.contractType === LegacyContractType.ERC1155_CONTRACT,
    ) ?? [];

  // Solana contracts
  const solanaContracts =
    data?.dataSources?.filter(
      (ds) => ds.sourceType === DataSourceType.DATA_SOURCE_TYPE_SOLANA_CONTRACT,
    ) ?? [];

  // Aptos contracts
  const aptosContracts =
    data?.dataSources?.filter(
      (ds) => ds.sourceType === DataSourceType.DATA_SOURCE_TYPE_APTOS_CONTRACT,
    ) ?? [];

  const aptosCoinContracts =
    aptosContracts?.filter(
      (ds) =>
        ds.aptosSource?.contractType === LegacyContractType.APTOS_COIN_CONTRACT,
    ) ?? [];

  const aptosTokenContracts =
    aptosContracts?.filter(
      (ds) =>
        ds.aptosSource?.contractType ===
        LegacyContractType.APTOS_TOKEN_CONTRACT,
    ) ?? [];

  const isLoading = data === undefined;

  return {
    data,
    error,
    allErc1155ContractSources: [
      ...erc1155EthereumContracts,
      ...erc1155PolygonContracts,
      ...erc1155BaseContracts,
      ...erc1155AvaxContracts,
    ],
    allErc721ContractSources: [
      ...erc721EthereumContracts,
      ...erc721PolygonContracts,
      ...erc721ImxContracts,
      ...erc721BaseContracts,
      ...erc721AvaxContracts,
    ],
    allErc20ContractSources: [
      ...erc20EthereumContracts,
      ...erc20PolygonContracts,
      ...erc20ImxContracts,
      ...erc20BaseContracts,
      ...erc20AvaxContracts,
    ],
    otherEthereumContracts,
    ethereumContracts,
    solanaContracts,
    polygonContracts,
    imxContracts,
    erc721ImxContracts,
    erc721PolygonContracts,
    erc20PolygonContracts,
    baseContracts,
    erc20BaseContracts,
    erc721BaseContracts,
    aptosContracts,
    aptosCoinContracts,
    aptosTokenContracts,
    isLoading,
    reloadContracts: mutate,
  };
}
