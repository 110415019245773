import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";
import { ConnectedAccountActionButton } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/common/ConnectedAccountActionButton";
import { ConnectWalletStatus } from "@/utils/hooks/use_connect_evm_wallet";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import { useConnectedAccountBuilderController } from "../ConnectedAccountBuilderController";
import { ConnectedAccountContainer } from "./common/ConnectedAccountContainer";
import {
  ConnectAptosWalletController,
  useConnectAptosWallet,
} from "@/utils/hooks/use_connect_aptos_wallet";
import { WalletSelector } from "@aptos-labs/wallet-adapter-ant-design";
import { useEffect } from "react";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { useIsMobile } from "@/utils/hooks/use_is_mobile";

export function AptosAccountBuilder() {
  const isMobile = useIsMobile();
  const { createOrUpdate } = useConnectedAccountBuilderController();
  const connectWalletController = useConnectAptosWallet();
  const {
    status,
    address,
    isModalOpen,
    setIsModalOpen,
    getSignature,
    publicKey,
  } = connectWalletController;

  async function createAccountWithSignature(
    address: string,
    publicKey: string,
  ) {
    const result = await getSignature();

    if (result) {
      return createOrUpdate({
        id: address,
        accountType: MemberConnectedAccountType.AptosWallet,
        data: {
          aptos: {
            publicKey,
            message: result.message,
            signature: result.signature,
          },
        },
      });
    }
  }

  useEffect(() => {
    if (
      // Ensure we request signature every time we are in this state,
      // to handle cases where signature fails and user clicks "retry".
      status === ConnectWalletStatus.ACCOUNT_CONNECTED &&
      address &&
      publicKey
    ) {
      createAccountWithSignature(address, publicKey);
    }
  }, [address, status]);

  return (
    <>
      <div className="space-y-[10px]">
        <ConnectedAccountContainer>
          <ConnectWalletButton
            isDisabled={isMobile}
            connectWalletController={connectWalletController}
          />
        </ConnectedAccountContainer>
      </div>
      <WalletSelector isModalOpen={isModalOpen} setModalOpen={setIsModalOpen} />
    </>
  );
}

function ConnectWalletButton(props: {
  isDisabled: boolean;
  connectWalletController: ConnectAptosWalletController;
}) {
  const { status, connectWallet } = props.connectWalletController;

  switch (status) {
    case ConnectWalletStatus.REQUESTING_SIGNATURE:
    case ConnectWalletStatus.CONNECT_MODAL_OPEN:
      return (
        <Tooltip tooltipContent="Waiting for approval">
          <LoadingSpinner size={20} />
        </Tooltip>
      );
    case ConnectWalletStatus.ERROR:
      return (
        <ConnectedAccountActionButton
          disabled={props.isDisabled}
          onClick={() => connectWallet()}
        >
          Retry
        </ConnectedAccountActionButton>
      );
    case ConnectWalletStatus.INITIAL:
      return (
        <ConnectedAccountActionButton
          disabled={props.isDisabled}
          onClick={() => connectWallet()}
        >
          Connect
        </ConnectedAccountActionButton>
      );
    case ConnectWalletStatus.SIGNATURE_PROVIDED:
    default:
      return null;
  }
}
