import { ActionTypeVisitor, MemberActionType } from "@juntochat/kazm-shared";

export type ActionTypeLabelBuilderOptions = {
  // Should the label include platform context, e.g. "Connect Discord" vs "Connect".
  // Sometimes we already display the platform name (aka. context) separately,
  // and we don't want to repeat that in the action names as well.
  withPlatformContext?: boolean;
};

/**
 * Provides a short answer to: "What should the user do to complete this action?".
 * Builds action labels solely based on their type.
 *
 * When adding new labels, just ask yourself the above question.
 * Few examples:
 * - user should "Connect wallet"
 * - user should "Follow us on Twitter"
 * - ...
 */
export class ActionTypeLabelBuilder extends ActionTypeVisitor<
  ActionTypeLabelBuilderOptions,
  string
> {
  public getLabel(
    actionType: MemberActionType,
    options: ActionTypeLabelBuilderOptions,
  ) {
    return this.visit(actionType, {
      ...options,
      withPlatformContext: options.withPlatformContext ?? true,
    });
  }

  protected ethereumConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect Ethereum Wallet";
    } else {
      return "Connect";
    }
  }

  protected ethereumOwnToken(): string {
    return "Hold (any of) tokens";
  }

  protected ethereumMinimumBalance(): string {
    return "Minimum balance";
  }

  protected ethereumOwnNft(): string {
    return "Hold (any of) NFTs";
  }

  protected ethereumOwnPoap(): string {
    return "Hold POAP";
  }

  protected solanaConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect Solana Wallet";
    } else {
      return "Connect";
    }
  }

  protected solanaOwnToken(): string {
    return "Hold (any of) tokens";
  }

  protected aptosConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect Aptos Wallet";
    } else {
      return "Connect";
    }
  }

  protected aptosOwnToken(): string {
    return "Hold (any of) tokens";
  }

  protected aptosOwnNft(): string {
    return "Hold (any of) NFTs";
  }

  protected walletProvideLiquidity(): string {
    return "Provide liquidity";
  }

  protected twitterConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect Twitter";
    } else {
      return "Connect";
    }
  }

  protected twitterMention(): string {
    return "Mention";
  }

  protected twitterLikeRetweet(): string {
    return "Like / Retweet";
  }

  protected twitterFollow(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Twitter Follow";
    } else {
      return "Follow";
    }
  }

  protected twitterReact(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "React on Twitter";
    } else {
      return "React";
    }
  }

  protected twitterNameSubstring(
    options: ActionTypeLabelBuilderOptions,
  ): string {
    if (options.withPlatformContext) {
      return "Include in Twitter name";
    } else {
      return "Include in name";
    }
  }

  protected twitterBioSubstring(
    options: ActionTypeLabelBuilderOptions,
  ): string {
    if (options.withPlatformContext) {
      return "Include in Twitter bio";
    } else {
      return "Include in bio";
    }
  }

  protected twitterProfilePicture(
    options: ActionTypeLabelBuilderOptions,
  ): string {
    if (options.withPlatformContext) {
      return "Twitter Profile Picture";
    } else {
      return "Profile Picture";
    }
  }

  protected discordConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect Discord";
    } else {
      return "Connect";
    }
  }

  protected discordServerJoin(): string {
    return "Join server";
  }

  protected discordHasDiscordRole(): string {
    return "Have a role";
  }

  protected discordSendMessage(): string {
    return "Send message";
  }

  protected discordReaction(): string {
    return "React to message";
  }

  protected youtubeConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect YouTube";
    } else {
      return "Connect";
    }
  }

  protected youtubeSubscribe(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Subscribe on YouTube";
    } else {
      return "Subscribe";
    }
  }

  protected instagramConnection(
    options: ActionTypeLabelBuilderOptions,
  ): string {
    if (options.withPlatformContext) {
      return "Connect Instagram";
    } else {
      return "Connect";
    }
  }

  protected telegramConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect Telegram";
    } else {
      return "Connect";
    }
  }

  protected telegramJoinGroup(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Join Telegram Group";
    } else {
      return "Join Group";
    }
  }

  protected telegramJoinChannel(
    options: ActionTypeLabelBuilderOptions,
  ): string {
    if (options.withPlatformContext) {
      return "Join Telegram Channel";
    } else {
      return "Join Channel";
    }
  }

  protected telegramSendMessage(
    options: ActionTypeLabelBuilderOptions,
  ): string {
    if (options.withPlatformContext) {
      return "Send Telegram message";
    } else {
      return "Send message";
    }
  }

  protected unstoppableDomainsConnection(
    options: ActionTypeLabelBuilderOptions,
  ): string {
    if (options.withPlatformContext) {
      return "Connect Unstoppable";
    } else {
      return "Connect";
    }
  }

  protected emailConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect Email";
    } else {
      return "Connect";
    }
  }

  protected stripeSubscriptionVerified(
    options: ActionTypeLabelBuilderOptions,
  ): string {
    if (options.withPlatformContext) {
      return "Verify Stripe subscription";
    } else {
      return "Verify";
    }
  }

  protected location(): string {
    return "Your location";
  }

  protected phoneNumber(): string {
    return "Phone number";
  }

  protected multipleChoice(): string {
    return "Multiple Choice";
  }

  protected textInput(): string {
    return "Text input";
  }

  protected urlInput(): string {
    return "Enter URL";
  }

  protected manualPointAdjustment(): string {
    throw new Error("Manual points shouldn't be user-facing");
  }

  protected kazmForm(): string {
    return "Complete Kazm form";
  }

  protected kazmMembership(): string {
    return "Join Kazm membership";
  }

  protected kazmMembershipTier(): string {
    return "Reach a tier";
  }

  protected kazmMemberTag(): string {
    return "Have a tag";
  }

  protected kazmQuestCompletion(): string {
    return "Complete quest";
  }

  protected kazmBadgeEarned(): string {
    return "Earn a badge";
  }

  protected kazmApiEvent(): string {
    return "Custom Events";
  }

  protected reCaptchaV2(): string {
    return "Complete reCaptcha";
  }

  protected questPointsThreshold(): string {
    return "Points";
  }

  protected uploadImage(): string {
    return "Upload image or video";
  }

  protected termsOfServiceAgreement(): string {
    return "Accept agreement";
  }

  protected referral(): string {
    return "Refer a friend";
  }

  protected referralBonus(): string {
    return "Referral bonus";
  }

  protected instagramMedia(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Instagram post";
    } else {
      return "Post";
    }
  }
  protected instagramFollow(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Instagram Follow";
    } else {
      return "Follow";
    }
  }
  protected tikTokMedia(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Post TikTok video";
    } else {
      return "Post a video";
    }
  }
  protected tikTokFollow(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "TikTok Follow";
    } else {
      return "Follow";
    }
  }
  protected spotifyConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect Spotify";
    } else {
      return "Connect";
    }
  }
  protected spotifyFollow(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Follow on Spotify";
    } else {
      return "Follow";
    }
  }
  protected spotifyListen(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Listen on Spotify";
    } else {
      return "Listen";
    }
  }
  protected tikTokConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect TikTok";
    } else {
      return "Connect";
    }
  }
  protected proofOfPresence(): string {
    return "Proof of Presence";
  }
  protected visitLink(): string {
    return "Visit Link";
  }
  protected facebookConnection(options: ActionTypeLabelBuilderOptions): string {
    if (options.withPlatformContext) {
      return "Connect Facebook";
    } else {
      return "Connect";
    }
  }
  protected checkIn(): string {
    return "Check In";
  }
}
