import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";
import styled from "@emotion/styled";
import { useMemberDrawer } from "../member_drawer_provider";

import { ExternalLink } from "@/common_components/ExternalLink";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { Shimmer } from "@/common_components/loading/shimmer";
import {
  PickerMenu,
  PickerMenuItem,
} from "@/common_components/menus/PickerMenuCard";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@/common_components/overlays/modals/ConfirmModal";
import { ConnectedAccountIcon } from "@/modules/connected_accounts/ConnectedAccountIcon/ConnectedAccountIcon";
import { getAccountProfileUrl } from "@/modules/connected_accounts/ConnectedAccountLink/ConnectedAccountLink";
import { useGetAllMemberConnectedAccounts } from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts";
import { getMemberDisplay } from "@/projects/members/table/member_utils";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useReloadMembers } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { ToastUtils } from "@/utils/toast_utils";
import KazmUtils from "@/utils/utils";
import {
  ConnectedAccountDto,
  MemberConnectedAccountType,
} from "@juntochat/internal-api";
import { useState } from "react";
import { RxExternalLink } from "react-icons/rx";
import { EditMemberConnectionsModal } from "./EditMemberConnectionsModal";

export function ConnectedAccounts() {
  const { selectedMember } = useMemberDrawer();
  const { data: accounts } = useGetAllMemberConnectedAccounts(
    selectedMember?.memberId,
  );

  return (
    <div className="!inset-0 flex max-w-full flex-1 flex-wrap justify-between">
      <div className="flex gap-x-[10px]">
        {accounts ? (
          accounts.map((account) => (
            <ConnectedAccount key={account.id} account={account} />
          ))
        ) : (
          <>
            <Shimmer width={40} height={40} />
            <Shimmer width={40} height={40} />
            <Shimmer width={40} height={40} />
          </>
        )}
      </div>
      <EditConnectionsMenu />
    </div>
  );
}

interface ConnectedAccountProps {
  account: ConnectedAccountDto;
  label?: string;
}

function ConnectedAccount({ account }: ConnectedAccountProps) {
  const hideActionAccountTypes = new Set<MemberConnectedAccountType>([
    MemberConnectedAccountType.FacebookAccount,
  ]);

  console.log(account);

  function getDisplayName() {
    if (
      account.accountType === MemberConnectedAccountType.EthereumWallet ||
      account.accountType === MemberConnectedAccountType.SolanaWallet ||
      account.accountType === MemberConnectedAccountType.AptosWallet
    ) {
      return KazmUtils.shortenWalletAddress(account.id);
    } else {
      return account?.name ?? "Unknown";
    }
  }

  return (
    <Tooltip
      position="top center"
      on={["hover"]}
      padding={0}
      contentStyle={{
        borderRadius: "4px",
      }}
      tooltipContent={
        <div className="flex items-center justify-center space-x-[5px] rounded-[4px] px-[8px] py-[4px] text-white">
          <div>{getDisplayName()}</div>
          {!hideActionAccountTypes.has(account.accountType) && (
            <ConnectedAccountAction account={account} />
          )}
        </div>
      }
    >
      <ConnectedAccountIconContainer className="h-[40px]">
        <ConnectedAccountIcon accountType={account.accountType} size={20} />
      </ConnectedAccountIconContainer>
    </Tooltip>
  );
}

enum EditConnectionOptions {
  EditConnections = "Edit Connections",
  Disconnect = "Disconnect",
}

function EditConnectionsMenu() {
  const { reloadMembers } = useReloadMembers();
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showEditMemberConnectionsModal, setShowEditMemberConnectionsModal] =
    useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const { showConfirmModal, controller: confirmModalController } =
    useConfirmModalController<ConfirmDeletionModalProps>();
  const { selectedMember, setSelectedMemberId } = useMemberDrawer();
  const { name } = getMemberDisplay({
    member: selectedMember,
  });

  const items = [
    {
      id: EditConnectionOptions.EditConnections,
      label: "Edit Connections",
      onClick: () => setShowEditMemberConnectionsModal(true),
    },
    {
      id: EditConnectionOptions.Disconnect,
      label: "Delete Member",
      onClick: () =>
        showConfirmModal(
          async () => {
            if (!selectedMember) {
              throw new Error("Selected member is not defined");
            }

            await cloudFunctionsService.memberApi.orgMemberInfoControllerBatchDelete(
              {
                orgId,
                deleteMembershipMembersDto: {
                  memberIds: [selectedMember.memberId],
                },
              },
            );
            await reloadMembers();
            setSelectedMemberId(undefined);
          },
          {
            title: "Are you sure you want to delete this member?",
            description: (
              <div className="w-full text-gray-200">
                This will remove the following member from the organization,{" "}
                {name}.
              </div>
            ),
            hideCancelButton: true,
            buttonText: "Delete",
          },
        ),
    },
  ];

  const menuItems = items.map(
    (item): PickerMenuItem<EditConnectionOptions> => ({
      id: item.id,
      label: <div className="text-left">{item.label}</div>,
    }),
  );

  return (
    <>
      <ConfirmDeletionModal controller={confirmModalController} />
      <div>
        <UnstyledButton
          onClick={() => setIsMenuOpen(true)}
          onMouseDown={(e) => setMousePosition({ x: e.clientX, y: e.clientY })}
        >
          <ConnectedAccountIconContainer className="h-[40px]">
            <KazmIcon.ThreeDotsVertical
              size={20}
              className="cursor-pointer text-gray-300 hover:text-white"
            />
          </ConnectedAccountIconContainer>
        </UnstyledButton>
        <PickerMenu
          state={isMenuOpen ? "open" : "closed"}
          onClose={() => setIsMenuOpen(false)}
          anchorPoint={mousePosition}
          menuItems={menuItems}
          onPick={(item) => {
            items.find((i) => i.id === item.id)?.onClick();
            setIsMenuOpen(false);
          }}
          direction="bottom"
        />
      </div>
      {showEditMemberConnectionsModal && (
        <EditMemberConnectionsModal
          onClose={() => setShowEditMemberConnectionsModal(false)}
        />
      )}
    </>
  );
}

const ConnectedAccountIconContainer = styled(UnstyledButton)({
  display: "flex",
  width: "40px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
});

function ConnectedAccountAction({ account }: { account: ConnectedAccountDto }) {
  const copyTypes: MemberConnectedAccountType[] = [
    MemberConnectedAccountType.Email,
    MemberConnectedAccountType.Phone,
    MemberConnectedAccountType.AptosWallet,
    MemberConnectedAccountType.SolanaWallet,
    MemberConnectedAccountType.EthereumWallet,
  ];

  if (copyTypes.includes(account.accountType)) {
    return (
      <KazmIcon.Link
        className="cursor-pointer text-gray-300 hover:text-white"
        onClick={() => {
          navigator.clipboard.writeText(account.id);
          ToastUtils.showInfoToast("Copied to clipboard");
        }}
      />
    );
  }

  const link = getAccountProfileUrl(account);

  if (!link) {
    return null;
  }

  return (
    <ExternalLink href={link}>
      <RxExternalLink className="cursor-pointer text-gray-300 hover:text-white" />
    </ExternalLink>
  );
}
