import { ReactElement, ReactNode, useEffect } from "react";

import {
  ActionOutcomeBuilderProvider,
  getRequiredOrgMemberAccountTypeByActionType,
  useActionOutcomesProvider,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import {
  ActionTypeVisitor,
  CommonActionUtils,
  CommonQuestUtils,
  MemberActionDefinition,
  MemberActionType,
} from "@juntochat/kazm-shared";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { InstagramFollowOutcomeBuilder } from "@/modules/actions/outcomes/builders/InstagramFollow/InstagramFollow";
import { TikTokFollowOutcomeBuilder } from "@/modules/actions/outcomes/builders/TikTokFollow/TikTokFollow";
import { TikTokMediaOutcomeBuilder } from "@/modules/actions/outcomes/builders/TikTokMedia/TikTokMedia";
import { TwitterFollowOutcomeBuilder } from "@/modules/actions/outcomes/builders/TwitterFollow/TwitterFollow";
import { YoutubeSubscribeOutcomeBuilder } from "@/modules/actions/outcomes/builders/YoutubeSubscribe/YoutubeSubscribe.tsx";
import {
  OutcomeBuilderConnectedAccountsProvider,
  useOptionalOutcomeBuilderConnectedAccounts,
  useOutcomeBuilderConnectedAccounts,
} from "@/modules/actions/outcomes/builders/common/OutcomeBuilderConnectedAccounts";
import { VerifiedOutcomeCheckmark } from "@/modules/actions/outcomes/builders/common/VerifiedOutcomeCheckmark";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { ConnectedAccountBuilder } from "@/modules/connected_accounts/ConnectedAccountBuilder/ConnectedAccountBuilder";
import { ConnectedAccountIcon } from "@/modules/connected_accounts/ConnectedAccountIcon/ConnectedAccountIcon";
import { ConnectedAccountTypeName } from "@/modules/connected_accounts/ConnectedAccountTitle/ConnectedAccountTypeName";
import {
  useGetAllCurrentMemberConnectedAccounts,
  useRefreshConnectedAccountsOnWindowFocus,
} from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useIsAppEmbed } from "@/utils/hooks/use_embedded_options";
import { ErrorMessage } from "@common/error/ErrorMessage";
import { Shimmer } from "@common/loading/shimmer";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import classNames from "classnames";
import { CheckInOutcomeBuilder } from "./CheckInOutcomeBuilder";
import { DefaultOutcomeBuilder } from "./Default/Default";
import { DiscordSendMessageOutcomeBuilder } from "./DiscordSendMessage/DiscordSendMessage";
import { EthereumOutcomeBuilder } from "./Ethereum/Ethereum";
import { EthereumOwnNftOutcomeBuilder } from "./EthereumOwnNft/EthereumOwnNft";
import { EthereumOwnTokenOutcomeBuilder } from "./EthereumOwnToken/EthereumOwnToken";
import { InstagramMediaOutcomeBuilder } from "./InstagramMedia/InstagramMedia";
import { KazmMembershipOutcomeBuilder } from "./KazmMembership/KazmMembershipOutcomeBuilder";
import { LocationOutcomeBuilder } from "./Location/Location";
import { ManualPointsAdjustmentOutcomeBuilder } from "./ManualPointsAdjustment/ManualPointsAdjustment";
import { MultipleChoiceOutcomeBuilder } from "./MultipleChoice/MultipleChoice";
import { PhoneNumberOutcomeBuilder } from "./PhoneNumber/PhoneNumber";
import { ReCaptchaOutcomeBuilder } from "./ReCaptcha/ReCaptcha";
import { ReferralOutcomeBuilder } from "./Referral/Referral";
import { SolanaOwnTokenOutcomeBuilder } from "./SolanaOwnToken/SolanaOwnToken";
import { StripeSubscriptionOutcomeBuilder } from "./StripeSubscription/StripeSubscription";
import { TelegramSendMessageOutcomeBuilder } from "./TelegramSendMessage/TelegramSendMessage";
import { TermsOfServiceOutcomeBuilder } from "./TermsOfService/TermsOfService";
import { TextInputOutcomeBuilder } from "./TextInput/TextInput";
import { TwitterBioSubstringOutcomeBuilder } from "./TwitterBioSubstringOutcomeBuilder";
import { TwitterMentionOutcomeBuilder } from "./TwitterMention/TwitterMention";
import { TwitterNameSubstringOutcomeBuilder } from "./TwitterNameSubstringOutcomeBuilder";
import { TwitterProfilePictureOutcomeBuilder } from "./TwitterProfilePictureOutcomeBuilder";
import { UploadImageOutcomeBuilder } from "./UploadImage/UploadImage";
import { UrlInputOutcomeBuilder } from "./UrlInput/UrlInput";
import { VisitLinkOutcomeBuilder } from "./VisitLink/VisitLink";
import { WalletProvideLiquidityOutcomeBuilder } from "./WalletProvideLiquidityOutcomeBuilder";
import {
  OutcomeBuilderConfigProvider,
  useOutcomeBuilderConfig,
} from "./common/OutcomeBuilderConfig";
import { IndicateIfRequired } from "./common/OutcomeBuilderContainer";
import { AptosOwnTokenOutcomeBuilder } from "./AptosOwnToken/AptosOwnToken";
import { AptosOwnNftOutcomeBuilder } from "./AptosOwnNft/AptosOwnNft";

interface ActionOutcomeBuilderProps {
  actionDefinition: MemberActionDefinition;
  shouldUseMembershipBranding: boolean;
  renderContentOnly?: boolean;
  className?: string;
  connectAccountTitle?: string;
  showIcon?: boolean;
}

export function ActionOutcomeBuilder(props: ActionOutcomeBuilderProps) {
  return (
    <OutcomeBuilderConfigProvider showIcon={props.showIcon ?? false}>
      <ActionOutcomeBuilderProvider actionDefinition={props.actionDefinition}>
        <div className="w-full">
          <OutcomeBuilderWithProvider {...props} />
        </div>
      </ActionOutcomeBuilderProvider>
    </OutcomeBuilderConfigProvider>
  );
}

// This is for internal usage only (e.g. component tests).
// You should use `ActionOutcomeBuilder` or `ActionOutcomeListBuilder` instead.
export function OutcomeBuilderWithProvider(
  props: Omit<ActionOutcomeBuilderProps, "actionDefinition">,
) {
  const visitor = new OutcomeBuilderInternal();

  const { definition } = useOutcomeBuilderProvider();
  const { branding } = useMembershipBranding();
  const isAdminApp = useIsAdminApp();

  const requiredAccountType = getRequiredOrgMemberAccountTypeByActionType(
    definition.type,
  );

  if (props.renderContentOnly) {
    return (
      <div className="font-semibold">{visitor.render(definition.type)}</div>
    );
  }

  const isAutomaticallyClaimed = CommonQuestUtils.isAutomaticallyClaimed(
    definition.type,
  );

  return (
    <div
      className={classNames(
        "flex w-full flex-col rounded-[10px] text-start font-semibold",
        props.className,
      )}
      style={{
        color: props.shouldUseMembershipBranding
          ? branding?.textColor
          : undefined,
        background: props.shouldUseMembershipBranding
          ? branding?.containerColor
          : undefined,
      }}
    >
      <WithOptionalAccountType
        accountType={requiredAccountType}
        title={props.connectAccountTitle}
      >
        {visitor.render(definition.type)}
      </WithOptionalAccountType>
      {!isAutomaticallyClaimed && !isAdminApp && <OutcomeErrors />}
    </div>
  );
}

// Unfortunately we can't make this both
// an action visitor and a (class) React component,
// since classes can't extend multiple classes in Typescript.
class OutcomeBuilderInternal extends ActionTypeVisitor<
  never,
  ReactElement | null
> {
  render(actionType: MemberActionType) {
    return this.visit(actionType, undefined as never);
  }

  protected ethereumConnection(): ReactElement | null {
    return <DefaultOutcomeBuilder />;
  }

  protected ethereumOwnToken(): ReactElement {
    return <EthereumOwnTokenOutcomeBuilder />;
  }

  protected ethereumMinimumBalance(): ReactElement {
    return <EthereumOutcomeBuilder />;
  }

  protected ethereumOwnNft(): ReactElement {
    return <EthereumOwnNftOutcomeBuilder />;
  }

  protected ethereumOwnPoap(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected solanaConnection(): ReactElement | null {
    return <DefaultOutcomeBuilder />;
  }

  protected solanaOwnToken(): ReactElement {
    return <SolanaOwnTokenOutcomeBuilder />;
  }

  protected aptosConnection(): ReactElement | null {
    return <DefaultOutcomeBuilder />;
  }

  protected aptosOwnToken(): ReactElement | null {
    return <AptosOwnTokenOutcomeBuilder />;
  }

  protected aptosOwnNft(): ReactElement | null {
    return <AptosOwnNftOutcomeBuilder />;
  }

  protected walletProvideLiquidity(): ReactElement {
    return <WalletProvideLiquidityOutcomeBuilder />;
  }

  protected twitterConnection(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected twitterMention(): ReactElement {
    return <TwitterMentionOutcomeBuilder />;
  }

  protected twitterLikeRetweet(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected twitterFollow(): ReactElement {
    return <TwitterFollowOutcomeBuilder />;
  }

  protected twitterReact(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected twitterNameSubstring(): ReactElement {
    return <TwitterNameSubstringOutcomeBuilder />;
  }

  protected twitterBioSubstring(): ReactElement {
    return <TwitterBioSubstringOutcomeBuilder />;
  }

  protected twitterProfilePicture(): ReactElement {
    return <TwitterProfilePictureOutcomeBuilder />;
  }

  protected instagramFollow(): ReactElement {
    return <InstagramFollowOutcomeBuilder />;
  }

  protected discordConnection(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected discordServerJoin(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected discordHasDiscordRole(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected discordSendMessage(): ReactElement {
    return <DiscordSendMessageOutcomeBuilder />;
  }

  protected discordReaction(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected instagramConnection(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected telegramConnection(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected telegramJoinGroup(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected telegramJoinChannel(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected telegramSendMessage(): ReactElement {
    return <TelegramSendMessageOutcomeBuilder />;
  }

  protected unstoppableDomainsConnection(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected emailConnection(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected stripeSubscriptionVerified(): ReactElement {
    return <StripeSubscriptionOutcomeBuilder />;
  }

  protected instagramMedia(): ReactElement {
    return <InstagramMediaOutcomeBuilder />;
  }

  protected tikTokMedia(): ReactElement {
    return <TikTokMediaOutcomeBuilder />;
  }

  protected tikTokFollow(): ReactElement {
    return <TikTokFollowOutcomeBuilder />;
  }

  protected spotifyConnection(): ReactElement | null {
    return <DefaultOutcomeBuilder />;
  }

  protected spotifyFollow(): ReactElement | null {
    return <DefaultOutcomeBuilder />;
  }

  protected spotifyListen(): ReactElement | null {
    return <DefaultOutcomeBuilder />;
  }

  protected tikTokConnection(): ReactElement | null {
    return <DefaultOutcomeBuilder />;
  }

  protected youtubeConnection(): ReactElement | null {
    return <DefaultOutcomeBuilder />;
  }

  protected youtubeSubscribe(): ReactElement | null {
    return <YoutubeSubscribeOutcomeBuilder />;
  }

  // Actions without account connection requirement.

  protected location(): ReactElement {
    return <LocationOutcomeBuilder />;
  }

  protected phoneNumber(): ReactElement {
    return <PhoneNumberOutcomeBuilder />;
  }

  protected multipleChoice(): ReactElement {
    return <MultipleChoiceOutcomeBuilder />;
  }

  protected textInput(): ReactElement {
    return <TextInputOutcomeBuilder />;
  }

  protected urlInput(): ReactElement {
    return <UrlInputOutcomeBuilder />;
  }

  protected manualPointAdjustment(): ReactElement {
    return <ManualPointsAdjustmentOutcomeBuilder />;
  }

  protected kazmForm(): ReactElement {
    return <DefaultOutcomeBuilder />;
  }

  protected kazmMembership(): ReactElement {
    return <KazmMembershipOutcomeBuilder />;
  }

  protected kazmMembershipTier() {
    return <DefaultOutcomeBuilder />;
  }

  protected kazmQuestCompletion() {
    return <DefaultOutcomeBuilder />;
  }

  protected kazmBadgeEarned() {
    return <DefaultOutcomeBuilder />;
  }

  protected kazmApiEvent(): ReactElement | null {
    return null;
  }

  protected kazmMemberTag(): ReactElement | null {
    // We never show this in the UI (since it's only used as a prerequisite).
    return null;
  }

  protected reCaptchaV2(): ReactElement {
    return <ReCaptchaOutcomeBuilder />;
  }

  protected questPointsThreshold(): ReactElement {
    return <DefaultOutcomeBuilder onlyShowRequiredPoints />;
  }

  protected uploadImage(): ReactElement {
    return <UploadImageOutcomeBuilder />;
  }

  protected termsOfServiceAgreement(): ReactElement {
    return <TermsOfServiceOutcomeBuilder />;
  }

  protected referral(): ReactElement {
    return <ReferralOutcomeBuilder />;
  }

  protected referralBonus(): ReactElement {
    return <ReferralOutcomeBuilder />;
  }

  protected proofOfPresence(): ReactElement | null {
    return null;
  }

  protected visitLink(): ReactElement | null {
    return <VisitLinkOutcomeBuilder />;
  }

  protected facebookConnection() {
    return <DefaultOutcomeBuilder />;
  }

  protected checkIn() {
    return <CheckInOutcomeBuilder />;
  }
}

type WithOptionalAccountTypeProps = Omit<
  WithRequiredAccountTypeProps,
  "accountType"
> & {
  accountType: MemberConnectedAccountType | undefined;
};

function WithOptionalAccountType(props: WithOptionalAccountTypeProps) {
  const connectedAccountsProvider =
    useOptionalOutcomeBuilderConnectedAccounts();
  if (props.accountType === undefined) {
    return props.children;
  }

  // In case provider isn't present (for mocking purposes),
  // use the default provider to retrieve accounts from the REST API.
  if (connectedAccountsProvider === undefined) {
    return (
      <DefaultConnectedAccountsProvider>
        <WithRequiredAccountType {...props} accountType={props.accountType} />
      </DefaultConnectedAccountsProvider>
    );
  } else {
    return (
      <WithRequiredAccountType {...props} accountType={props.accountType} />
    );
  }
}

function DefaultConnectedAccountsProvider(props: { children: ReactNode }) {
  const response = useGetAllCurrentMemberConnectedAccounts();

  return (
    <OutcomeBuilderConnectedAccountsProvider value={response}>
      {props.children}
    </OutcomeBuilderConnectedAccountsProvider>
  );
}

type WithRequiredAccountTypeProps = {
  accountType: MemberConnectedAccountType;
  children: ReactElement | null;
  title?: string;
};

/**
 * Current pattern of using this component within each outcome builder works well
 * if we are displaying single outcome builder that requires a connection.
 * This is good enough for now, since this is indeed the case in quests/tier requirements.
 *
 * We may need to handle this differently (to not show duplicate connection prompts)
 * if we ever want to display multiple outcome builders
 * that each require a connection of the same account type,
 */
function WithRequiredAccountType(props: WithRequiredAccountTypeProps) {
  const { data: connectedAccounts } = useOutcomeBuilderConnectedAccounts();
  const { definition } = useOutcomeBuilderProvider();

  const isConnectionAction = CommonActionUtils.isConnectionAction(
    definition.type,
  );

  const requiredAccounts =
    connectedAccounts?.filter(
      (account) => account.accountType === props.accountType,
    ) ?? [];
  const hasRequiredAccount = requiredAccounts.length > 0;
  const requiredAccountWithExpiredToken = requiredAccounts.find(
    (account) => !account.isAuthTokenValid,
  );

  // Wrap in outcome section so that required action errors are shown.
  return (
    <div className="flex flex-col gap-y-[10px]">
      <AccountConnectionRequirement
        accountType={props.accountType}
        title={props.title}
      />
      {/* We don't need to show connection outcome builders */}
      {/* since they don't provide any additional value/context to the user. */}
      {!isConnectionAction && (
        <OutcomeBuilderConfigProvider
          // Icon is already shown in this component,
          // so we don't need to show it again in descending components.
          showIcon={false}
        >
          <div
            className={classNames({
              "pointer-events-none opacity-[0.5]":
                !hasRequiredAccount || requiredAccountWithExpiredToken,
            })}
          >
            {props.children}
          </div>
        </OutcomeBuilderConfigProvider>
      )}
    </div>
  );
}

function AccountConnectionRequirement(
  props: Pick<WithRequiredAccountTypeProps, "accountType" | "title">,
) {
  const {
    error,
    data: connectedAccounts,
    mutate: refetchConnectedAccounts,
  } = useOutcomeBuilderConnectedAccounts();
  const { definition } = useOutcomeBuilderProvider();
  const { clearOutcomeErrorsByDefinition } = useActionOutcomesProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();

  const isUserInputRequired =
    // Manually verifiable action builders always displays an input(s).
    CommonQuestUtils.isManuallyVerifiableAction(definition.type) ||
    new Set([
      // These also display a required input.
      MemberActionType.DISCORD_SEND_MESSAGE,
      MemberActionType.TWITTER_MENTION,
    ]).has(definition.type);

  const requiredAccounts =
    connectedAccounts?.filter(
      (account) => account.accountType === props.accountType,
    ) ?? [];
  const hasRequiredAccount = requiredAccounts.length > 0;
  const requiredAccountWithExpiredToken = requiredAccounts.find(
    (account) => !account.isAuthTokenValid,
  );
  const isAppEmbed = useIsAppEmbed();

  async function onAccountConnected() {
    if (isUserInputRequired) {
      // Just clear any "Account required" errors.
      clearOutcomeErrorsByDefinition(definition.id);
    } else {
      const isConnectionOnly = CommonActionUtils.isConnectionAction(
        definition.type,
      );
      await verifyOutcome({
        // We know in advance that connection only actions will be valid.
        optimistic: isConnectionOnly,
      });
    }
  }

  useRefreshConnectedAccountsOnWindowFocus({
    // Only refresh if they haven't already connected the account
    shouldRefresh: !hasRequiredAccount,
    editingAccountType: props.accountType,
  });

  useEffect(() => {
    if (
      hasRequiredAccount &&
      // We want to avoid making extra calls to the twitter API due to rate limit constraints
      definition.type !== MemberActionType.TWITTER_REACT
    ) {
      onAccountConnected();
    }
  }, [hasRequiredAccount]);

  const title = props.title ?? (
    <span className="text-[15px]">
      Connect <ConnectedAccountTypeName accountType={props.accountType} />
    </span>
  );

  const isAdminApp = useIsAdminApp();

  if (isAdminApp) {
    return <ConnectSuccess accountType={props.accountType} title={title} />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!connectedAccounts) {
    return <Shimmer height={30} />;
  }

  if (requiredAccountWithExpiredToken) {
    return (
      <div className="flex flex-col gap-y-[10px]">
        <ConnectedAccountBuilder
          isEditable
          onCreateSuccess={() => refetchConnectedAccounts()}
          accountType={props.accountType}
          initialAccountId={requiredAccountWithExpiredToken.id}
        />
        <ErrorMessage
          className="text-right font-normal"
          error={`Reconnect @${requiredAccountWithExpiredToken.username} (token expired)`}
        />
      </div>
    );
  }

  if (hasRequiredAccount) {
    return <ConnectSuccess accountType={props.accountType} title={title} />;
  }

  return (
    <ConnectedAccountBuilder
      isEditable
      onCreateSuccess={() => refetchConnectedAccounts()}
      accountType={props.accountType}
      allowManualRefresh={isAppEmbed}
    />
  );
}

type ConnectSuccessProps = {
  accountType: MemberConnectedAccountType;
  title: ReactNode;
};

// Must match the layout and design of `ConnectedAccountBuilder` component.
function ConnectSuccess(props: ConnectSuccessProps) {
  const { showIcon } = useOutcomeBuilderConfig();
  const { branding } = useMembershipBranding();

  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-[2px]">
        {showIcon && (
          <ConnectedAccountIcon
            accountType={props.accountType}
            color={branding?.textColor}
            size={20}
          />
        )}
        <div
          className="flex items-center gap-x-[10px]"
          style={{
            color: branding?.textColor,
          }}
        >
          {props.title}
        </div>
        <IndicateIfRequired />
      </div>
      <VerifiedOutcomeCheckmark />
    </div>
  );
}

function OutcomeErrors() {
  const { definition } = useOutcomeBuilderProvider();

  const { textSizeMultiplier } = useMembershipBranding();
  const outcomeManager = useActionOutcomesProvider();

  const errors =
    outcomeManager.outcomeErrorsByDefinitionId.get(definition.id) ?? [];

  if (errors.length === 0) {
    return null;
  }

  return (
    <div className="flex-column mt-1 w-full">
      {errors.map((error) => (
        <div
          className="text-red-200"
          style={{ fontSize: 12 * textSizeMultiplier }}
          key={error.description}
        >
          {error.description}
        </div>
      ))}
    </div>
  );
}
