import { useEffect, useRef } from "react";
import Modal from "react-modal";

import "./styles";

import { TextStyles } from "@utils/styles";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { appAuth } from "./firebase";
import AuthProvider from "./providers/auth_provider";
import { SolanaWalletProvider } from "./providers/solana_wallet_provider";
import { KazmSwrProvider } from "./providers/swr_provider";
import { KazmWagmiProvider } from "./providers/wagmi_provider";
import { KazmRouter } from "./router";
import { CloudFunctionsServiceProvider } from "./services/cloud_functions_service";
import { useGetFavicon } from "./utils/hooks/use_get_favicon";
import { useHideLoader } from "./utils/hooks/use_hide_loader";
import TwitterPixel from "./utils/twitter_pixel";
import { AptosWalletProvider } from "./providers/aptos_provider";

export function App() {
  useHideLoader();

  const twitterPixelInitiated = useRef<boolean>(false);

  useEffect(() => {
    if (twitterPixelInitiated.current) return;
    TwitterPixel.init({ pixelId: "o9373", options: { debug: false } });
    twitterPixelInitiated.current = true;
  }, []);

  useEffect(() => {
    Modal.setAppElement("#root");
  });

  const { faviconHref } = useGetFavicon();

  return (
    <div className={classNames("App", TextStyles.body)} id="root-element">
      <Helmet>
        <link rel="icon" type="image/svg" href={faviconHref} sizes="32x32" />
      </Helmet>
      <KazmWagmiProvider>
        <SolanaWalletProvider>
          <AptosWalletProvider>
            <KazmSwrProvider>
              <CloudFunctionsServiceProvider auth={appAuth}>
                <AuthProvider auth={appAuth}>
                  <KazmRouter />
                </AuthProvider>
              </CloudFunctionsServiceProvider>
            </KazmSwrProvider>
          </AptosWalletProvider>
        </SolanaWalletProvider>
      </KazmWagmiProvider>
    </div>
  );
}
