import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { ActionButton } from "@common/buttons/ActionButton";
import { CenterModal } from "@common/overlays/modals/CenterModal";
import {
  AppColors,
  MemberActionOutcome,
  MemberActionType,
} from "@juntochat/kazm-shared";
import { ToastUtils } from "@utils/toast_utils";

import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import {
  ActionOutcomeBuilder,
  useActionOutcomesProvider,
} from "@/modules/actions";
import { AccessibleImage } from "@common/images/AccessibleImage";

import { QrCode } from "@/common_components/QrCode";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { ClaimActionResult } from "@/membership_form/components/rewards/ClaimRewardModal/ClaimActionResult.tsx";
import { useRedeemRewardController } from "@/membership_form/components/rewards/ClaimRewardModal/controller_provider";
import { getPosRewardUrl } from "@/membership_form/pages/PointOfSaleRewardPage";
import { useConfetti } from "@/membership_form/providers/confetti_provider";
import { useOrgMember } from "@/membership_form/providers/org_member_provider";
import { ActivationImage } from "@/modules/activations/ActivationImage/ActivationImage.tsx";
import { ActivationPointsBadge } from "@/modules/activations/ActivationPointsBadge/ActivationPointsBadge.tsx";
import { DEFAULT_EMPTY_RAW_FORMAT } from "@/projects/membership/providers/forms/use_default_form.ts";
import {
  RichTextEditor,
  RichTextView,
} from "@common/editors/RichTextEditor/RichTextEditor";
import { KazmIcon } from "@common/icons/KazmIcons";
import { ShareModalLayout } from "@common/overlays/modals/ShareModals/common/ShareModalLayout";
import { ActionType } from "@juntochat/internal-api";
import { useMemberPoints } from "@utils/hooks/use_member_points";
import classNames from "classnames";
import { ReactNode, createRef, useEffect, useState } from "react";
import { MembershipThemedModal } from "@common/overlays/modals/MembershipThemedModal.tsx";

export type ClaimRewardModalViewProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

export function ClaimRewardModalView(props: ClaimRewardModalViewProps) {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const shareMessage = loyaltyForm.shareMessage;
  const { activation, shouldShowSuccess } = useRedeemRewardController();
  const { branding } = useMembershipBranding();

  if (shouldShowSuccess) {
    return (
      <RewardSuccessModal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        shareMessage={shareMessage}
      />
    );
  }

  return (
    <MembershipThemedModal
      title={
        activation.points !== 0 && (
          <div className="text-[16px] !font-normal">
            <ActivationPointsBadge activation={activation} enablePointsLabel />
          </div>
        )
      }
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={{
        content: {
          maxWidth: "600px",
          overflow: "visible",
        },
      }}
    >
      <ClaimRewardModalContent />
    </MembershipThemedModal>
  );
}

function ClaimRewardModalContent() {
  const { activation, isLoadingAutoClaim } = useRedeemRewardController();
  const { branding } = useMembershipBranding();
  const isDefaultRichDescription =
    activation.richDescription === JSON.stringify(DEFAULT_EMPTY_RAW_FORMAT);

  if (isLoadingAutoClaim) {
    return (
      <div className="flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <div className="max-h-[80vh] space-y-[20px] overflow-y-scroll text-center">
        <div>
          <div
            className="headline-md mx-auto"
            style={{ color: branding.textColor }}
          >
            {activation.title}
          </div>
          {!isDefaultRichDescription && (
            <RichTextView
              textColor={branding.textColor}
              value={JSON.parse(activation?.richDescription || "[]")}
            />
          )}
        </div>
        <RedeemRewardBuilder />
      </div>
    );
  }
}

type RewardSuccessModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  shareMessage: string;
};

function RewardSuccessModal(props: RewardSuccessModalProps) {
  const { activation, claim } = useRedeemRewardController();

  return (
    <CenterModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={{
        content: {
          maxWidth: 600,
          background: AppColors.darkBaseLighter,
        },
      }}
    >
      <div className="headline-md text-center">{activation.title}</div>
      <ShareModalLayout
        generateMessage={() => props.shareMessage}
        membershipId={activation.membershipId}
      >
        <div className="space-y-[10px] pb-[10px]">
          <RichTextEditor
            textEditorClassName="text-center"
            readOnly
            rawValue={activation.displaySettings?.successMessage}
          />
          {activation.imageUrl !== "" && (
            <AccessibleImage
              className="h-[300px] w-full overflow-hidden rounded-[10px] object-cover"
              src={activation.imageUrl}
            />
          )}
          {claim?.claimActionResults.map((result) => (
            <ClaimActionResult
              key={result.type}
              action={activation.claimActions.find(
                (e) => e.type === result.type,
              )}
              result={result}
            />
          ))}
        </div>
      </ShareModalLayout>
    </CenterModal>
  );
}

function RedeemRewardBuilder() {
  const controller = useRedeemRewardController();
  const { activation } = controller;

  switch (activation.type) {
    case ActionType.RewardQrCode:
      return <QrCodeRewardBuilder />;
    case ActionType.RewardMerch:
    case ActionType.RewardShopifyDiscount:
    case ActionType.RewardLink:
    case ActionType.RewardDiscordRole:
    case ActionType.RewardKazmDiscount:
    default:
      return <DefaultBody />;
  }
}

function QrCodeRewardBuilder() {
  const controller = useRedeemRewardController();
  const [showQrCode, setShowQrCode] = useState<boolean>(false);
  const qrCodeWrapper = createRef<HTMLDivElement>();
  const { signedInMember } = useOrgMember();
  const { loyaltyForm } = useLoyaltyFormProvider();

  if (!showQrCode) {
    return <DefaultBody overrideRedeemClick={() => setShowQrCode(true)} />;
  }

  if (!loyaltyForm) {
    throw new Error("Loyalty form undefined");
  }

  const qrCodeLink = `${window.location.origin}/${getPosRewardUrl({
    membershipId: loyaltyForm.id,
    memberId: signedInMember.memberId,
    posRewardId: controller.activation.activationId,
    orgId: loyaltyForm.orgId,
  })}`;

  return (
    <div className="flex flex-col gap-y-[10px]" ref={qrCodeWrapper}>
      <span className="font-semibold">
        {controller.activation?.displaySettings?.qrCodeReward?.prompt}
      </span>
      <QrCode
        autoSize={{
          referenceElementRef: qrCodeWrapper,
          referenceDimension: "width",
        }}
        value={qrCodeLink}
      />
      <div className="flex items-center gap-x-[10px]">
        <div className="text-center text-gray-300">
          Don’t scan & hit the “Mark Redeemed” button or you will lose your
          reward
        </div>
        <ActionButton
          className="flex items-center justify-center rounded-[4px] bg-gray-500 p-3"
          // QR code reward is redeemed on a separate page,
          // so we need to listen for new claims and treat them as submitted.
          onClick={() => controller.checkForUnseenClaims()}
        >
          <KazmIcon.Refresh />
        </ActionButton>
      </div>
    </div>
  );
}

function DefaultBody(props: {
  children?: ReactNode;
  overrideRedeemClick?: () => void | Promise<void>;
}) {
  const controller = useRedeemRewardController();

  return (
    <div className="flex flex-col gap-y-[20px]">
      <ActivationImage
        className="h-[400px] max-h-[400px] w-full"
        activation={controller.activation}
        fallbackToDefaultImage={false}
      />
      <PrerequisitesList />
      {props.children}
      <RedeemButton overrideOnClick={props.overrideRedeemClick} />
    </div>
  );
}

function PrerequisitesList() {
  const { branding } = useMembershipBranding();
  const controller = useRedeemRewardController();
  const { balancePoints } = useMemberPoints();
  const rewardPointsCost = Math.abs(controller.activation.points ?? 0);
  const hasEnoughPoints = balancePoints >= rewardPointsCost;
  const {
    definitions: claimRequirements,
    isOutcomeValid,
    updateAndVerifyOutcomes,
  } = useActionOutcomesProvider();

  const hasCompletedPrerequisites =
    controller.activation.claimRequirements.every((prerequisite) =>
      isOutcomeValid(prerequisite.id),
    );

  useEffect(() => {
    updateAndVerifyOutcomes({
      outcomes: claimRequirements.map((definition) =>
        MemberActionOutcome.fromPartial({
          definitionId: definition.id,
          type: definition.type,
        }),
      ),
      debounceValidation: false,
      // Reward prerequisite can include actions with
      // async validation logic (e.g. API calls to Discord API).
      optimistic: false,
    });
  }, []);

  const claimRequirementsToShow = claimRequirements.filter((requirement) => {
    const isPointsRequirementWithZeroPoints =
      requirement.type === MemberActionType.QUEST_POINTS_THRESHOLD &&
      requirement.questPointsThreshold?.threshold === 0;
    return !isPointsRequirementWithZeroPoints;
  });

  if (claimRequirementsToShow.length === 0) {
    return null;
  }

  return (
    <div className="space-y-[10px]">
      <div className="text-left font-semibold">Required:</div>
      <div className="flex w-full flex-col space-y-[10px]">
        {claimRequirementsToShow.map((prerequisite) => (
          <div
            key={prerequisite.id}
            className={classNames("rounded-[4px] p-[10px]", {
              "opacity-50": hasEnoughPoints && hasCompletedPrerequisites,
            })}
            style={{
              backgroundColor: branding.containerColor,
            }}
          >
            <ActionOutcomeBuilder
              actionDefinition={prerequisite}
              shouldUseMembershipBranding={false}
              showIcon={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function RedeemButton(props: { overrideOnClick?: () => void | Promise<void> }) {
  const { branding } = useMembershipBranding();
  const { activation, submitClaim } = useRedeemRewardController();
  const { showConfetti } = useConfetti();
  const { pendingPoints, balancePoints } = useMemberPoints();
  const rewardPointsCost = Math.abs(activation.points ?? 0);
  const hasEnoughPoints = balancePoints >= rewardPointsCost;
  const hasEnoughPointsWithPending =
    balancePoints + pendingPoints >= rewardPointsCost;
  const { isOutcomeValid } = useActionOutcomesProvider();

  const hasCompletedPrerequisites = activation.claimRequirements.every(
    (requirement) => isOutcomeValid(requirement.id),
  );
  const hasPointsRequirement = activation.claimRequirements.some(
    (requirement) =>
      requirement.type === ActionType.QuestPointsThreshold &&
      requirement.questPointsThreshold?.threshold !== 0,
  );

  async function onRedeem() {
    try {
      await submitClaim();
      // A couple notes for rewards:
      // - no need to check for outcome errors, since there are none for rewards.
      // - do not close the reward modal, as we show the success state within the same modal.
      showConfetti();
    } catch (error: unknown) {
      ToastUtils.showErrorToast(error);
    }
  }

  function renderHelpMessage() {
    if (!hasPointsRequirement) {
      return "";
    }

    if (hasEnoughPoints) {
      return "Your points balance will be adjusted";
    }

    if (hasEnoughPointsWithPending) {
      return <PendingPointsMessage pendingPoints={pendingPoints} />;
    }

    return "You don't have enough points to redeem this reward";
  }

  return (
    <div className="flex w-full flex-col space-y-[10px]">
      <ActionButton
        disabled={!hasEnoughPoints || !hasCompletedPrerequisites}
        onClick={props.overrideOnClick ?? onRedeem}
        className="h-[44px] w-full rounded-[30px] font-semibold"
        style={{
          backgroundColor: branding?.buttonColor,
          color: branding?.buttonTextColor,
        }}
      >
        {rewardPointsCost === 0
          ? "Redeem"
          : `Redeem for ${rewardPointsCost} points`}
      </ActionButton>
      <p
        className="mt-[10px] text-center text-[14px]"
        style={{
          color: branding?.textColor,
        }}
      >
        {renderHelpMessage()}
      </p>
    </div>
  );
}

function PendingPointsMessage(props: { pendingPoints: number }) {
  const { branding } = useMembershipBranding();

  return (
    <span
      style={{
        color: branding?.textColor,
      }}
    >
      <span className="font-semibold">
        You have {props.pendingPoints} pending points
      </span>
      , which you can't spend until they are approved by an admin
    </span>
  );
}
