import {
  OrgConnectedAccountDto,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import { AppColors } from "@juntochat/kazm-shared";
import { BiLogoStripe } from "react-icons/bi";
import { FaShopify, FaTiktok } from "react-icons/fa";
import { KazmIcon } from "../icons/KazmIcons";

export function orgConnectedAccountTypeTitle(
  type: OrgConnectedAccountType,
): string {
  const orgConnectedAccountTypeTitleLookup: Record<
    OrgConnectedAccountType,
    string
  > = {
    [OrgConnectedAccountType.SpotifyAccount]: "Spotify",
    [OrgConnectedAccountType.StripeAccount]: "Stripe",
    [OrgConnectedAccountType.ShopifyAccount]: "Shopify",
    [OrgConnectedAccountType.TwitterAccount]: "Twitter",
    [OrgConnectedAccountType.InstagramAccount]: "Instagram",
    [OrgConnectedAccountType.DiscordAccount]: "Discord",
    [OrgConnectedAccountType.TelegramAccount]: "Telegram",
    [OrgConnectedAccountType.TiktokAccount]: "Tiktok",
    [OrgConnectedAccountType.EthereumAccount]: "Ethereum Contract",
    [OrgConnectedAccountType.PolygonAccount]: "Polygon Contract",
    [OrgConnectedAccountType.ImxAccount]: "IMX Contract",
    [OrgConnectedAccountType.BaseAccount]: "Base Contract",
    [OrgConnectedAccountType.AvaxAccount]: "Avalanche Contract",
    [OrgConnectedAccountType.SolanaAccount]: "Solana Contract",
    [OrgConnectedAccountType.AptosAccount]: "Aptos Contract",
  };

  return orgConnectedAccountTypeTitleLookup[type];
}

export function OrgConnectedAccountTypeIcon({
  size = 20,
  color = AppColors.white,
  connectedAccountType,
}: {
  size?: number;
  color?: string;
  connectedAccountType: OrgConnectedAccountType;
}) {
  const orgConnectedAccountTypeIconLookup: Record<
    OrgConnectedAccountType,
    JSX.Element
  > = {
    [OrgConnectedAccountType.SpotifyAccount]: (
      <KazmIcon.Spotify size={size} color={color} />
    ),
    [OrgConnectedAccountType.StripeAccount]: (
      <BiLogoStripe size={size} color={color} />
    ),
    [OrgConnectedAccountType.ShopifyAccount]: (
      <FaShopify size={size} color={color} />
    ),
    [OrgConnectedAccountType.TwitterAccount]: (
      <KazmIcon.Twitter size={size} color={color} />
    ),
    [OrgConnectedAccountType.InstagramAccount]: (
      <KazmIcon.Instagram size={size} color={color} />
    ),
    [OrgConnectedAccountType.DiscordAccount]: (
      <KazmIcon.Discord size={size} color={color} />
    ),
    [OrgConnectedAccountType.TelegramAccount]: (
      <KazmIcon.Telegram size={size} color={color} />
    ),
    [OrgConnectedAccountType.TiktokAccount]: (
      <FaTiktok size={size} color={color} />
    ),
    [OrgConnectedAccountType.EthereumAccount]: (
      <KazmIcon.Ethereum size={size} color={color} />
    ),
    [OrgConnectedAccountType.PolygonAccount]: (
      <KazmIcon.Polygon size={size} color={color} />
    ),
    [OrgConnectedAccountType.ImxAccount]: (
      <KazmIcon.ImmutableX size={size} color={color} />
    ),
    [OrgConnectedAccountType.BaseAccount]: (
      <KazmIcon.Base size={size} color={color} />
    ),
    [OrgConnectedAccountType.AvaxAccount]: (
      <KazmIcon.Avax size={size} color={color} />
    ),
    [OrgConnectedAccountType.SolanaAccount]: (
      <KazmIcon.Solana size={size} color={color} />
    ),
    [OrgConnectedAccountType.AptosAccount]: (
      <KazmIcon.Aptos size={size} color={color} />
    ),
  };

  return orgConnectedAccountTypeIconLookup[connectedAccountType];
}

export function orgConnectedAccountName(account: OrgConnectedAccountDto) {
  const data = account.authData;
  const result = account.result;

  const defaultName = `${orgConnectedAccountTypeTitle(
    account.accountType,
  )} Account (${account.accountId})`;

  switch (account.accountType) {
    case OrgConnectedAccountType.DiscordAccount:
      return result?.discordResult?.name ?? defaultName;
    case OrgConnectedAccountType.TwitterAccount:
      return data?.twitterData?.name ?? defaultName;
    case OrgConnectedAccountType.EthereumAccount:
    case OrgConnectedAccountType.PolygonAccount:
    case OrgConnectedAccountType.ImxAccount:
    case OrgConnectedAccountType.BaseAccount:
    case OrgConnectedAccountType.AvaxAccount:
    case OrgConnectedAccountType.SolanaAccount:
    case OrgConnectedAccountType.AptosAccount:
      return result?.contractResult?.name ?? defaultName;
    case OrgConnectedAccountType.TelegramAccount:
      return data?.telegramData?.username ?? defaultName;
    case OrgConnectedAccountType.ShopifyAccount:
      return data?.shopifyData?.shop ?? defaultName;
    case OrgConnectedAccountType.InstagramAccount:
      return data?.instagramData?.username ?? defaultName;
    case OrgConnectedAccountType.TiktokAccount:
      return (
        data?.tiktokData?.username ??
        data?.tiktokData?.displayName ??
        defaultName
      );
    default:
      return defaultName;
  }
}
