import { UnstyledButton } from "@common/buttons/SimpleButton";
import { MemberConnectedAccounts } from "@common/MemberConnectedAccounts";
import { MemberInfo } from "@juntochat/kazm-shared";

import { useMemberDrawer } from "./MemberDrawer/member_drawer_provider";

import { MemberProfileImage } from "./MemberProfileImage";
import { MemberUsername } from "@/projects/members/table/MemberUsername.tsx";

export function MemberProfile({
  member,
  showTooltip = false,
}: {
  member: MemberInfo;
  showTooltip?: boolean;
}) {
  const { setSelectedMemberId } = useMemberDrawer();

  return (
    <div className="flex">
      <UnstyledButton
        className="flex items-center overflow-hidden"
        onClick={() => setSelectedMemberId(member.memberId)}
      >
        <MemberProfileImage member={member} />
        <MemberUsername member={member} showTooltip={showTooltip} />
      </UnstyledButton>
      <div className="flex gap-[4px]">
        <MemberConnectedAccounts member={member} />
      </div>
    </div>
  );
}
