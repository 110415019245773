import SizedBox from "@/common_components/SizedBox";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { MembershipTextInput } from "@/membership_form/components/inputs/MembershipTextInput";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding";
import {
  useActionOutcomesProvider,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import {
  DefaultFulfillmentButton,
  DefaultVerificationButton,
  LinkFulfillmentButton,
} from "@/modules/actions/outcomes/builders/common/DefaultButtons";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { useGetAllCurrentMemberConnectedAccounts } from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts";
import { BlockchainUtils } from "@/utils/blockchain_utils";
import KazmUtils from "@/utils/utils";
import { KazmIcon } from "@common/icons/KazmIcons";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import {
  BlockchainType,
  TwitterProfilePictureSource,
} from "@juntochat/kazm-shared";
import { ReactNode } from "react";
import { OutcomeBuilderContainer } from "./common/OutcomeBuilderContainer";

export function TwitterProfilePictureOutcomeBuilder() {
  const { definition, outcome } = useOutcomeBuilderProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const { data: connectedAccounts } = useGetAllCurrentMemberConnectedAccounts();
  const { isOutcomeValid, isValidating } = useActionOutcomesProvider();
  const { branding, textSizeMultiplier } = useMembershipBranding();
  const twitterAccount = connectedAccounts?.find(
    (e) => e.accountType === MemberConnectedAccountType.TwitterAccount,
  );

  const id = twitterAccount?.id;
  const isComplete = isOutcomeValid(definition.id);
  const sourceType = definition.twitterProfilePicture?.source;
  const isNFTSource = sourceType === TwitterProfilePictureSource.NFT_COLLECTION;
  const contractAddress = definition.twitterProfilePicture?.contractAddress;
  function onClick() {
    window.open(`https://twitter.com/i/user/${id}`, "_blank");
  }

  return (
    <div className="flex flex-col gap-y-[10px]">
      {isNFTSource ? (
        <Prerequisite title="NFT image">
          {isNFTSource && contractAddress && (
            <LinkFulfillmentButton
              title={`Collection ${KazmUtils.shortenWalletAddress(contractAddress)}`}
              url={BlockchainUtils.getErc721TokenPurchaseUrl({
                blockchain: BlockchainType.ETHEREUM,
                address: contractAddress,
              })}
            />
          )}
          <MembershipTextInput
            style={{ width: 125 }}
            defaultValue={outcome?.twitterProfilePicture?.tokenId}
            label="Token ID"
            onChangeText={(tokenId) =>
              verifyOutcome({
                outcome: {
                  twitterProfilePicture: {
                    tokenId: tokenId,
                  },
                },
                debounce: true,
                optimistic: false,
              })
            }
          />
        </Prerequisite>
      ) : (
        <Prerequisite title="Image">
          <AccessibleImage
            width={30}
            height={30}
            src={definition.twitterProfilePicture?.link}
            alt="profile picture"
          />
          <a
            download
            href={definition.twitterProfilePicture?.link}
            className="flex items-center justify-center gap-x-[5px] whitespace-nowrap rounded-[7px] px-[7px] py-[5px]"
            style={{
              fontSize: 14 * textSizeMultiplier,
              color: branding?.buttonTextColor,
              backgroundColor: branding?.buttonColor,
            }}
          >
            Download
          </a>
        </Prerequisite>
      )}
      <OutcomeBuilderContainer title={"Include image as profile picture"}>
        {id && !isComplete && !isValidating(definition.id) && (
          <>
            <DefaultFulfillmentButton onClick={onClick}>
              <KazmIcon.Twitter size={20} />
              Profile
            </DefaultFulfillmentButton>
            <SizedBox width={10} />
          </>
        )}
        <DefaultVerificationButton />
      </OutcomeBuilderContainer>
    </div>
  );
}

function Prerequisite({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  const { branding, textSizeMultiplier } = useMembershipBranding();

  return (
    <div className="flex w-full items-center justify-between space-x-[10px]">
      <div
        style={{
          color: branding.textColor,
          fontSize: 15.3 * textSizeMultiplier,
        }}
      >
        {title}
      </div>
      <div className="flex items-center justify-between space-x-[10px]">
        {children}
      </div>
    </div>
  );
}
