import { ExternalLink } from "@common/ExternalLink";
import {
  ConnectedAccountDto,
  MemberConnectedAccountType,
} from "@juntochat/internal-api";
import { ReactNode } from "react";

type ConnectedAccountLinkProps = {
  className?: string;
  account: ConnectedAccountDto;
  children: ReactNode;
};

export function ConnectedAccountLink(props: ConnectedAccountLinkProps) {
  const { account, ...linkProps } = props;

  const profileUrl = getAccountProfileUrl(account);

  if (profileUrl) {
    return <ExternalLink href={profileUrl} {...linkProps} />;
  } else {
    return <div {...linkProps} />;
  }
}

// Some account types may not support linking to a profile.
export function getAccountProfileUrl(account: ConnectedAccountDto) {
  switch (account.accountType) {
    case MemberConnectedAccountType.TwitterAccount:
      return `https://twitter.com/i/user/${account.id}`;
    case MemberConnectedAccountType.InstagramAccount: {
      const instagramUsername = account.username;
      return instagramUsername
        ? `https://instagram.com/${instagramUsername}`
        : undefined;
    }
    case MemberConnectedAccountType.EthereumWallet:
      return `https://etherscan.io/address/${account.id}`;
    case MemberConnectedAccountType.AptosWallet:
      return `https://aptoscan.com/account/${account.id}`;
    case MemberConnectedAccountType.DiscordAccount:
      return `https://discordapp.com/users/${account.id}`;
    case MemberConnectedAccountType.SpotifyAccount:
      return `https://open.spotify.com/user/${account.id}`;
    case MemberConnectedAccountType.UnstoppableDomain:
      return `https://ud.me/${account.id}`;
    // TODO: Link to member profile info (member drawer) by ID?
    case MemberConnectedAccountType.TelegramAccount:
      return `https://t.me/${account.name}`;
    case MemberConnectedAccountType.TikTokAccount:
      return account?.username
        ? `https://tiktok.com/@${account.username}`
        : undefined;
    case MemberConnectedAccountType.FacebookAccount:
    case MemberConnectedAccountType.Email:
    case MemberConnectedAccountType.KazmMemberId:
    default:
      return undefined;
  }
}
