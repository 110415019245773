import { ReactElement } from "react";

import { ConnectedAccountTypeIcon } from "@common/data_source/ConnectedAccountTypeIcon";
import { DataSourceIcon } from "@common/data_source/DataSourceIcon";
import { Tooltip } from "@common/overlays/tooltip/Tooltip";
import {
  AppColors,
  AttributeValueType,
  MemberInfo,
  PropertyDefinition,
  PropertyType,
  userConnectedAccountTypeFromJSON,
} from "@juntochat/kazm-shared";
import {
  useCurrentOrgDataSources,
  useGetSingleMember,
  useGetSingleOrgPropertyDefinitionMetadataV2,
  useListMemberTags,
} from "@utils/hooks/use_cache";
import { readableNumber } from "@utils/text_utils";
import KazmUtils from "@utils/utils";

import { TagList } from "../tags/TagList";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";
import { MemberUsername } from "@/projects/members/table/MemberUsername.tsx";
import { useMemberDrawer } from "@/projects/members/drawers/MemberDrawer/member_drawer_provider.tsx";
import { UnstyledButton } from "@common/buttons/SimpleButton.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";

export type PropertyValueProps = {
  // If member is provided, additional UI can be shown (depending on the property type).
  member?: MemberInfo;
  propertyDefinition: PropertyDefinition | undefined;
  value: number | string | string[] | undefined;
  settings?: PropertyValueSettings;
};

export type PropertyValueSettings = {
  minifiedView?: boolean;
  maxTagsToShow?: number;
  showTagsActionButton?: boolean;
  fallback?: PropertyFallback;
};

type PropertyFallback = null | string | ReactElement;

export function PropertyValue(props: PropertyValueProps) {
  const { propertyDefinition, value, member, settings } = props;

  if (!propertyDefinition) {
    return renderFallback(props.settings?.fallback);
  }

  if (value === undefined) {
    return renderFallback(props.settings?.fallback);
  }

  const stringArray = value instanceof Array ? value : [String(value)];
  const singleValue = value instanceof Array ? value[0] : value;

  switch (propertyDefinition.propertyType) {
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER:
      return (
        <PropertyMembershipMember
          membershipId={String(singleValue)}
          propertyDefinition={propertyDefinition}
        />
      );
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_ID:
      return (
        <PropertyMembershipTierId
          tierId={String(singleValue)}
          propertyDefinition={propertyDefinition}
        />
      );
    case PropertyType.PROPERTY_ACCOUNT_DATA_SOURCE_IDS:
      return <PropertyDataSources sourceId={String(value)} />;
    case PropertyType.PROPERTY_ACCOUNT_TYPES:
      return (
        <PropertyAccountTypes
          propertyDefinition={propertyDefinition}
          rawAccountType={String(singleValue)}
          settings={settings}
        />
      );
    case PropertyType.PROPERTY_ACCOUNT_TAG:
      return (
        <PropertyAccountTags
          member={member}
          tagIds={stringArray}
          settings={settings}
        />
      );
    case PropertyType.PROPERTY_MEMBER_REFERRER_ID:
      return <PropertyReferrerId referrerMemberId={String(singleValue)} />;
  }
  switch (propertyDefinition.valueType) {
    case AttributeValueType.ATTRIBUTE_VALUE_TIMESTAMP:
      return <PropertyDefault value={KazmUtils.formatDate(value)} />;
    case AttributeValueType.ATTRIBUTE_VALUE_NUMERIC:
      return <PropertyDefault value={readableNumber(+value)} />;
    default:
      return <PropertyDefault value={value} />;
  }
}

function PropertyDefault(props: { value: string | number | string[] }) {
  function renderCell(value: string | number | string[]) {
    return (
      <span
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {String(value || "-")}
      </span>
    );
  }

  const maxLength = 100;
  if (String(props.value).length > maxLength) {
    return (
      <div className="overflow-hidden">
        <Tooltip
          contentStyle={{ color: AppColors.white }}
          tooltipContent={String(props.value)}
        >
          {renderCell(props.value)}
        </Tooltip>
      </div>
    );
  }

  return renderCell(props.value);
}

function PropertyMembershipMember(props: {
  membershipId: string;
  propertyDefinition: PropertyDefinition;
}) {
  const { propertyMetadata } = useGetSingleOrgPropertyDefinitionMetadataV2(
    props.propertyDefinition.id,
  );

  const membership = propertyMetadata?.memberships?.find(
    (membership) => membership.id === props.membershipId,
  );

  if (!membership) {
    return null;
  }

  return <span>{membership.privateLabel}</span>;
}

function PropertyMembershipTierId(props: {
  tierId: string;
  propertyDefinition: PropertyDefinition;
}) {
  const { propertyMetadata } = useGetSingleOrgPropertyDefinitionMetadataV2(
    props.propertyDefinition.id,
  );
  const tier = propertyMetadata?.tiers?.find(
    (tier) => tier.id === props.tierId,
  );

  if (!tier) {
    return null;
  }

  return <span>{tier.name}</span>;
}

function PropertyReferrerId(props: { referrerMemberId: string }) {
  const { referrerMemberId } = props;
  const hasReferrer = Boolean(referrerMemberId);

  const { setSelectedMemberId } = useMemberDrawer();
  const { data, isLoading } = useGetSingleMember({
    memberId: referrerMemberId,
    shouldFetch: hasReferrer,
  });

  if (!hasReferrer) {
    return null;
  }

  if (isLoading) {
    return <Shimmer height={20} width={50} />;
  }

  if (!data) {
    // Referrer member doesn't exist anymore (was removed).
    return null;
  }

  return (
    <UnstyledButton
      className="flex items-center"
      onClick={() => setSelectedMemberId(referrerMemberId)}
    >
      <MemberUsername member={data} showTooltip />
    </UnstyledButton>
  );
}

function PropertyAccountTypes(props: {
  rawAccountType: string;
  settings?: PropertyValueSettings;
  propertyDefinition: PropertyDefinition;
}) {
  const { rawAccountType, settings, propertyDefinition } = props;
  const { propertyMetadata } = useGetSingleOrgPropertyDefinitionMetadataV2(
    propertyDefinition.id,
  );
  const connectedAccountType = userConnectedAccountTypeFromJSON(rawAccountType);
  const targetAccountDefinition = propertyMetadata?.accountTypeDefinitions.find(
    (accountTypeDefinition) =>
      accountTypeDefinition.accountType === connectedAccountType,
  );
  return (
    <div className="flex items-center">
      <ConnectedAccountTypeIcon
        color={AppColors.white}
        accountType={connectedAccountType}
      />
      {!settings?.minifiedView && (
        <span className="ml-2">{targetAccountDefinition?.title ?? "-"}</span>
      )}
    </div>
  );
}

function PropertyAccountTags({
  member,
  tagIds,
  settings,
}: {
  member?: MemberInfo;
  tagIds: string[];
  settings: PropertyValueSettings | undefined;
}) {
  const { maxTagsToShow, showTagsActionButton = true } = settings ?? {};
  const orgId = useCurrentOrgId();
  const tags = useListMemberTags({
    orgId,
  });
  const tagsToShow =
    tags.data?.data.filter((tag) => tagIds.includes(tag.id)) ?? [];

  return (
    <TagList
      showActionButton={showTagsActionButton}
      maxTagsToShow={maxTagsToShow}
      tags={tagsToShow}
      member={member}
    />
  );
}

function PropertyDataSources(props: { sourceId: string }) {
  const { sourcesLookupById } = useCurrentOrgDataSources();
  const source = sourcesLookupById.get(props.sourceId);

  if (!source) {
    return <div>-</div>;
  }

  return (
    <div className="flex space-x-[10px]">
      <DataSourceIcon source={source} />
      <div>{source.name}</div>
    </div>
  );
}

function renderFallback(fallback?: PropertyFallback) {
  if (typeof fallback === "string") {
    return <>{fallback}</>;
  }
  return fallback ?? null;
}
