import { useParams } from "react-router-dom";
import { CenterModal } from "@common/overlays/modals/CenterModal";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import useSWR from "swr";
import {
  PointOfSaleRewardControllerGetRequest,
  PointOfSaleRewardControllerRedeemRequest,
  PointOfSaleRewardDto,
} from "@juntochat/internal-api";
import { AccessibleImage } from "@common/images/AccessibleImage";
import { FullScreenLoading } from "@common/loading/LoadingScreen";
import { KazmIcon } from "@common/icons/KazmIcons";
import { useState } from "react";
import { ToastUtils } from "@utils/toast_utils";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { NotFoundPage } from "@/common_components/error/NotFoundPage";
import { RoundedButton } from "../components/RoundedButton";
import { RichTextView } from "@common/editors/RichTextEditor/RichTextEditor.tsx";

export function PointOfSaleRewardPage() {
  const params = useParams() as {
    memberId: string;
    posRewardId: string;
    orgId: string;
    membershipId: string;
  };

  const controller = useRedeemController(params);
  const { data, error } = useGetPosReward(params);

  if (error) {
    return <NotFoundPage title={"Reward not found"} />;
  } else if (!data) {
    return <FullScreenLoading />;
  }

  return (
    <div className="h-[100vh] bg-dark-base">
      <CenterModal
        isOpen={true}
        style={{
          content: {
            width: "400px",
            overflow: "hidden",
            position: "relative",
          },
        }}
      >
        {controller.isRedeemed && <SuccessOverlay reward={data} />}
        <RedeemContent reward={data} controller={controller} />
      </CenterModal>
    </div>
  );
}

function SuccessOverlay(props: { reward: PointOfSaleRewardDto }) {
  const { branding } = useMembershipBranding();

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-dark-base">
      <div className="flex items-center gap-x-[20px]">
        <KazmIcon.CheckCircle size={60} color={branding?.buttonColor} />
        <div className="flex flex-col">
          <span className="text-[16px] font-semibold">Redeemed</span>
          <span className="text-[12px] text-gray-300">
            {props.reward.claimant.name}
          </span>
        </div>
      </div>
    </div>
  );
}

function RedeemContent(props: {
  controller: RedeemController;
  reward: PointOfSaleRewardDto;
}) {
  const { controller, reward } = props;
  const { branding } = useMembershipBranding();

  return (
    <div className="flex flex-col gap-y-[10px]">
      <div className="flex gap-x-[10px]">
        {reward.claimant.imageUrl && (
          <AccessibleImage
            src={reward.claimant.imageUrl}
            className="rounded-full "
          />
        )}
        <span className="text-[14px] font-bold">{reward.claimant.name}</span>
      </div>
      {reward.imageUrl && (
        <AccessibleImage
          className="h-full max-h-[50vh] w-full rounded object-cover"
          src={reward.imageUrl}
        />
      )}
      <div>
        <span className="text-[16px] font-semibold">{reward.title}</span>
        <div className="flex items-center gap-x-[5px] ">
          <span className="text-[14px] text-gray-300">
            {Math.abs(reward.points)} points
          </span>
          {reward.claimant.hasEnoughPoints && <KazmIcon.Check size={12} />}
        </div>
      </div>
      {reward.description && (
        <RichTextView
          textEditorClassName="z-[-1]"
          textColor={branding.textColor}
          value={JSON.parse(reward.description)}
        />
      )}
      <RoundedButton
        disabled={!reward.claimant.hasEnoughPoints}
        onClick={controller.redeem}
      >
        Mark redeemed
      </RoundedButton>
    </div>
  );
}

type RedeemController = {
  isRedeemed: boolean;
  redeem: () => Promise<void>;
};

function useRedeemController(
  request: PointOfSaleRewardControllerRedeemRequest,
): RedeemController {
  const cloudFunctions = useCloudFunctionsService();
  const [isRedeemed, setIsRedeemed] = useState(false);

  async function redeem() {
    try {
      await cloudFunctions.membershipPointOfSaleApi.pointOfSaleRewardControllerRedeem(
        request,
      );
      setIsRedeemed(true);
    } catch (e) {
      ToastUtils.showErrorToast(e, {
        includeSupportEmail: true,
        defaultMessage: "Something went wrong",
      });
    }
  }

  return {
    isRedeemed,
    redeem,
  };
}

function useGetPosReward(request: PointOfSaleRewardControllerGetRequest) {
  const cloudFunctions = useCloudFunctionsService();

  return useSWR(JSON.stringify(request), () =>
    cloudFunctions.membershipPointOfSaleApi.pointOfSaleRewardControllerGet(
      request,
    ),
  );
}

export function getPosRewardUrl(options: {
  membershipId: string;
  posRewardId: string;
  memberId: string;
  orgId: string;
}) {
  const { orgId, membershipId, memberId, posRewardId } = options;
  return `orgs/${orgId}/memberships/${membershipId}/members/${memberId}/point-of-sale-rewards/${posRewardId}`;
}
