/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { ActionTypeFromJSON, ActionTypeToJSON, } from './ActionType.js';
import { AptosOwnNftDefinitionDtoFromJSON, AptosOwnNftDefinitionDtoToJSON, } from './AptosOwnNftDefinitionDto.js';
import { AptosOwnTokenDefinitionDtoFromJSON, AptosOwnTokenDefinitionDtoToJSON, } from './AptosOwnTokenDefinitionDto.js';
import { CheckInDefinitionDtoFromJSON, CheckInDefinitionDtoToJSON, } from './CheckInDefinitionDto.js';
import { DiscordReactionDefinitionDtoFromJSON, DiscordReactionDefinitionDtoToJSON, } from './DiscordReactionDefinitionDto.js';
import { DiscordRoleRequirementDefinitionDtoFromJSON, DiscordRoleRequirementDefinitionDtoToJSON, } from './DiscordRoleRequirementDefinitionDto.js';
import { DiscordSendMessageDefinitionDtoFromJSON, DiscordSendMessageDefinitionDtoToJSON, } from './DiscordSendMessageDefinitionDto.js';
import { DiscordServerJoinDefinitionDtoFromJSON, DiscordServerJoinDefinitionDtoToJSON, } from './DiscordServerJoinDefinitionDto.js';
import { EthereumMinimumBalanceDefinitionDtoFromJSON, EthereumMinimumBalanceDefinitionDtoToJSON, } from './EthereumMinimumBalanceDefinitionDto.js';
import { EthereumOwnNftDefinitionDtoFromJSON, EthereumOwnNftDefinitionDtoToJSON, } from './EthereumOwnNftDefinitionDto.js';
import { EthereumOwnPoapDefinitionDtoFromJSON, EthereumOwnPoapDefinitionDtoToJSON, } from './EthereumOwnPoapDefinitionDto.js';
import { EthereumOwnTokenDefinitionDtoFromJSON, EthereumOwnTokenDefinitionDtoToJSON, } from './EthereumOwnTokenDefinitionDto.js';
import { InstagramFollowDefinitionDtoFromJSON, InstagramFollowDefinitionDtoToJSON, } from './InstagramFollowDefinitionDto.js';
import { InstagramMediaDefinitionDtoFromJSON, InstagramMediaDefinitionDtoToJSON, } from './InstagramMediaDefinitionDto.js';
import { KazmApiEventDefinitionDtoFromJSON, KazmApiEventDefinitionDtoToJSON, } from './KazmApiEventDefinitionDto.js';
import { KazmBadgeEarnedDefinitionDtoFromJSON, KazmBadgeEarnedDefinitionDtoToJSON, } from './KazmBadgeEarnedDefinitionDto.js';
import { KazmFormDefinitionDtoFromJSON, KazmFormDefinitionDtoToJSON, } from './KazmFormDefinitionDto.js';
import { KazmMemberTagDefinitionDtoFromJSON, KazmMemberTagDefinitionDtoToJSON, } from './KazmMemberTagDefinitionDto.js';
import { KazmMembershipDefinitionDtoFromJSON, KazmMembershipDefinitionDtoToJSON, } from './KazmMembershipDefinitionDto.js';
import { KazmMembershipTierDefinitionDtoFromJSON, KazmMembershipTierDefinitionDtoToJSON, } from './KazmMembershipTierDefinitionDto.js';
import { KazmQuestCompletionDefinitionDtoFromJSON, KazmQuestCompletionDefinitionDtoToJSON, } from './KazmQuestCompletionDefinitionDto.js';
import { LocationDataDefinitionDtoFromJSON, LocationDataDefinitionDtoToJSON, } from './LocationDataDefinitionDto.js';
import { MultipleChoiceDefinitionDtoFromJSON, MultipleChoiceDefinitionDtoToJSON, } from './MultipleChoiceDefinitionDto.js';
import { PhoneNumberDefinitionDtoFromJSON, PhoneNumberDefinitionDtoToJSON, } from './PhoneNumberDefinitionDto.js';
import { PointsThresholdDefinitionDtoFromJSON, PointsThresholdDefinitionDtoToJSON, } from './PointsThresholdDefinitionDto.js';
import { ReferralDefinitionDtoFromJSON, ReferralDefinitionDtoToJSON, } from './ReferralDefinitionDto.js';
import { SolanaOwnTokenDefinitionDtoFromJSON, SolanaOwnTokenDefinitionDtoToJSON, } from './SolanaOwnTokenDefinitionDto.js';
import { SpotifyFollowDefinitionDtoFromJSON, SpotifyFollowDefinitionDtoToJSON, } from './SpotifyFollowDefinitionDto.js';
import { SpotifyListenDefinitionDtoFromJSON, SpotifyListenDefinitionDtoToJSON, } from './SpotifyListenDefinitionDto.js';
import { StripeSubscriptionVerifiedDefinitionDtoFromJSON, StripeSubscriptionVerifiedDefinitionDtoToJSON, } from './StripeSubscriptionVerifiedDefinitionDto.js';
import { TelegramJoinChannelDefinitionDtoFromJSON, TelegramJoinChannelDefinitionDtoToJSON, } from './TelegramJoinChannelDefinitionDto.js';
import { TelegramJoinGroupDefinitionDtoFromJSON, TelegramJoinGroupDefinitionDtoToJSON, } from './TelegramJoinGroupDefinitionDto.js';
import { TelegramSendMessageDefinitionDtoFromJSON, TelegramSendMessageDefinitionDtoToJSON, } from './TelegramSendMessageDefinitionDto.js';
import { TermsOfServiceAgreementDefinitionDtoFromJSON, TermsOfServiceAgreementDefinitionDtoToJSON, } from './TermsOfServiceAgreementDefinitionDto.js';
import { TextInputDefinitionDtoFromJSON, TextInputDefinitionDtoToJSON, } from './TextInputDefinitionDto.js';
import { TikTokFollowDefinitionDtoFromJSON, TikTokFollowDefinitionDtoToJSON, } from './TikTokFollowDefinitionDto.js';
import { TikTokMediaDefinitionDtoFromJSON, TikTokMediaDefinitionDtoToJSON, } from './TikTokMediaDefinitionDto.js';
import { TwitterBioSubstringDefinitionDtoFromJSON, TwitterBioSubstringDefinitionDtoToJSON, } from './TwitterBioSubstringDefinitionDto.js';
import { TwitterFollowDefinitionDtoFromJSON, TwitterFollowDefinitionDtoToJSON, } from './TwitterFollowDefinitionDto.js';
import { TwitterLikeRetweetDefinitionDtoFromJSON, TwitterLikeRetweetDefinitionDtoToJSON, } from './TwitterLikeRetweetDefinitionDto.js';
import { TwitterMentionDefinitionDtoFromJSON, TwitterMentionDefinitionDtoToJSON, } from './TwitterMentionDefinitionDto.js';
import { TwitterNameSubstringDefinitionDtoFromJSON, TwitterNameSubstringDefinitionDtoToJSON, } from './TwitterNameSubstringDefinitionDto.js';
import { TwitterProfilePictureDefintionDtoFromJSON, TwitterProfilePictureDefintionDtoToJSON, } from './TwitterProfilePictureDefintionDto.js';
import { TwitterReactDefinitionDtoFromJSON, TwitterReactDefinitionDtoToJSON, } from './TwitterReactDefinitionDto.js';
import { VisitLinkDefinitionDtoFromJSON, VisitLinkDefinitionDtoToJSON, } from './VisitLinkDefinitionDto.js';
import { WalletProvideLiquidityDtoFromJSON, WalletProvideLiquidityDtoToJSON, } from './WalletProvideLiquidityDto.js';
import { YouTubeSubscribeDefinitionDtoFromJSON, YouTubeSubscribeDefinitionDtoToJSON, } from './YouTubeSubscribeDefinitionDto.js';
/**
 * Check if a given object implements the ActionDefinitionDto interface.
 */
export function instanceOfActionDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "updatedDate" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "isRequired" in value;
    return isInstance;
}
export function ActionDefinitionDtoFromJSON(json) {
    return ActionDefinitionDtoFromJSONTyped(json, false);
}
export function ActionDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': ActionTypeFromJSON(json['type']),
        'discordHasDiscordRole': !exists(json, 'discordHasDiscordRole') ? undefined : DiscordRoleRequirementDefinitionDtoFromJSON(json['discordHasDiscordRole']),
        'discordReaction': !exists(json, 'discordReaction') ? undefined : DiscordReactionDefinitionDtoFromJSON(json['discordReaction']),
        'discordSendMessage': !exists(json, 'discordSendMessage') ? undefined : DiscordSendMessageDefinitionDtoFromJSON(json['discordSendMessage']),
        'discordServerJoin': !exists(json, 'discordServerJoin') ? undefined : DiscordServerJoinDefinitionDtoFromJSON(json['discordServerJoin']),
        'ethereumMinimumBalance': !exists(json, 'ethereumMinimumBalance') ? undefined : EthereumMinimumBalanceDefinitionDtoFromJSON(json['ethereumMinimumBalance']),
        'ethereumOwnNft': !exists(json, 'ethereumOwnNft') ? undefined : EthereumOwnNftDefinitionDtoFromJSON(json['ethereumOwnNft']),
        'ethereumOwnPoap': !exists(json, 'ethereumOwnPoap') ? undefined : EthereumOwnPoapDefinitionDtoFromJSON(json['ethereumOwnPoap']),
        'ethereumOwnToken': !exists(json, 'ethereumOwnToken') ? undefined : EthereumOwnTokenDefinitionDtoFromJSON(json['ethereumOwnToken']),
        'solanaOwnToken': !exists(json, 'solanaOwnToken') ? undefined : SolanaOwnTokenDefinitionDtoFromJSON(json['solanaOwnToken']),
        'aptosOwnToken': !exists(json, 'aptosOwnToken') ? undefined : AptosOwnTokenDefinitionDtoFromJSON(json['aptosOwnToken']),
        'aptosOwnNft': !exists(json, 'aptosOwnNft') ? undefined : AptosOwnNftDefinitionDtoFromJSON(json['aptosOwnNft']),
        'walletProvideLiquidity': !exists(json, 'walletProvideLiquidity') ? undefined : WalletProvideLiquidityDtoFromJSON(json['walletProvideLiquidity']),
        'instagramFollow': !exists(json, 'instagramFollow') ? undefined : InstagramFollowDefinitionDtoFromJSON(json['instagramFollow']),
        'instagramMedia': !exists(json, 'instagramMedia') ? undefined : InstagramMediaDefinitionDtoFromJSON(json['instagramMedia']),
        'kazmApiEvent': !exists(json, 'kazmApiEvent') ? undefined : KazmApiEventDefinitionDtoFromJSON(json['kazmApiEvent']),
        'kazmForm': !exists(json, 'kazmForm') ? undefined : KazmFormDefinitionDtoFromJSON(json['kazmForm']),
        'kazmMembership': !exists(json, 'kazmMembership') ? undefined : KazmMembershipDefinitionDtoFromJSON(json['kazmMembership']),
        'locationData': !exists(json, 'locationData') ? undefined : LocationDataDefinitionDtoFromJSON(json['locationData']),
        'multipleChoice': !exists(json, 'multipleChoice') ? undefined : MultipleChoiceDefinitionDtoFromJSON(json['multipleChoice']),
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberDefinitionDtoFromJSON(json['phoneNumber']),
        'proofOfPresence': !exists(json, 'proofOfPresence') ? undefined : json['proofOfPresence'],
        'questPointsThreshold': !exists(json, 'questPointsThreshold') ? undefined : PointsThresholdDefinitionDtoFromJSON(json['questPointsThreshold']),
        'reCaptchaV2': !exists(json, 'reCaptchaV2') ? undefined : json['reCaptchaV2'],
        'referral': !exists(json, 'referral') ? undefined : ReferralDefinitionDtoFromJSON(json['referral']),
        'spotifyConnection': !exists(json, 'spotifyConnection') ? undefined : json['spotifyConnection'],
        'spotifyFollow': !exists(json, 'spotifyFollow') ? undefined : SpotifyFollowDefinitionDtoFromJSON(json['spotifyFollow']),
        'spotifyListen': !exists(json, 'spotifyListen') ? undefined : SpotifyListenDefinitionDtoFromJSON(json['spotifyListen']),
        'stripeSubscriptionVerified': !exists(json, 'stripeSubscriptionVerified') ? undefined : StripeSubscriptionVerifiedDefinitionDtoFromJSON(json['stripeSubscriptionVerified']),
        'telegramJoinGroup': !exists(json, 'telegramJoinGroup') ? undefined : TelegramJoinGroupDefinitionDtoFromJSON(json['telegramJoinGroup']),
        'telegramJoinChannel': !exists(json, 'telegramJoinChannel') ? undefined : TelegramJoinChannelDefinitionDtoFromJSON(json['telegramJoinChannel']),
        'telegramSendMessage': !exists(json, 'telegramSendMessage') ? undefined : TelegramSendMessageDefinitionDtoFromJSON(json['telegramSendMessage']),
        'termsOfServiceAgreement': !exists(json, 'termsOfServiceAgreement') ? undefined : TermsOfServiceAgreementDefinitionDtoFromJSON(json['termsOfServiceAgreement']),
        'textInput': !exists(json, 'textInput') ? undefined : TextInputDefinitionDtoFromJSON(json['textInput']),
        'tiktokFollow': !exists(json, 'tiktokFollow') ? undefined : TikTokFollowDefinitionDtoFromJSON(json['tiktokFollow']),
        'tiktokMedia': !exists(json, 'tiktokMedia') ? undefined : TikTokMediaDefinitionDtoFromJSON(json['tiktokMedia']),
        'twitterFollow': !exists(json, 'twitterFollow') ? undefined : TwitterFollowDefinitionDtoFromJSON(json['twitterFollow']),
        'twitterLikeRetweet': !exists(json, 'twitterLikeRetweet') ? undefined : TwitterLikeRetweetDefinitionDtoFromJSON(json['twitterLikeRetweet']),
        'twitterMention': !exists(json, 'twitterMention') ? undefined : TwitterMentionDefinitionDtoFromJSON(json['twitterMention']),
        'twitterReact': !exists(json, 'twitterReact') ? undefined : TwitterReactDefinitionDtoFromJSON(json['twitterReact']),
        'twitterNameSubstring': !exists(json, 'twitterNameSubstring') ? undefined : TwitterNameSubstringDefinitionDtoFromJSON(json['twitterNameSubstring']),
        'twitterBioSubstring': !exists(json, 'twitterBioSubstring') ? undefined : TwitterBioSubstringDefinitionDtoFromJSON(json['twitterBioSubstring']),
        'twitterProfilePicture': !exists(json, 'twitterProfilePicture') ? undefined : TwitterProfilePictureDefintionDtoFromJSON(json['twitterProfilePicture']),
        'uploadImage': !exists(json, 'uploadImage') ? undefined : json['uploadImage'],
        'urlInput': !exists(json, 'urlInput') ? undefined : json['urlInput'],
        'visitLink': !exists(json, 'visitLink') ? undefined : VisitLinkDefinitionDtoFromJSON(json['visitLink']),
        'kazmMembershipTier': !exists(json, 'kazmMembershipTier') ? undefined : KazmMembershipTierDefinitionDtoFromJSON(json['kazmMembershipTier']),
        'kazmQuestCompletion': !exists(json, 'kazmQuestCompletion') ? undefined : KazmQuestCompletionDefinitionDtoFromJSON(json['kazmQuestCompletion']),
        'kazmBadgeEarned': !exists(json, 'kazmBadgeEarned') ? undefined : KazmBadgeEarnedDefinitionDtoFromJSON(json['kazmBadgeEarned']),
        'kazmMemberTag': !exists(json, 'kazmMemberTag') ? undefined : KazmMemberTagDefinitionDtoFromJSON(json['kazmMemberTag']),
        'youtubeSubscribe': !exists(json, 'youtubeSubscribe') ? undefined : YouTubeSubscribeDefinitionDtoFromJSON(json['youtubeSubscribe']),
        'checkIn': !exists(json, 'checkIn') ? undefined : CheckInDefinitionDtoFromJSON(json['checkIn']),
        'id': json['id'],
        'updatedDate': json['updatedDate'],
        'createdDate': json['createdDate'],
        'isRequired': json['isRequired'],
    };
}
export function ActionDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': ActionTypeToJSON(value.type),
        'discordHasDiscordRole': DiscordRoleRequirementDefinitionDtoToJSON(value.discordHasDiscordRole),
        'discordReaction': DiscordReactionDefinitionDtoToJSON(value.discordReaction),
        'discordSendMessage': DiscordSendMessageDefinitionDtoToJSON(value.discordSendMessage),
        'discordServerJoin': DiscordServerJoinDefinitionDtoToJSON(value.discordServerJoin),
        'ethereumMinimumBalance': EthereumMinimumBalanceDefinitionDtoToJSON(value.ethereumMinimumBalance),
        'ethereumOwnNft': EthereumOwnNftDefinitionDtoToJSON(value.ethereumOwnNft),
        'ethereumOwnPoap': EthereumOwnPoapDefinitionDtoToJSON(value.ethereumOwnPoap),
        'ethereumOwnToken': EthereumOwnTokenDefinitionDtoToJSON(value.ethereumOwnToken),
        'solanaOwnToken': SolanaOwnTokenDefinitionDtoToJSON(value.solanaOwnToken),
        'aptosOwnToken': AptosOwnTokenDefinitionDtoToJSON(value.aptosOwnToken),
        'aptosOwnNft': AptosOwnNftDefinitionDtoToJSON(value.aptosOwnNft),
        'walletProvideLiquidity': WalletProvideLiquidityDtoToJSON(value.walletProvideLiquidity),
        'instagramFollow': InstagramFollowDefinitionDtoToJSON(value.instagramFollow),
        'instagramMedia': InstagramMediaDefinitionDtoToJSON(value.instagramMedia),
        'kazmApiEvent': KazmApiEventDefinitionDtoToJSON(value.kazmApiEvent),
        'kazmForm': KazmFormDefinitionDtoToJSON(value.kazmForm),
        'kazmMembership': KazmMembershipDefinitionDtoToJSON(value.kazmMembership),
        'locationData': LocationDataDefinitionDtoToJSON(value.locationData),
        'multipleChoice': MultipleChoiceDefinitionDtoToJSON(value.multipleChoice),
        'phoneNumber': PhoneNumberDefinitionDtoToJSON(value.phoneNumber),
        'proofOfPresence': value.proofOfPresence,
        'questPointsThreshold': PointsThresholdDefinitionDtoToJSON(value.questPointsThreshold),
        'reCaptchaV2': value.reCaptchaV2,
        'referral': ReferralDefinitionDtoToJSON(value.referral),
        'spotifyConnection': value.spotifyConnection,
        'spotifyFollow': SpotifyFollowDefinitionDtoToJSON(value.spotifyFollow),
        'spotifyListen': SpotifyListenDefinitionDtoToJSON(value.spotifyListen),
        'stripeSubscriptionVerified': StripeSubscriptionVerifiedDefinitionDtoToJSON(value.stripeSubscriptionVerified),
        'telegramJoinGroup': TelegramJoinGroupDefinitionDtoToJSON(value.telegramJoinGroup),
        'telegramJoinChannel': TelegramJoinChannelDefinitionDtoToJSON(value.telegramJoinChannel),
        'telegramSendMessage': TelegramSendMessageDefinitionDtoToJSON(value.telegramSendMessage),
        'termsOfServiceAgreement': TermsOfServiceAgreementDefinitionDtoToJSON(value.termsOfServiceAgreement),
        'textInput': TextInputDefinitionDtoToJSON(value.textInput),
        'tiktokFollow': TikTokFollowDefinitionDtoToJSON(value.tiktokFollow),
        'tiktokMedia': TikTokMediaDefinitionDtoToJSON(value.tiktokMedia),
        'twitterFollow': TwitterFollowDefinitionDtoToJSON(value.twitterFollow),
        'twitterLikeRetweet': TwitterLikeRetweetDefinitionDtoToJSON(value.twitterLikeRetweet),
        'twitterMention': TwitterMentionDefinitionDtoToJSON(value.twitterMention),
        'twitterReact': TwitterReactDefinitionDtoToJSON(value.twitterReact),
        'twitterNameSubstring': TwitterNameSubstringDefinitionDtoToJSON(value.twitterNameSubstring),
        'twitterBioSubstring': TwitterBioSubstringDefinitionDtoToJSON(value.twitterBioSubstring),
        'twitterProfilePicture': TwitterProfilePictureDefintionDtoToJSON(value.twitterProfilePicture),
        'uploadImage': value.uploadImage,
        'urlInput': value.urlInput,
        'visitLink': VisitLinkDefinitionDtoToJSON(value.visitLink),
        'kazmMembershipTier': KazmMembershipTierDefinitionDtoToJSON(value.kazmMembershipTier),
        'kazmQuestCompletion': KazmQuestCompletionDefinitionDtoToJSON(value.kazmQuestCompletion),
        'kazmBadgeEarned': KazmBadgeEarnedDefinitionDtoToJSON(value.kazmBadgeEarned),
        'kazmMemberTag': KazmMemberTagDefinitionDtoToJSON(value.kazmMemberTag),
        'youtubeSubscribe': YouTubeSubscribeDefinitionDtoToJSON(value.youtubeSubscribe),
        'checkIn': CheckInDefinitionDtoToJSON(value.checkIn),
        'id': value.id,
        'updatedDate': value.updatedDate,
        'createdDate': value.createdDate,
        'isRequired': value.isRequired,
    };
}
