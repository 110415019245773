import { MemberConnectedAccountType } from "@juntochat/internal-api";

type ConnectedAccountTypeNameProps = {
  accountType: MemberConnectedAccountType;
};

export function ConnectedAccountTypeName(props: ConnectedAccountTypeNameProps) {
  const { accountType } = props;

  const accountTypeNames: Record<
    MemberConnectedAccountType,
    string | undefined
  > = {
    KAZM_MEMBER_ID: "Kazm member",
    UNKNOWN: undefined,
    ETHEREUM_WALLET: "Ethereum Wallet",
    SOLANA_WALLET: "Solana Wallet",
    APTOS_WALLET: "Aptos Wallet",
    TWITTER_ACCOUNT: "Twitter",
    DISCORD_ACCOUNT: "Discord",
    INSTAGRAM_ACCOUNT: "Instagram",
    EMAIL: "Email",
    TELEGRAM_ACCOUNT: "Telegram",
    UNSTOPPABLE_DOMAIN: "Unstoppable",
    SPOTIFY_ACCOUNT: "Spotify",
    TIK_TOK_ACCOUNT: "TikTok",
    PHONE: "Phone",
    FACEBOOK_ACCOUNT: "Facebook",
    YOUTUBE_ACCOUNT: "YouTube",
  };

  const name = accountTypeNames[accountType];

  if (!name) {
    return <></>;
  }

  return <span>{name}</span>;
}
