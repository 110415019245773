import { format } from "date-fns";
import { useEffect, useState } from "react";

import { SearchableScrollbar } from "@/common_components/scroll/SearchableScrollbar";
import { CollapsedText } from "@common/CollapsedText";
import SizedBox from "@common/SizedBox";
import { DataSourceIcon } from "@common/data_source/DataSourceIcon";
import { DateInput } from "@common/inputs/DateInput";
import TextInput from "@common/inputs/TextInput";
import { Shimmer } from "@common/loading/shimmer";
import {
  CheckboxMenuItem,
  FocusableMenuItem,
} from "@common/menus/CheckboxMenuItem";
import {
  AppColors,
  AttributeValueType,
  OrgDataSource,
  PropertyDefinition,
  PropertyType,
} from "@juntochat/kazm-shared";
import {
  useCurrentOrgDataSources,
  useGetSingleOrgPropertyDefinitionMetadataV2,
  useListMemberTags,
} from "@utils/hooks/use_cache";

import KazmUtils from "../../../utils/utils";
import { PropertyValue } from "../properties/PropertyValue";

import { useAppliedFilters } from "@common/filters/filters_controller.tsx";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";
import {
  getDefaultAppliedFilter,
  getFilterOptions,
  PropertyArrayFilterOption,
} from "./filter_utils";

export type MemberFilterInputProps = {
  propertyDefinition: PropertyDefinition;
};

/**
 * Renders an appropriate filter input UI, given a property definition.
 */
export function FilterInput(props: MemberFilterInputProps) {
  const { valueType, propertyType } = props.propertyDefinition;

  switch (propertyType) {
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER:
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_ID:
      return <ArrayFilterInput {...props} />;
    case PropertyType.PROPERTY_ACCOUNT_TAG:
      return <TagFilterInput {...props} />;
  }

  switch (valueType) {
    case AttributeValueType.ATTRIBUTE_VALUE_ARRAY:
      return <ArrayFilterInput {...props} />;
    case AttributeValueType.ATTRIBUTE_VALUE_TIMESTAMP:
      return <DateFilterInput {...props} />;
    case AttributeValueType.ATTRIBUTE_VALUE_NUMERIC:
    case AttributeValueType.ATTRIBUTE_VALUE_STRING:
    default:
      return <TextualFilterInput {...props} />;
  }
}

function TagFilterInput(props: MemberFilterInputProps) {
  const orgId = useCurrentOrgId();
  const tags = useListMemberTags({
    orgId,
  });

  if (tags.data === undefined) {
    return <Shimmer />;
  }

  return (
    <ArrayFilterInput
      propertyDefinition={props.propertyDefinition}
      options={tags.data.data.map((tag) => ({
        label: tag.title,
        value: tag.id,
      }))}
    />
  );
}

type ArrayFilterInputProps = {
  propertyDefinition: PropertyDefinition;
  options?: PropertyArrayFilterOption[];
};

function ArrayFilterInput(props: ArrayFilterInputProps) {
  const { propertyDefinition } = props;
  const { sourcesLookupById } = useCurrentOrgDataSources();
  const dataSource = sourcesLookupById.get(
    propertyDefinition.dataSourceId ?? "",
  );
  const { propertyMetadata } = useGetSingleOrgPropertyDefinitionMetadataV2(
    propertyDefinition.id,
  );
  const { getAppliedFilter, setAppliedFilter, removeAppliedFilter } =
    useAppliedFilters();

  const appliedFilter =
    getAppliedFilter(propertyDefinition.id) ??
    getDefaultAppliedFilter(propertyDefinition);

  const appliedChildrenLookup = new Set(
    appliedFilter.options?.map((child) => child.value),
  );

  function onOptionChange(targetOption: { value: string }, isChecked: boolean) {
    const newAppliedOptionsLookup = new Set(appliedChildrenLookup);
    if (isChecked) {
      newAppliedOptionsLookup.add(targetOption.value);
    } else {
      newAppliedOptionsLookup.delete(targetOption.value);
    }

    const isAnyOptionApplied = newAppliedOptionsLookup.size > 0;
    if (!isAnyOptionApplied) {
      removeAppliedFilter(propertyDefinition.id);
    } else {
      setAppliedFilter({
        ...appliedFilter,
        options: [...newAppliedOptionsLookup].map((value) => ({
          value,
        })),
      });
    }
  }

  const mockOptions = Array.from({ length: 3 }).map((_, index) => ({
    value: String(index),
    label: String(index),
  }));

  const isLoading = !KazmUtils.isDefined(propertyMetadata);

  const options = isLoading
    ? mockOptions
    : props.options ??
      getFilterOptions({
        propertyDefinition,
        propertyMetadata,
      })?.sort((a, b) =>
        a.position !== undefined && b.position !== undefined
          ? a.position - b.position
          : 0,
      ) ??
      [];

  return (
    <div className="max-h-[50vh] bg-dark-base-lighter">
      {dataSource && (
        <div className="p-[10px]">
          <DataSourceLabel dataSource={dataSource} />
        </div>
      )}
      <SearchableScrollbar
        className="py-[10px]"
        enableSearch={options && options.length > 12}
        maxHeight={dataSource ? "200px" : "400px"}
        searchableFields={["label"]}
        items={options}
        emptyState={<div>No {propertyDefinition.title}</div>}
        renderItem={(option) => (
          <CheckboxMenuItem
            enableHoverEffect
            disabled={isLoading}
            key={option.value}
            value={appliedChildrenLookup.has(option.value ?? "")}
            onChange={(isChecked) => onOptionChange(option, isChecked)}
            label={
              isLoading ? (
                <Shimmer width={150} />
              ) : (
                <PropertyValue
                  propertyDefinition={propertyDefinition}
                  value={option.value}
                />
              )
            }
          />
        )}
      />
    </div>
  );
}

function TextualFilterInput(
  props: MemberFilterInputProps & {
    formatInputValue?: (value: string) => string;
    formatInitialValue?: (value: string) => string;
  },
) {
  const {
    propertyDefinition,
    formatInputValue = (value) => value,
    formatInitialValue = (value) => value,
  } = props;
  const { sourcesLookupById } = useCurrentOrgDataSources();
  const dataSource = sourcesLookupById.get(
    propertyDefinition.dataSourceId ?? "",
  );
  const { getAppliedFilter, setAppliedFilter } = useAppliedFilters();
  const appliedFilter =
    getAppliedFilter(propertyDefinition.id) ??
    getDefaultAppliedFilter(propertyDefinition);
  const initialValue = formatInitialValue(
    appliedFilter?.options[0]?.value ?? "",
  );
  // This local variable `value` serves as a cache
  // that needs to be synced with `initialValue` (from applied filters).
  // So in case the applied filter is removed `value` needs to reflect that.
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  function onChange(inputValue: string) {
    setValue(formatInputValue(inputValue));
  }

  function onBlur() {
    setAppliedFilter({
      ...appliedFilter,
      options: [{ value: formatInputValue(value) }],
    });
  }

  return (
    <FocusableMenuItem style={{ background: AppColors.darkBaseLighter }}>
      {() => (
        <div className="flex w-full justify-between">
          {dataSource && <DataSourceLabel dataSource={dataSource} />}
          <div style={{ width: dataSource ? 100 : "100%" }}>
            <TextInput
              label="Value"
              defaultValue={value}
              type={getHtmlInputType(propertyDefinition.valueType)}
              onChangeText={onChange}
              controlled
              onBlur={onBlur}
            />
          </div>
        </div>
      )}
    </FocusableMenuItem>
  );
}

function DateFilterInput({ propertyDefinition }: MemberFilterInputProps) {
  const { sourcesLookupById } = useCurrentOrgDataSources();

  const dataSource = sourcesLookupById.get(
    propertyDefinition.dataSourceId ?? "",
  );
  const { getAppliedFilter, setAppliedFilter } = useAppliedFilters();
  const appliedFilter =
    getAppliedFilter(propertyDefinition.id) ??
    getDefaultAppliedFilter(propertyDefinition);
  const initialValue =
    appliedFilter?.options[0]?.value ?? new Date().toISOString();

  function onChange(value: Date | undefined) {
    if (value) {
      setAppliedFilter({
        ...appliedFilter,
        options: [{ value: format(value, "yyyy-MM-dd") }],
      });
    }
  }

  return (
    <FocusableMenuItem style={{ background: AppColors.darkBaseLighter }}>
      {({ closeMenu }) => (
        <div className="flex w-full justify-between">
          {dataSource && <DataSourceLabel dataSource={dataSource} />}
          <div style={{ width: dataSource ? 100 : "100%" }}>
            <DateInput
              className="w-full"
              onChange={(value) => {
                onChange(value);
                // Must manually close the menu,
                // otherwise it won't get properly closed on outside click
                // once the date is selected & applied
                closeMenu();
              }}
              value={new Date(initialValue)}
            />
          </div>
        </div>
      )}
    </FocusableMenuItem>
  );
}

function DataSourceLabel(props: { dataSource: OrgDataSource }) {
  const maxLength = 11;
  const shouldShowTooltip = props.dataSource.name.length > maxLength;

  return (
    <div className="flex w-[130px] items-center">
      <DataSourceIcon size={20} source={props.dataSource} />
      <SizedBox width={5} />
      <span className="max-w-[70%] overflow-hidden text-ellipsis whitespace-nowrap">
        <CollapsedText showTooltip={shouldShowTooltip}>
          {props.dataSource.name}
        </CollapsedText>
      </span>
      <SizedBox width={10} />
    </div>
  );
}

function getHtmlInputType(dataType: AttributeValueType) {
  switch (dataType) {
    case AttributeValueType.ATTRIBUTE_VALUE_NUMERIC:
      return "number";
    case AttributeValueType.ATTRIBUTE_VALUE_STRING:
    default:
      return "text";
  }
}
