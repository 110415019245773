import { uuidv4 } from "@firebase/util";
import {
  ContractType,
  OrgConnectedAccountDto,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import {
  ActionTypeVisitor,
  BlockchainType,
  ExchangeType,
  MemberActionDefinition,
  TierComparisonOperator,
  TwitterProfilePictureSource,
} from "@juntochat/kazm-shared";

interface CopyTemplateActionInput {
  action: MemberActionDefinition;
  accounts: OrgConnectedAccountDto[];
  tierIdMap: Map<string, string>;
}

// Returns a new action with new ids and referencing the correct account data
// If it is not  possible to create a new action with the provided information, it returns undefined
export class CopyTemplateAction extends ActionTypeVisitor<
  CopyTemplateActionInput,
  { copy: MemberActionDefinition; isDraft?: boolean } | undefined
> {
  protected solanaConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected ethereumConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected aptosConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected solanaOwnToken(input: CopyTemplateActionInput): {
    copy: MemberActionDefinition;
    isDraft?: boolean;
  } {
    const account = input.accounts.find(
      (account) =>
        account.accountType === OrgConnectedAccountType.SolanaAccount,
    );

    const tokenInfo = account?.result?.contractResult;
    const tokenDetails = input.action.solanaOwnToken?.anyOfTokens?.[0];

    if (tokenInfo && tokenDetails) {
      return {
        copy: {
          ...input.action,
          solanaOwnToken: {
            anyOfTokens: [
              {
                id: uuidv4(),
                tokenAddress: tokenInfo.address,
                name: "",
                link: "",
                minimumBalance: "",
                createdDate: new Date().toISOString(),
              },
            ],
          },
        },
        isDraft: true,
      };
    } else {
      return {
        copy: {
          ...input.action,
          solanaOwnToken: {
            anyOfTokens: [],
          },
        },
        isDraft: true,
      };
    }
  }

  protected ethereumOwnToken(input: CopyTemplateActionInput): {
    copy: MemberActionDefinition;
    isDraft?: boolean;
  } {
    const account = input.accounts.find(
      (account) =>
        account.accountType === OrgConnectedAccountType.EthereumAccount &&
        account.result?.contractResult?.type === ContractType.Erc20Contract,
    );

    const tokenInfo = account?.result?.contractResult;
    const tokenDetails = input.action.ethereumOwnToken?.anyOfTokens?.[0];

    if (tokenInfo && tokenDetails) {
      return {
        copy: {
          ...input.action,
          ethereumOwnToken: {
            anyOfTokens: [
              {
                id: uuidv4(),
                tokenAddress: tokenInfo.address,
                name: "",
                link: "",
                minimumBalance: "",
                createdDate: new Date().toISOString(),
                blockchain: BlockchainType.ETHEREUM,
              },
            ],
          },
        },
        isDraft: true,
      };
    } else {
      return {
        copy: {
          ...input.action,
          ethereumOwnToken: {
            anyOfTokens: [],
          },
        },
        isDraft: true,
      };
    }
  }

  protected aptosOwnToken(input: CopyTemplateActionInput): {
    copy: MemberActionDefinition;
    isDraft?: boolean;
  } {
    const account = input.accounts.find(
      (account) => account.accountType === OrgConnectedAccountType.AptosAccount,
    );

    const tokenInfo = account?.result?.contractResult;
    const tokenDetails = input.action.aptosOwnToken?.anyOfTokens?.[0];

    if (tokenInfo && tokenDetails) {
      return {
        copy: {
          ...input.action,
          aptosOwnToken: {
            anyOfTokens: [
              {
                id: uuidv4(),
                tokenAddress: tokenInfo.address,
                name: "",
                link: "",
                minimumBalance: "",
                createdDate: new Date().toISOString(),
              },
            ],
          },
        },
        isDraft: true,
      };
    } else {
      return {
        copy: {
          ...input.action,
          aptosOwnToken: {
            anyOfTokens: [],
          },
        },
        isDraft: true,
      };
    }
  }

  protected aptosOwnNft(input: CopyTemplateActionInput): {
    copy: MemberActionDefinition;
    isDraft?: boolean;
  } {
    const account = input.accounts.find(
      (account) => account.accountType === OrgConnectedAccountType.AptosAccount,
    );

    const nftInfo = account?.result?.contractResult;
    const nftDetails = input.action.aptosOwnNft?.anyOfNfts?.[0];

    if (nftInfo && nftDetails) {
      return {
        copy: {
          ...input.action,
          aptosOwnNft: {
            anyOfNfts: [
              {
                id: uuidv4(),
                tokenAddress: nftInfo.address,
                name: "",
                link: "",
                minimumBalance: "",
                createdDate: new Date().toISOString(),
              },
            ],
          },
        },
        isDraft: true,
      };
    } else {
      return {
        copy: {
          ...input.action,
          aptosOwnNft: {
            anyOfNfts: [],
          },
        },
        isDraft: true,
      };
    }
  }

  protected ethereumMinimumBalance(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected ethereumOwnNft(input: CopyTemplateActionInput): {
    copy: MemberActionDefinition;
    isDraft?: boolean;
  } {
    const account = input.accounts.find(
      (account) =>
        account.accountType === OrgConnectedAccountType.EthereumAccount &&
        account.result?.contractResult?.type === ContractType.Erc721Contract,
    );

    const tokenInfo = account?.result?.contractResult;
    const tokenDetails = input.action.ethereumOwnNft?.anyOfNfts?.[0];

    if (tokenInfo && tokenDetails) {
      return {
        copy: {
          ...input.action,
          ethereumOwnNft: {
            anyOfNfts: [
              {
                ...tokenDetails,
                id: uuidv4(),
                nftAddress: tokenInfo.address,
                name: "",
                link: "",
                createdDate: new Date().toISOString(),
                blockchain: BlockchainType.ETHEREUM,
              },
            ],
          },
        },
      };
    } else {
      return {
        copy: {
          ...input.action,
          ethereumOwnNft: {
            anyOfNfts: [],
          },
        },
        isDraft: true,
      };
    }
  }

  protected ethereumOwnPoap(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        ethereumOwnPoap: {
          ...input.action.ethereumOwnPoap,
          eventId: "",
          link: "",
          imageUrl: "",
        },
      },
      isDraft: true,
    };
  }

  protected walletProvideLiquidity(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        walletProvideLiquidity: {
          ...input.action.walletProvideLiquidity,
          blockchain: BlockchainType.ETHEREUM,
          exchange: ExchangeType.UNISWAP,
          tokenOneAddress: "",
          tokenTwoAddress: "",
          includeThreshold: false,
          threshold: 0,
        },
      },
      isDraft: true,
    };
  }

  protected twitterConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected twitterMention(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        twitterMention: { usernameToMention: "" },
      },
      isDraft: true,
    };
  }

  protected twitterFollow(input: CopyTemplateActionInput): {
    copy: MemberActionDefinition;
    isDraft?: boolean;
  } {
    const account = input.accounts.find(
      (account) =>
        account.accountType === OrgConnectedAccountType.TwitterAccount,
    );

    const accountId = account?.accountId;
    const name =
      account?.authData?.twitterData?.name ||
      account?.authData?.twitterData?.screenName;

    if (accountId && name) {
      return {
        copy: {
          ...input.action,
          twitterFollow: {
            accountId,
            name,
          },
        },
      };
    } else {
      return {
        copy: {
          ...input.action,
          twitterFollow: {
            accountId: "",
            name: "",
          },
        },
        isDraft: true,
      };
    }
  }

  protected twitterReact(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        twitterReact: {
          tweetUrl: "",
          shouldLike: input.action.twitterReact?.shouldLike ?? false,
          shouldRetweet: input.action.twitterReact?.shouldRetweet ?? false,
          shouldComment: input.action.twitterReact?.shouldComment ?? false,
        },
      },
      isDraft: true,
    };
  }

  protected twitterNameSubstring(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        twitterNameSubstring: {
          substring: "",
        },
      },
      isDraft: true,
    };
  }

  protected twitterBioSubstring(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        twitterBioSubstring: {
          substring: "",
        },
      },
      isDraft: true,
    };
  }

  protected twitterProfilePicture(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        twitterProfilePicture: {
          source: TwitterProfilePictureSource.WEB_URL,
          link: "",
          contractAddress: "",
        },
      },
      isDraft: true,
    };
  }

  protected discordConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected discordServerJoin(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        discordServerJoin: {
          serverId: "",
          inviteLink: "",
        },
      },
      isDraft: true,
    };
  }

  protected discordHasDiscordRole(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        discordHasDiscordRole: {
          requiredRoleIds: [],
          discordServerJoin: {
            serverId: "",
            inviteLink: "",
          },
        },
      },
      isDraft: true,
    };
  }

  protected discordSendMessage(input: CopyTemplateActionInput) {
    const account = input.accounts.find(
      (account) =>
        account.accountType === OrgConnectedAccountType.DiscordAccount,
    );

    const serverId = account?.result?.discordResult?.discordServerId;

    if (serverId) {
      return {
        copy: {
          ...input.action,
          discordSendMessage: { serverId },
        },
      };
    } else {
      return {
        copy: {
          ...input.action,
          discordSendMessage: { serverId: "" },
        },
        isDraft: true,
      };
    }
  }

  protected discordReaction(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        discordReaction: {
          messageLink: "",
        },
      },
      isDraft: true,
    };
  }

  protected instagramConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected instagramMedia(input: CopyTemplateActionInput) {
    const instagramUsername = input.accounts.find(
      (account) =>
        account.accountType === OrgConnectedAccountType.InstagramAccount,
    )?.authData?.instagramData?.username;

    if (instagramUsername) {
      return {
        copy: {
          ...input.action,
          instagramMedia: {
            usernameToMention: instagramUsername,
          },
        },
      };
    } else {
      return {
        copy: {
          ...input.action,
          instagramMedia: {
            usernameToMention: "",
          },
        },
        isDraft: true,
      };
    }
  }

  protected instagramFollow(input: CopyTemplateActionInput) {
    const account = input.accounts.find(
      (account) =>
        account.accountType === OrgConnectedAccountType.InstagramAccount,
    );

    const username = account?.authData?.instagramData?.username;

    if (username) {
      return {
        copy: {
          ...input.action,
          instagramFollow: { usernameToFollow: username },
        },
      };
    } else {
      return {
        copy: {
          ...input.action,
          instagramFollow: {
            usernameToFollow: "",
          },
        },
        isDraft: true,
      };
    }
  }

  protected tikTokMedia(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        tiktokMedia: {
          targetTiktokUserId: "",
          usernameToMention: "",
          displayNameToMention: "",
        },
      },
      isDraft: true,
    };
  }

  protected tikTokFollow(input: CopyTemplateActionInput): {
    copy: MemberActionDefinition;
    isDraft?: boolean;
  } {
    const account = input.accounts.find(
      (account) =>
        account.accountType === OrgConnectedAccountType.TiktokAccount,
    );

    const username = account?.authData?.tiktokData?.username;

    if (username) {
      return {
        copy: {
          ...input.action,
          tiktokFollow: { usernameToFollow: username },
        },
      };
    } else {
      return {
        copy: {
          ...input.action,
          tiktokFollow: {
            usernameToFollow: "",
          },
        },
        isDraft: true,
      };
    }
  }

  protected telegramConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected telegramJoinGroup(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        telegramJoinGroup: {
          groupId: "",
          groupName: "",
          inviteLink: "",
        },
      },
      isDraft: true,
    };
  }

  protected telegramJoinChannel(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        telegramJoinChannel: {
          chatId: "",
        },
      },
      isDraft: true,
    };
  }

  protected telegramSendMessage(input: CopyTemplateActionInput): {
    copy: MemberActionDefinition;
    isDraft?: boolean;
  } {
    return {
      copy: {
        ...input.action,
        telegramSendMessage: {
          groupId: "",
          groupName: "",
          inviteLink: "",
        },
      },
      isDraft: true,
    };
  }

  protected unstoppableDomainsConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected emailConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected stripeSubscriptionVerified(input: CopyTemplateActionInput): {
    copy: MemberActionDefinition;
    isDraft?: boolean;
  } {
    const account = input.accounts.find(
      (account) =>
        account.accountType === OrgConnectedAccountType.StripeAccount,
    );

    return {
      copy: {
        ...input.action,
        stripeSubscriptionVerified: {
          stripeAccountId: account?.accountId ?? "",
          subscriptionPageLink: "",
          productIds: [],
        },
      },
      isDraft: true,
    };
  }

  protected facebookConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected youtubeConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected youtubeSubscribe(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        youtubeSubscribe: {
          channelId: "",
          channelName: "",
        },
      },
      isDraft: true,
    };
  }

  protected location(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected phoneNumber(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected multipleChoice(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected textInput(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected urlInput(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected kazmForm(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        kazmForm: {
          formId: "",
        },
      },
      isDraft: true,
    };
  }

  protected kazmMembership(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        kazmMembership: {
          membershipId: "",
        },
      },
    };
  }

  protected kazmApiEvent(input: CopyTemplateActionInput): {
    copy: MemberActionDefinition;
    isDraft?: boolean;
  } {
    return {
      copy: {
        ...input.action,
        kazmApiEvent: {
          eventDefinitionId: "",
        },
      },
      isDraft: true,
    };
  }

  protected kazmMembershipTier(input: CopyTemplateActionInput) {
    const newTierId = input.tierIdMap.get(
      input.action.kazmMembershipTier?.tierId ?? "",
    );
    if (input.action.kazmMembershipTier?.tierId && newTierId) {
      return {
        copy: {
          ...input.action,
          kazmMembershipTier: {
            tierId: newTierId,
            comparison: input.action.kazmMembershipTier?.comparison,
          },
        },
      };
    } else {
      return {
        copy: {
          ...input.action,
          kazmMembershipTier: {
            tierId: "",
            comparison: TierComparisonOperator.TIER_EQUAL,
          },
        },
        isDraft: true,
      };
    }
  }

  protected kazmQuestCompletion(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        kazmQuestCompletion: {
          questId: "",
        },
      },
      isDraft: true,
    };
  }

  protected kazmBadgeEarned(input: CopyTemplateActionInput) {
    return {
      copy: {
        ...input.action,
        kazmBadgeEarned: {
          badgeId: "",
        },
      },
      isDraft: true,
    };
  }

  protected kazmMemberTag(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected reCaptchaV2(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected questPointsThreshold(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected uploadImage(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected termsOfServiceAgreement(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected referral(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected referralBonus(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected visitLink(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected checkIn(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected spotifyConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  protected tikTokConnection(input: CopyTemplateActionInput) {
    return { copy: input.action };
  }

  public copy(input: CopyTemplateActionInput) {
    return this.visit(input.action.type, {
      ...input,
      action: {
        ...input.action,
        id: uuidv4(),
      },
    });
  }

  // Not implemented:
  protected spotifyFollow() {
    return undefined;
  }

  protected spotifyListen() {
    return undefined;
  }

  protected manualPointAdjustment() {
    return undefined;
  }

  protected twitterLikeRetweet() {
    return undefined;
  }

  protected proofOfPresence() {
    return undefined;
  }
}
