/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AptosTokenDefinitionDto interface.
 */
export function instanceOfAptosTokenDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "tokenAddress" in value;
    isInstance = isInstance && "minimumBalance" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "createdDate" in value;
    return isInstance;
}
export function AptosTokenDefinitionDtoFromJSON(json) {
    return AptosTokenDefinitionDtoFromJSONTyped(json, false);
}
export function AptosTokenDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'tokenAddress': json['tokenAddress'],
        'minimumBalance': json['minimumBalance'],
        'link': json['link'],
        'createdDate': json['createdDate'],
    };
}
export function AptosTokenDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'tokenAddress': value.tokenAddress,
        'minimumBalance': value.minimumBalance,
        'link': value.link,
        'createdDate': value.createdDate,
    };
}
