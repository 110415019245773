import { createParagraphNode } from "@/common_components/editors/RichTextEditor/utils/paragraph";
import {
  ActionUsage,
  ActionUsageContext,
  getActionTypeLabel,
  getRequiredOrgMemberAccountTypeByActionType,
} from "@/modules/actions";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { DEFAULT_EMPTY_RAW_FORMAT } from "@/projects/membership/providers/forms/use_default_form.ts";
import {
  ActionDefinitionDto,
  ActionType,
  ActivationCategory,
  ActivationClaimActionType,
  ActivationClaimDto,
  ActivationClaimVerificationStatus,
  ActivationDisplaySettings,
  ActivationDto,
  ActivationRecurrenceDto,
  ActivationRecurrencePeriod,
  ActivationVerificationMethod,
  DtoPointsType,
} from "@juntochat/internal-api";
import {
  CommonActionUtils,
  CommonQuestUtils,
  MemberActionDefinition,
  MemberActionType,
  Membership,
} from "@juntochat/kazm-shared";
import { v4 as uuidv4 } from "uuid";

type CreateDefaultQuestOptions = {
  questType: MemberActionType;
  membership: Membership;
  existingQuests: ActivationDto[];
  hasAutoTwitterQuestVerificationEntitlement: boolean;
};

type CreateDefaultRewardOptions = {
  rewardType: ActionType;
  membership: Membership;
  existingRewards: ActivationDto[];
};

type CreateDefaultBadgeOptions = {
  membership: Membership;
  existingBadges: ActivationDto[];
};

export class ActivationUtils {
  /**
   * Returns true if activation can be claimed more than once.
   */
  static isRecurrent(activation: ActivationDto): boolean {
    if (activation.recurrence === null) {
      return false;
    }
    const isOnceEverRecurrence =
      activation.recurrence.period === ActivationRecurrencePeriod.Ever &&
      activation.recurrence.limitPerPeriod === 1;
    return !isOnceEverRecurrence;
  }

  static isClaimPendingPointsAssignment(claim: ActivationClaimDto) {
    // Only pending claims with 0 points require approval.
    // In some cases, manual verification is optional and points are assigned immediately.
    return (
      claim.verification?.status ===
        ActivationClaimVerificationStatus.Pending && claim.points === 0
    );
  }

  static isRedemptionMethodRequirement(
    activation: ActivationDto,
    requirement: MemberActionDefinition,
  ) {
    const actionUsageContext = ActionUsageContext.REWARD_REDEMPTION_METHOD;
    const categoriesWithRedemptionMethod = new Set<ActivationCategory>([
      ActivationCategory.Badge,
      ActivationCategory.Reward,
    ]);
    return (
      categoriesWithRedemptionMethod.has(activation.category) &&
      ActionUsage.isActionUsedInContext(requirement.type, actionUsageContext) &&
      // Points requirement is treated as a redemption method
      // only when the activation has the same points cost as specified in the requirement.
      // This is because the redemption method settings UI both:
      // - adds the quest points threshold requirement to `claimRequirements`
      // - and updates `activation.points` field to match the specified points
      (requirement.type === MemberActionType.QUEST_POINTS_THRESHOLD
        ? activation.points &&
          -activation.points === requirement.questPointsThreshold?.threshold
        : true)
    );
  }

  static getMostRecentClaim(claims: ActivationClaimDto[]) {
    return claims.sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
    )[0];
  }

  /**
   * Failed claims are created when the user submits an incorrect answer
   * (e.g. for multi-choice or text question quests).
   */
  static isFailedClaim(claim: ActivationClaimDto) {
    return (
      claim.points === 0 && claim.actionValidationResults.some((e) => !e.valid)
    );
  }

  static getCategoryLabel(category: ActivationCategory): string {
    switch (category) {
      case ActivationCategory.Reward:
        return "reward";
      case ActivationCategory.Quest:
        return "quest";
      case ActivationCategory.Form:
        return "form";
      case ActivationCategory.Badge:
        return "badge";
    }
  }

  static getCategoryClaimVerb(category: ActivationCategory): string {
    switch (category) {
      case ActivationCategory.Reward:
      case ActivationCategory.Badge:
        return "earn";
      case ActivationCategory.Quest:
      case ActivationCategory.Form:
        return "complete";
    }
  }

  static createDefaultReward(
    options: CreateDefaultRewardOptions,
  ): ActivationDto {
    const { rewardType, membership, existingRewards } = options;
    const protoRewardType = DtoMigrationUtils.actionTypeToProto(rewardType);

    const displaySettings: ActivationDisplaySettings = {
      successMessage: JSON.stringify([
        createParagraphNode([
          {
            text: "Congrats on earning your reward! Share your accomplishment.",
          },
        ]),
      ]),
    };

    if (rewardType === ActionType.RewardQrCode) {
      displaySettings.qrCodeReward = {
        prompt: "Show this QR to the sales associate.",
      };
    }

    const pointsCost = 10;

    const createdDate = new Date().toISOString();
    const pointsRedemptionRequirement: ActionDefinitionDto = {
      id: uuidv4(),
      isRequired: true,
      type: ActionType.QuestPointsThreshold,
      createdDate,
      updatedDate: createdDate,
      questPointsThreshold: {
        threshold: pointsCost,
        pointsType: DtoPointsType.BalancePoints,
      },
    };

    return {
      category: ActivationCategory.Reward,
      displaySettings,
      type: rewardType,
      verificationMethod: ActivationVerificationMethod.Unspecified,
      orgId: membership.orgId,
      activationId: uuidv4(),
      membershipId: membership.id,
      title: "",
      richDescription: JSON.stringify(DEFAULT_EMPTY_RAW_FORMAT),
      imageUrl: "",
      isActive: true,
      isVisible: true,
      position: this.getNewActivationPosition(existingRewards),
      points: -pointsCost,
      backgroundColor: CommonActionUtils.getActionTypeColor(protoRewardType),
      prerequisites: [],
      claimRequirements: [
        pointsRedemptionRequirement,
        ...this.getDefaultRewardClaimRequirements(rewardType),
      ],
      recurrence: {
        limitPerPeriod: 1,
        period: ActivationRecurrencePeriod.Ever,
      },
      claimActions: this.getDefaultClaimActions(rewardType),
      activationTypeSettings: {},
      isDraft: false,
    };
  }

  static createDefaultBadge(options: CreateDefaultBadgeOptions): ActivationDto {
    const { membership, existingBadges } = options;
    const badgeType = ActionType.BadgeCustom;
    const protoRewardType = DtoMigrationUtils.actionTypeToProto(badgeType);

    const displaySettings: ActivationDisplaySettings = {
      successMessage: JSON.stringify([
        createParagraphNode([
          {
            text: "Congrats on earning your badge! Share your accomplishment.",
          },
        ]),
      ]),
    };

    return {
      category: ActivationCategory.Badge,
      displaySettings,
      type: badgeType,
      verificationMethod: ActivationVerificationMethod.Unspecified,
      orgId: membership.orgId,
      activationId: uuidv4(),
      membershipId: membership.id,
      title: "",
      richDescription: JSON.stringify(DEFAULT_EMPTY_RAW_FORMAT),
      // Do not set a default image, as we have image template builder UI instead.
      imageUrl: "",
      isActive: true,
      isVisible: true,
      position: this.getNewActivationPosition(existingBadges),
      points: 0,
      backgroundColor: CommonActionUtils.getActionTypeColor(protoRewardType),
      prerequisites: [],
      claimRequirements: [],
      recurrence: null,
      claimActions: [],
      activationTypeSettings: {},
      isDraft: false,
    };
  }

  private static getDefaultClaimActions(activationType: ActionType) {
    switch (activationType) {
      case ActionType.RewardDiscordRole:
        return [
          {
            type: ActivationClaimActionType.DiscordRole,
            discordRole: {
              roleId: "",
              serverId: "",
            },
          },
        ];
      case ActionType.RewardKazmDiscount:
        return [
          {
            type: ActivationClaimActionType.KazmDiscount,
          },
        ];
      case ActionType.RewardShopifyDiscount:
        return [
          {
            type: ActivationClaimActionType.ShopifyDiscount,
            shopifyDiscountCode: {
              storeId: "",
              storeUrl: "",
              priceRuleId: "",
            },
          },
        ];
      case ActionType.RewardLink:
        return [
          {
            type: ActivationClaimActionType.WebLink,
            webLink: {
              title: "",
              url: "",
            },
          },
        ];
      default:
        return [];
    }
  }

  private static getDefaultRewardClaimRequirements(
    type: ActionType,
  ): ActionDefinitionDto[] {
    const now = new Date().toISOString();
    switch (type) {
      case ActionType.RewardDiscordRole:
        return [
          {
            id: uuidv4(),
            type: ActionType.DiscordServerJoin,
            isRequired: true,
            createdDate: now,
            updatedDate: now,
            discordServerJoin: {
              serverId: "",
              inviteLink: "",
            },
          },
        ];

      default:
        return [];
    }
  }

  // These quests must allow unlimited claims,
  // as action validators are the ones who determine how many times can a user perform an action.
  static hasPredefinedUnlimitedRecurrence(actionType: ActionType): boolean {
    return new Set<ActionType>([
      ActionType.ProofOfPresence,
      ActionType.ReferralBonus,
      ActionType.Referral,
    ]).has(actionType);
  }

  static createDefaultQuest(options: CreateDefaultQuestOptions): ActivationDto {
    const {
      questType,
      membership,
      existingQuests,
      hasAutoTwitterQuestVerificationEntitlement,
    } = options;
    const dtoActionType = DtoMigrationUtils.actionTypeFromProto(questType);
    const createdDate = new Date().toISOString();
    const isAutoVerifiable = CommonQuestUtils.isAutoVerifiableAction(questType);
    const requiresTwitterApiEntitlement =
      CommonQuestUtils.requiresTwitterApiEntitlement(questType);
    const isManuallyVerifiable =
      CommonQuestUtils.isManuallyVerifiableAction(questType);

    let verificationMethod: ActivationVerificationMethod =
      ActivationVerificationMethod.Unspecified;
    if (isAutoVerifiable) {
      if (
        hasAutoTwitterQuestVerificationEntitlement ||
        !requiresTwitterApiEntitlement
      ) {
        verificationMethod = ActivationVerificationMethod.AutoVerify;
      } else {
        verificationMethod = ActivationVerificationMethod.AutoApprove;
      }
    } else if (questType === MemberActionType.TEXT_INPUT) {
      verificationMethod = ActivationVerificationMethod.AutoApprove;
    } else if (isManuallyVerifiable) {
      verificationMethod = ActivationVerificationMethod.Manual;
    }

    const claimRequirements = [
      {
        id: uuidv4(),
        type: dtoActionType,
        updatedDate: createdDate,
        createdDate: createdDate,
        isRequired: true,
      },
    ];

    const hasRecurrentRequirement = claimRequirements.some(
      (requirement) =>
        !CommonActionUtils.isConnectionAction(
          DtoMigrationUtils.actionDefinitionToProto(requirement).type,
        ),
    );
    const isRecurrentActivationType = new Set<ActionType>([
      ActionType.RewardMerch,
      ActionType.RewardQrCode,
    ]).has(dtoActionType);
    const isRecurrent = hasRecurrentRequirement || isRecurrentActivationType;

    let recurrence: ActivationRecurrenceDto | null = null;

    if (this.hasPredefinedUnlimitedRecurrence(dtoActionType)) {
      // Allow claiming any number of times,
      // as proof of presence action validator checks for already used QR codes.
      recurrence = {
        period: ActivationRecurrencePeriod.Unlimited,
        limitPerPeriod: -1,
      };
    } else if (isRecurrent) {
      recurrence = {
        limitPerPeriod: 1,
        period: ActivationRecurrencePeriod.Ever,
      };
    }

    const prerequisites: ActionDefinitionDto[] = [];

    if (questType === MemberActionType.REFERRAL_BONUS) {
      const referralQuest = existingQuests.find(
        (quest) => quest.type === ActionType.Referral,
      );

      if (!referralQuest) {
        throw new Error("Missing required referral quest");
      }

      const createdDate = new Date().toISOString();
      prerequisites.push({
        id: uuidv4(),
        type: ActionType.KazmQuestCompletion,
        kazmQuestCompletion: {
          questId: referralQuest.activationId,
        },
        isRequired: true,
        createdDate,
        updatedDate: createdDate,
      });
    }

    return {
      category: ActivationCategory.Quest,
      displaySettings: {},
      type: dtoActionType,
      verificationMethod,
      orgId: membership.orgId,
      activationId: uuidv4(),
      membershipId: membership.id,
      title: getActionTypeLabel(questType, {
        withPlatformContext: true,
      }),
      richDescription: JSON.stringify(DEFAULT_EMPTY_RAW_FORMAT),
      imageUrl: "",
      isActive: true,
      isVisible: true,
      position: this.getNewActivationPosition(existingQuests),
      points: 10,
      backgroundColor: CommonActionUtils.getActionTypeColor(questType),
      prerequisites,
      claimRequirements,
      recurrence,
      claimActions: [],
      activationTypeSettings: {},
      isDraft: false,
    };
  }

  private static getNewActivationPosition(activations: ActivationDto[]) {
    if (activations.length === 0) {
      return 0;
    }
    const maxActivationPosition = Math.max(
      ...activations.map((activation) => activation.position),
    );

    return maxActivationPosition + Math.random();
  }

  static getAllRecurrencePeriods(): ActivationRecurrencePeriod[] {
    return [
      ActivationRecurrencePeriod.Minute,
      ActivationRecurrencePeriod.Hourly,
      ActivationRecurrencePeriod.Daily,
      ActivationRecurrencePeriod.Weekly,
      ActivationRecurrencePeriod.Ever,
      ActivationRecurrencePeriod.Unlimited,
    ];
  }

  static isSocialQuest(activation: ActivationDto): boolean {
    const actionType = DtoMigrationUtils.actionTypeToProto(activation.type);
    const requiredAccountType =
      getRequiredOrgMemberAccountTypeByActionType(actionType);
    return requiredAccountType !== undefined;
  }

  static shouldBeDisplayedAsRecurrent(activation: ActivationDto) {
    const claimRequirements = activation.claimRequirements.map((e) =>
      DtoMigrationUtils.actionDefinitionToProto(e),
    );
    const isActionRecurrent = claimRequirements.some(
      (requirement) => !CommonActionUtils.isConnectionAction(requirement.type),
    );

    if (activation.category === ActivationCategory.Reward) {
      return false;
    }

    if (!this.isRecurrent(activation)) {
      return false;
    }

    const isRecurrenceAllowedTries = [
      MemberActionType.TEXT_INPUT,
      MemberActionType.MULTIPLE_CHOICE,
    ].includes(DtoMigrationUtils.actionTypeToProto(activation.type));

    return isActionRecurrent && !isRecurrenceAllowedTries;
  }

  static shouldBeDisplayedAsAllowedRetries(activation: ActivationDto) {
    const allowsMultipleTries =
      activation.recurrence && activation.recurrence?.limitPerPeriod > 1;

    const isAutoValidatedTextQuestion =
      activation.type === ActionType.TextInput &&
      activation.claimRequirements[0]?.textInput?.question.validation;

    const isAutoValidatedMultipleChoiceQuestion =
      activation.type === ActionType.MultipleChoice &&
      activation.claimRequirements[0]?.multipleChoice?.question.correctOptionId;

    return (
      allowsMultipleTries &&
      (isAutoValidatedTextQuestion || isAutoValidatedMultipleChoiceQuestion)
    );
  }

  /**
   * Returns true if our backend logic is implemented in a way that allows
   * quest to be automatically claimed without any user-initiated request.
   * Before enabling the setting here, you also need to implement the backend logic.
   */
  static isAutomaticallyClaimed(activation: ActivationDto): boolean {
    const isAutomaticallyVerifiedActionTypeLookup: Record<ActionType, boolean> =
      {
        [ActionType.Referral]: true,
        [ActionType.ReferralBonus]: true,
        [ActionType.KazmApiEvent]: true,
        [ActionType.ProofOfPresence]: true,
        [ActionType.KazmForm]: true,
        [ActionType.DiscordSendMessage]: true,
        [ActionType.TelegramSendMessage]: true,
        [ActionType.UrlInput]: false,
        [ActionType.UploadImage]: false,
        [ActionType.InstagramMedia]: false,
        [ActionType.InstagramFollow]: false,
        [ActionType.TikTokMedia]: false,
        [ActionType.TikTokFollow]: false,
        [ActionType.RecaptchaV2]: false,
        [ActionType.TermsOfServiceAgreement]: false,
        [ActionType.QuestPointsThreshold]: false,
        [ActionType.EmailConnection]: false,
        [ActionType.EthereumConnection]: false,
        [ActionType.AptosConnection]: false,
        [ActionType.AptosOwnNft]: false,
        [ActionType.AptosOwnToken]: false,
        [ActionType.SolanaConnection]: false,
        [ActionType.SolanaOwnToken]: false,
        [ActionType.InstagramConnection]: false,
        [ActionType.TwitterConnection]: false,
        [ActionType.TelegramConnection]: false,
        [ActionType.DiscordConnection]: false,
        [ActionType.TikTokConnection]: false,
        [ActionType.UnstoppableDomainsConnection]: false,
        [ActionType.SpotifyConnection]: false,
        [ActionType.EthereumMinimumBalance]: false,
        [ActionType.EthereumOwnNft]: false,
        [ActionType.EthereumOwnToken]: false,
        [ActionType.EthereumOwnPoap]: false,
        [ActionType.WalletProvideLiquidity]: false,
        [ActionType.TwitterFollow]: false,
        [ActionType.TwitterLikeRetweet]: false,
        [ActionType.TwitterMention]: false,
        [ActionType.TwitterNameSubstring]: false,
        [ActionType.TwitterBioSubstring]: false,
        [ActionType.TwitterReact]: false,
        [ActionType.TwitterProfilePicture]: false,
        [ActionType.DiscordServerJoin]: false,
        [ActionType.DiscordHasDiscordRole]: false,
        [ActionType.DiscordReaction]: false,
        [ActionType.TelegramJoinGroup]: false,
        [ActionType.TelegramJoinChannel]: false,
        [ActionType.StripeSubscriptionVerified]: false,
        [ActionType.SpotifyFollow]: false,
        [ActionType.SpotifyListen]: false,
        [ActionType.Location]: false,
        [ActionType.TextInput]: false,
        [ActionType.MultipleChoice]: false,
        [ActionType.PhoneNumber]: false,
        [ActionType.ManualPointAdjustment]: false,
        [ActionType.VisitLink]: false,
        [ActionType.FacebookConnection]: false,
        [ActionType.KazmMembershipTier]: false,
        [ActionType.KazmQuestCompletion]: false,
        [ActionType.KazmBadgeEarned]: false,
        [ActionType.KazmMemberTag]: false,
        [ActionType.KazmMembership]: false,
        [ActionType.YoutubeConnection]: false,
        [ActionType.YoutubeSubscribe]: false,
        [ActionType.CheckIn]: false,

        // Rewards
        [ActionType.RewardDiscordRole]: false,
        [ActionType.RewardMerch]: false,
        [ActionType.RewardShopifyDiscount]: false,
        [ActionType.RewardLink]: false,
        [ActionType.RewardQrCode]: false,
        [ActionType.RewardKazmDiscount]: false,
        [ActionType.BadgeCustom]: false,
      };

    return isAutomaticallyVerifiedActionTypeLookup[activation.type];
  }

  static getRecurrencePeriodLabel(period: ActivationRecurrencePeriod): string {
    switch (period) {
      case ActivationRecurrencePeriod.Minute:
        return "minute";
      case ActivationRecurrencePeriod.Hourly:
        return "hour";
      case ActivationRecurrencePeriod.Daily:
        return "day";
      case ActivationRecurrencePeriod.Weekly:
        return "week";
      case ActivationRecurrencePeriod.Monthly:
        return "month";
      case ActivationRecurrencePeriod.Ever:
        return "ever";
      case ActivationRecurrencePeriod.Unlimited:
        return "unlimited";
      default:
        return "ever";
    }
  }
}
