import classNames from "classnames";
import { BiLinkExternal } from "react-icons/bi";

import { ExternalLink } from "@/common_components/ExternalLink";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { KazmIcon } from "@common/icons/KazmIcons";
import {
  ActionTypeVisitor,
  AppColors,
  MemberActionOutcome,
  MemberActionType,
} from "@juntochat/kazm-shared";
import KazmUtils from "@utils/utils";
import isVideo from "is-video";
import { ReactElement, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import ReactPlayer from "react-player";

interface VerificationOutcomeDisplayProps {
  outcome: MemberActionOutcome;
  // Show minified (shorter) version of the UI if possible.
  minified: boolean;
  className?: string;
  // Show enlarged version of the UI if possible.
  fullMode?: boolean;
}

export function ActionOutcomeDisplay(props: VerificationOutcomeDisplayProps) {
  const visitor = new ActionOutcomeDisplayInternal();
  return visitor.render(props.outcome.type, props);
}

class ActionOutcomeDisplayInternal extends ActionTypeVisitor<
  VerificationOutcomeDisplayProps,
  ReactElement | null
> {
  render(actionType: MemberActionType, props: VerificationOutcomeDisplayProps) {
    return this.visit(actionType, props);
  }

  protected instagramMedia(props: VerificationOutcomeDisplayProps) {
    return <NonApiVerifiableDisplay {...props} />;
  }

  protected instagramFollow(props: VerificationOutcomeDisplayProps) {
    return <NonApiVerifiableDisplay {...props} />;
  }

  protected tikTokMedia(props: VerificationOutcomeDisplayProps) {
    return <NonApiVerifiableDisplay {...props} />;
  }

  protected tikTokFollow(props: VerificationOutcomeDisplayProps) {
    return <NonApiVerifiableDisplay {...props} />;
  }

  protected uploadImage(props: VerificationOutcomeDisplayProps) {
    return <UploadImageOutcomeDisplay {...props} />;
  }

  protected urlInput(props: VerificationOutcomeDisplayProps) {
    return <UrlDisplay url={props.outcome.urlInput?.link ?? ""} {...props} />;
  }

  protected visitLink() {
    return null;
  }

  protected checkIn() {
    return null;
  }

  protected discordConnection() {
    return null;
  }

  protected discordHasDiscordRole() {
    return null;
  }

  protected discordReaction() {
    return null;
  }

  protected discordSendMessage() {
    return null;
  }

  protected discordServerJoin() {
    return null;
  }

  protected emailConnection() {
    return null;
  }

  protected solanaConnection() {
    return null;
  }

  protected solanaOwnToken() {
    return null;
  }

  protected aptosConnection() {
    return null;
  }

  protected aptosOwnToken() {
    return null;
  }

  protected aptosOwnNft() {
    return null;
  }

  protected ethereumConnection() {
    return null;
  }

  protected ethereumMinimumBalance() {
    return null;
  }

  protected ethereumOwnNft() {
    return null;
  }

  protected ethereumOwnPoap() {
    return null;
  }

  protected ethereumOwnToken() {
    return null;
  }

  protected walletProvideLiquidity() {
    return null;
  }

  protected instagramConnection() {
    return null;
  }

  protected kazmApiEvent() {
    return null;
  }

  protected kazmForm() {
    return null;
  }

  protected kazmMembership() {
    return null;
  }

  protected kazmMembershipTier() {
    return null;
  }

  protected kazmMemberTag() {
    return null;
  }

  protected kazmQuestCompletion() {
    return null;
  }

  protected kazmBadgeEarned() {
    return null;
  }

  protected location() {
    return null;
  }

  protected manualPointAdjustment() {
    return null;
  }

  protected multipleChoice() {
    return null;
  }

  protected phoneNumber() {
    return null;
  }

  protected questPointsThreshold() {
    return null;
  }

  protected reCaptchaV2() {
    return null;
  }

  protected referral() {
    return null;
  }

  protected referralBonus() {
    return null;
  }

  protected spotifyConnection() {
    return null;
  }

  protected spotifyFollow() {
    return null;
  }

  protected spotifyListen() {
    return null;
  }

  protected stripeSubscriptionVerified() {
    return null;
  }

  protected telegramConnection() {
    return null;
  }

  protected telegramJoinGroup() {
    return null;
  }

  protected telegramJoinChannel() {
    return null;
  }

  protected telegramSendMessage() {
    return null;
  }

  protected termsOfServiceAgreement() {
    return null;
  }

  protected textInput(props: VerificationOutcomeDisplayProps) {
    return <TextDisplay text={props.outcome.textInput?.response ?? ""} />;
  }

  protected twitterConnection() {
    return null;
  }

  protected twitterFollow(
    props: VerificationOutcomeDisplayProps,
  ): ReactElement | null {
    return <NonApiVerifiableDisplay {...props} />;
  }

  protected twitterReact() {
    return null;
  }

  protected twitterLikeRetweet() {
    return null;
  }

  protected twitterMention(props: VerificationOutcomeDisplayProps) {
    return <LinkDisplay link={props.outcome.twitterMention?.tweetUrl ?? ""} />;
  }

  protected twitterNameSubstring() {
    return null;
  }

  protected twitterBioSubstring() {
    return null;
  }

  protected twitterProfilePicture() {
    return null;
  }

  protected unstoppableDomainsConnection() {
    return null;
  }
  protected tikTokConnection() {
    return null;
  }
  protected facebookConnection() {
    return null;
  }
  protected proofOfPresence() {
    return null;
  }

  protected youtubeConnection() {
    return null;
  }
  protected youtubeSubscribe() {
    return null;
  }
}

function NonApiVerifiableDisplay(props: VerificationOutcomeDisplayProps) {
  const nestedOutcome = props.outcome.nonApiVerifiable;

  if (!nestedOutcome) {
    return null;
  }

  if (props.minified) {
    // We either require users to add content URL or image URL,
    // so at least one of them should be always defined.
    if (nestedOutcome.contentUrl === "") {
      return (
        <ImageDisplay
          className="h-full"
          imageUrl={nestedOutcome.proofImageUrl}
        />
      );
    } else {
      return <UrlDisplay url={nestedOutcome.contentUrl} {...props} />;
    }
  }

  return (
    <div
      className={classNames(
        props.className,
        "flex flex-col items-start gap-y-[10px]",
      )}
    >
      {nestedOutcome.contentUrl && (
        <UrlDisplay url={nestedOutcome.contentUrl} />
      )}
      {nestedOutcome.proofImageUrl && (
        <ImageDisplay
          className="h-full"
          imageUrl={nestedOutcome.proofImageUrl}
        />
      )}
    </div>
  );
}

function UploadImageOutcomeDisplay(props: VerificationOutcomeDisplayProps) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const mediaUrl = props.outcome.uploadImage?.link ?? "";

  return (
    <div
      className="relative cursor-pointer"
      onClick={() => window.open(mediaUrl, "_blank")}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {isMouseOver && (
        <div className="absolute right-2 top-2 rounded bg-cool-purple-400 p-2">
          <KazmIcon.ExternalLink color={AppColors.white} />
        </div>
      )}
      <DisplayPreview mediaUrl={mediaUrl} className={props.className} />
    </div>
  );
}

function DisplayPreview({
  mediaUrl,
  className,
}: {
  mediaUrl: string;
  className?: string;
}) {
  if (mediaUrl.endsWith(".pdf")) {
    return <PDFUploadedDisplay />;
  } else if (isVideo(mediaUrl)) {
    return (
      <ReactPlayer
        url={mediaUrl}
        controls={true}
        width="100%"
        height="100%"
        className={className}
      />
    );
  } else {
    return <ImageDisplay imageUrl={mediaUrl} className={className} />;
  }
}

export function PDFUploadedDisplay({
  color = AppColors.white,
}: {
  color?: string;
}) {
  return (
    <div
      className="mx-auto flex h-[300px] w-[300px] items-center justify-center rounded border-[1px]"
      style={{
        borderColor: color,
      }}
    >
      <div className="flex flex-col items-center justify-center space-y-[10px]">
        <FaFileAlt size={30} color={color} />
        <div className="headline-sm">PDF Uploaded</div>
      </div>
    </div>
  );
}

function UrlDisplay(props: {
  url: string;
  className?: string;
  fullMode?: boolean;
}) {
  if (props.fullMode) {
    return (
      <UnstyledButton
        className="flex w-full items-center justify-center space-x-[10px] rounded-[4px] bg-dark-base px-[10px] py-[15px]"
        onClick={() => window.open(KazmUtils.formatURL(props.url), "_blank")}
      >
        <div className="caption flex-1 truncate text-left text-white">
          {props.url}
        </div>
        <BiLinkExternal size={14} className="text-white" />
      </UnstyledButton>
    );
  }

  return (
    <UnstyledButton
      onClick={() => window.open(KazmUtils.formatURL(props.url), "_blank")}
    >
      <div
        className={classNames(
          "flex items-center justify-center gap-[5px] rounded bg-dark-base p-1",
          props.className,
        )}
      >
        <div className="caption w-[130px] truncate text-left text-white">
          {props.url}
        </div>
        <BiLinkExternal size={14} className="text-white" />
      </div>
    </UnstyledButton>
  );
}

function ImageDisplay(props: { imageUrl: string; className?: string }) {
  return (
    <img
      className={classNames("rounded", props.className)}
      style={{ objectFit: "contain" }}
      src={props.imageUrl}
      alt="Content to verify"
    />
  );
}

function TextDisplay(props: { text: string }) {
  return <div className="text-left text-white">{props.text}</div>;
}

function LinkDisplay(props: { link: string }) {
  return (
    <ExternalLink href={props.link}>
      <p className="w-[90%] truncate text-white hover:text-cool-purple-200">
        {props.link}
      </p>
    </ExternalLink>
  );
}
