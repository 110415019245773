import {
  ActionDefinitionValidationFieldType,
  useActionDefinitionErrorProvider,
} from "@/modules/actions";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { DataSourceUtils } from "@/utils/data_source_utils";
import { SimpleButton } from "@common/buttons/SimpleButton";
import TextInput from "@common/inputs/TextInput";
import { uuidv4 } from "@firebase/util";
import {
  BlockchainType,
  OrgDataSource,
  AptosTokenDefinition,
} from "@juntochat/kazm-shared";
import { useGetAddressInfo } from "@utils/hooks/use_cache";

import { CheckboxWithLabel } from "@/common_components/inputs/Checkbox";
import { DataSourceTypeIcon } from "@common/data_source/DataSourceTypeIcon.tsx";
import { BlockchainUtils } from "@utils/blockchain_utils.ts";
import { useCurrentOrgContractSources } from "@utils/hooks/use_current_org_contract_infos.ts";
import {
  AptosOwnNftController,
  useAptosOwnNftController,
} from "./useAptosOwnNftController";

export function AptosOwnNftDefinitionBuilder(
  options: ActionDefinitionBuilderProps,
) {
  const { validateDefinition } = useActionDefinitionErrorProvider();
  const controller = useAptosOwnNftController({
    ...options,
    validateDefinition,
  });

  return <AptosOwnNftDefinitionBuilderView controller={controller} />;
}

interface AptosOwnNftDefinitionBuilderViewProps {
  controller: AptosOwnNftController;
}

function AptosOwnNftDefinitionBuilderView({
  controller,
}: AptosOwnNftDefinitionBuilderViewProps) {
  const { getErrorsByFieldIdAndType } = useActionDefinitionErrorProvider();
  const { aptosTokenContracts } = useCurrentOrgContractSources();

  return (
    <div className="space-y-[10px]">
      <SimpleButton
        className="h-[40px] !bg-cool-purple-400"
        onClick={controller.connectAptosSource}
      >
        Connect NFT contract
      </SimpleButton>
      {aptosTokenContracts.map((source) => {
        const existingNftDefinition = controller.nfts.find((nft) =>
          BlockchainUtils.isTokenDefinitionEqualToSource(
            {
              ...nft,
              blockchain: BlockchainType.APTOS,
            },
            source,
          ),
        );
        const id = existingNftDefinition?.id ?? uuidv4();
        const minimumBalance = existingNftDefinition?.minimumBalance ?? "0";
        const link = existingNftDefinition?.link;
        const minimumBalanceErrorMessage = getErrorsByFieldIdAndType({
          fieldId: id,
          fieldType:
            ActionDefinitionValidationFieldType.APTOS_OWN_NFT_MINIMUM_BALANCE,
        })?.message;

        const linkErrorMessage = getErrorsByFieldIdAndType({
          fieldId: id,
          fieldType: ActionDefinitionValidationFieldType.APTOS_OWN_NFT_LINK,
        })?.message;

        const address = BlockchainUtils.getDataSourceContractAddress(source);

        return (
          <OwnNftInput
            key={source.id}
            source={source}
            isEnabled={existingNftDefinition !== undefined}
            enable={({ displayName }) => {
              controller.toggleOwnNft(
                AptosTokenDefinition.fromPartial({
                  id,
                  name: displayName,
                  tokenAddress: address,
                  createdDate: new Date().toISOString(),
                }),
              );
            }}
            minimumBalance={minimumBalance}
            setMinimumBalance={(minimumBalance) =>
              controller.editNft({ id, minimumBalance })
            }
            minimumBalanceError={
              controller.isDirty && minimumBalanceErrorMessage ? (
                <div>{minimumBalanceErrorMessage}</div>
              ) : undefined
            }
            handleOnBlurMinimumBalance={() => {}}
            purchaseNftLink={link}
            setPurchaseNftLink={(link) => controller.editNft({ id, link })}
            error={
              linkErrorMessage &&
              controller.isDirty && <div>{linkErrorMessage}</div>
            }
            onBlur={() => controller.setIsDirty(true)}
          />
        );
      })}
    </div>
  );
}

interface OwnNftInputProps {
  source: OrgDataSource;
  isEnabled: boolean;
  enable: ({ displayName }: { displayName: string }) => void;
  minimumBalance: string;
  setMinimumBalance: (minimumBalance: string) => void;
  handleOnBlurMinimumBalance: () => void;
  minimumBalanceError: string | boolean | JSX.Element | undefined;
  purchaseNftLink?: string;
  setPurchaseNftLink: (link: string) => void;
  error?: string | boolean | JSX.Element | undefined;
  onBlur?: () => void;
}

function OwnNftInput({
  isEnabled,
  enable,
  source,
  minimumBalance,
  setMinimumBalance,
  handleOnBlurMinimumBalance,
  minimumBalanceError,
  purchaseNftLink,
  setPurchaseNftLink,
  error,
  onBlur,
}: OwnNftInputProps) {
  const address = BlockchainUtils.getDataSourceContractAddress(source);
  const { data } = useGetAddressInfo({
    address,
    blockchainType: DataSourceUtils.dataSourceTypeToBlockchainType(
      source.sourceType,
    ),
  });

  const displayName =
    !source.name && data?.displayName && data.displayName !== source.name
      ? `${source.name} (${data.displayName})`
      : source.name;

  return (
    <div className="space-y-[10px]">
      <CheckboxWithLabel
        className="headline-sm !gap-[10px]"
        title={
          <span className="flex gap-x-[5px]">
            Own {displayName}
            <DataSourceTypeIcon dataSourceType={source.sourceType} />
          </span>
        }
        value={isEnabled}
        onChange={() => enable({ displayName })}
      />
      {isEnabled && (
        <div className="ml-[30px] flex items-start space-x-[10px]">
          <TextInput
            className="flex-1"
            label="Link to purchase"
            defaultValue={purchaseNftLink}
            onChangeText={setPurchaseNftLink}
            onBlur={onBlur}
            error={error ? <>{error}</> : undefined}
          />
          <TextInput
            className="w-[140px]"
            label="Minimum Balance"
            defaultValue={minimumBalance}
            onChangeText={(minimumBalance: string) =>
              setMinimumBalance(minimumBalance)
            }
            onBlur={handleOnBlurMinimumBalance}
            error={minimumBalanceError ? <>{minimumBalanceError}</> : undefined}
            min={1}
          />
        </div>
      )}
    </div>
  );
}
